import React, {useEffect,useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { withRouter } from 'react-router-dom';
import axios from "@fuse/utils/axios";
import { Tooltip } from '@material-ui/core';
import FuseLoading from '@fuse/core/FuseLoading';
import { getLocalTimeFromUTC } from '@fuse/utils/dateOperations';

const useStyles = makeStyles({
  root:{
    // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
    //   border: '2px solid black',
    //   display: 'none !important'
    // }
    '& .MuiDataGrid-columnsContainer':{
      background: '#8f8f8f26'
    }
  }
});
  
const columns = [
  { field: 'problem_no', headerName: 'Problem Number', width: 300 },
  { field: 'serial_no', headerName: 'Serial Number', width: 300,},
  { field: 'event_severity', headerName: 'Event Severity', width: 300},
  { field: 'problem_time', headerName: 'Event Date & Time',width: 300},
  { field: 'recovery_time', headerName: 'Recovery Time',width: 300},
  { field: 'event_status', headerName: 'Event Status',width: 300},
  { field: 'alert_subject', headerName: 'Alert Subject',width: 350,
      renderCell: (params) => {
        if(params.value?.length > 44){
          return(
            <Tooltip title={params.value} placement="top-start">
                <span className='activitiesTab-descText'>{params.value}</span>
            </Tooltip>
          )
        }else{
          return (<span>{params.value}</span>)
        }
    }
  },
  { field: 'event_duration', headerName: 'Event Duration',width: 300},
  { field: 'host_name', headerName: 'Host Name',width: 300},
];


function IncidentsTab({ contract_id }) {
    const classes = useStyles();    
    const [incidents, setIncidents] = useState([])
    const [loading,setLoading] = useState(false) 
    const [ page, setPage ] = useState(0);
    const [ rowCount, setRowCount ] = useState(0)

    function formatData(data){
      if(!data) return []
      return data.map((item) => {
        return {
          id:item.id,
          problem_no: item.problem_id || '-',
          serial_no: item.serial_no || '-',
          event_severity: item.event_severity || '-',
          problem_time: getLocalTimeFromUTC(item.created_at) || '-',
          recovery_time: item.recovery_time || '-',
          event_status: item.event_status || '-',
          alert_subject: item.alert_subject || '-',
          event_duration: item.event_duration || '-',
          host_name: item.host_name
        }
      })
    }

    function onPageChange( event ){
      // {
      //   "page": 1,
      //   "pageCount": 5,
      //   "pageSize": 100,
      //   "paginationMode": "server",
      //   "rowCount": 418
      // }
      setPage(event.page)
    }

    async function getIncidents(){
      setLoading(true);
      try {
        const res = await axios.get(`/contracts/fetch-contract-incident/${contract_id}?limit=${100}&page=${page}`);
        const { rows, count } = res.data.data;
        setIncidents(formatData(rows));
        setRowCount(count)
      } catch (err) {
        console.log(err);
      }finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      getIncidents()
    }, [page])

    if(loading){
      return (
        <FuseLoading/>
      )
    }

    return (
      <div className={classes.root} style={{height: 350, width: '100%',overflow:"auto"}}>
        <DataGrid
          rows={incidents}
          columns={columns}
          pagination
          rowsPerPageOptions={[]}
          rowCount={rowCount}
          paginationMode="server"
          onPageChange={ onPageChange }
          page={page}
        />
      </div>
    )
}

export default withRouter(IncidentsTab)
