import _ from "@lodash";
import React, { useCallback, useEffect, useState, useRef } from "react";
import axios from "@fuse/utils/axios";
import Icon from "@material-ui/core/Icon";
import { Button, TextField, Row, Col, Popover } from "@material-ui/core";
import useToast from "@fuse/hooks/useToast";
import useRouter from "@fuse/hooks/useRouter";
import Tooltip from "@material-ui/core/Tooltip";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import AlphabeticalSorting from "@fuse/utils/alphabeticalSort";
import GeneralModal from "@fuse/components/Modal/GeneralModal.js";
import { closeBulkArchiveModal, setServiceTicketsCount } from 'app/main/assets/store/formViewDialogReducer';
import {
  openFormViewerDialog,
  setFromData,
} from "../store/formViewDialogReducer";

import { useDispatch, useSelector } from "react-redux";
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { addAssetId } from "../../users/store/dialogReducer";
import { setCustomFilterData, setfilterlist, setrowGroupModel } from "../store/filterParamsReducer";
import { setSerialNumberForServiceRequest } from "../../service-request/store/filterParamsReducer";
import { Stack, Box, Typography } from '@mui/material'
import "ag-grid-enterprise";
import "styles/ag-grid-header-style.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { statusColors } from "@fuse/utils/colorName";
import { dateReformatTo_mmm_dd_yyyy } from "@fuse/utils/dateOperations";
import { isArray, result } from "lodash";
import PropTypes from 'prop-types'
import { setAssetQuickFilter, setAssetCount, openReportViewerDialog, assetGroupByHappened } from '../../reports/store/reportViewDialogReducer';
// import { Button, Icon, IconButton, makeStyles, Modal, Paper, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import MapView from "./mapView";
import { useDebounce } from "@fuse/hooks";
import FuseLoading from '@fuse/core/FuseLoading';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import { usePermission } from '@fuse/hooks';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { unMountAssetListFunc } from '../store/reportViewDialogReducer'
import { CropLandscapeOutlined } from "@material-ui/icons";
import { getContractStatus } from "helper/status";
import { closeFormViewerDialog, openFormViewerDialog as openFormViewerDialogForServiceTicket } from 'app/main/service-request/store/formViewDialogReducer';
import AddServiceRequestFormDialog from "app/main/service-request/components/addServiceRequestDialog";

const useStyles = makeStyles({
  layoutRoot: {},
  head: {
    position: "sticky",
    top: 0,
  },
  root: {
    // minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardGen: {
    width: "20%",
    height: "100%",
  },
  searchbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    margin: "5px 5px 5px 0",
    height: "28px",
  },
  activeTab: {
    background: "rgba(107, 44, 87, 0.04);",
    borderRadius: "0",
  },
  buttonSquare: {
    borderRadius: "5px",
  },
  btn: {
    background: "#30C90A",
    width: "120px",
    borderRadius: "17px",
    color: "#fff",
  },
  icon: {
    fontSize: "18px",
    color: "#61656F",
    display: "inline-block",
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function ListView(props) {
  const table_name = "ASSETS";
  const transform_words = {
    id: "ID",
    sku: "SKU",
    eol: "EOL",
    eosl: "EOSL",
    no: "No.",
  };
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("lg");
  const [tabState, setTabState] = useState("information");
  const router = useRouter();
  // const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef();
  const [locationMenu, setLocationMenu] = useState([]);
  const [usersMenu, setUsersMenu] = useState([]);
  const [aggridParam, setAggridParam] = useState([])
  const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
  const [tabValue, setTabValue] = useState(0)
  const { hasPermission } = usePermission();
  const addServiceTicketPermission = hasPermission('add-service_request')

  // const rowGroupPanelShow = "always";
  const tableHeaders = [
    "serial_no", "asset_tag", "name", "user_name", "status", "contract_number", "contract_tag",
    "contract_status", "model_name", "model_number", "category", "manufacturer",
    "location_name", "address", "notes", "created_date", "updated_date", "source", "network_status","mac_id",
  ];
  const [modalData, setModalData] = useState(null);
  const [statusMenu, setStatusMenu] = useState([]);
  const [assetId, setAssetId] = useState(null);

  const [value, setValue] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const toast = useToast(dispatch);
  const [ tableTitle, setTableTitle ] = useState('All Assets')
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const [noOfAsset, setNoOfAsset] = useState(0)
  const [open, setOpen] = useState(false)
  const [logs,setLogs] = useState([]);
  const [ logsLoading,setLogsLoading ] = useState(false)
  const [rowSelected, setRowSelected] = useState(false)
  const handleOpenDialog = () => {
    setOpen(true)
  }
  const mapViewRef = useRef(null);
  const close = () => {
    setOpen(false)
  }
  const [groupByName, setGroupByName] = useState([]);
  const [assetDetails, setAssetDetails] = useState();

  const handleChangeOne = (event) => {
    const {
      target: { value },
    } = event;
    setGroupByName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const names = [
    'Assets Tag',
    'Serial Number',
    'Asset Name',
    'User',
    'Status',
    'Contact Number',
  ];
  const [age, setAge] = useState('');
  // const [openFilter, setOpenFilter] = useState(false);

  const handleChangeExport = (event) => {
    setAge(event.target.value);
  };
  const handleChangeExport1 = (event) => {
    setAge(event.target.value);
  };
  const [filterEl, setFilterEl] = useState(null);
  const dropdownClick = (event) => {
    setFilterEl(filterEl ? null : event.currentTarget)
  }
  const handleFilterClose = () => {
    setFilterEl(null)
  }
  const openFilter = Boolean(filterEl);
  const id = 'simple-popper'

  const [filterEl2, setFilterEl2] = useState(null);
  const dropdownClick2 = (event) => {
    setFilterEl2(filterEl ? null : event.currentTarget)
  }
  const handleFilterClose2 = () => {
    setFilterEl2(null)
  }
  const openFilter2 = Boolean(filterEl2);
  const id2 = 'simple-popper2'
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
  const [ disabledImport, setDisabledImportBtn ] = useState(false)
  const [ redirectedWith, setRedirectedWith ] = useState(null);
  const asset_quick_filter = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.asset_quick_filter
  );
  const quick_added_asset = useSelector(
    (state) => state.assetsApp.reportViewDialogReducer.quickAddedAsset
  );
  const tab_value = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.tab_value
  );

  const user_store_query_data = useSelector(
    (state) => state.usersApp.dialogReducer
  );
  const { openModal } = useSelector((state) => state.assetsApp.formViewDialog)

  const [filterApplied, setFilterApplied] = useState(false);
  const history = useHistory()
  // const filterModel = useSelector((state) => state.assetsApp.filterParamsReducer.filter);
  // Get Query params for redirection
  const manufacturer_id = router.query?.manufacturer_id ?? ''
	const model_id = router.query?.model_id ?? ''
  const serial_id = router.query?.serial_id ?? ''
	const category_id = router.query?.category_id ?? ''
	const contract_header_id = router.query?.contract_header_id ?? ''
  const location_id = router.query?.location_id ?? ''
  const import_asset_in_other_module = props.importAssetInOtherModule ?? false
  const custom_filter_data = useSelector((state) => state.assetsApp.filterParamsReducer.custom_filter_data)
  const openState = useSelector((state) => state.ServiceRequestsApp.formViewDialog)
  function TabButton(props) {
    const { activeClass, tab, tabState, tabStateChange, ...restProps } = props;
    const isActive = tab == tabState;
    return (
      <Button
        {...restProps}
        onClick={() => tabStateChange(tab)}
        className={restProps.className && isActive ? activeClass : ""}
      >
        {props.children}
      </Button>
    );
  }

  function Close() {
    setOpen(false);
    dispatch(addAssetId(null));
    setTabState("information");
  }

  function TabContainer(props) {
    const { tabState, tab } = props;
    const shouldRender = tab == tabState;
    return shouldRender ? props.children : <></>;
  }

  const generateHeaderLabel = (header) => {
    if (header == "serial_no") {
      return "Serial Number";
    }
    if (header == "name") {
      return "Asset Name";
    }
    if (header == "model") {
      return "Model Name";
    }
    if (header == "contract_no") {
      return "Contract Number";
    }
    if (header === "location_name") {
      return "Location";
    }
    
    if (header === "user_name") {
      return "User";
    }
    if (header === "mac_id") {
      return "Mac Address";
    }

    return header
      .split("_")
      .map((word) => {
        if (word in transform_words) return transform_words[word];
        return word.charAt(0).toUpperCase() + word.substring(1);
      })
      .join(" ");
  };

  // function gotoServiceRequestPage(serialNumber) {
  //   if (!serialNumber) return;

  //   const asset_redirection = true;
  //   router.push(`/service-tickets`);
  //   dispatch(setSerialNumberForServiceRequest(serialNumber));
  // }

  function redirectToServiceRequestsPageWithSerialNo(data){
    axios.get(`/assets/${data?.id}`).then((res) => {
      if(res.data.message === "Could not fetch asset") {
          router.push('/not-found')
      }
        setAssetDetails(res.data.data)
      }).catch((err) => {
          console.log('err', err);
      })
		  dispatch(openFormViewerDialogForServiceTicket());
  }

  useEffect(()=>{
    setTabValue(tab_value)
  }, [tab_value])

   useEffect(()=>{
    setRedirectedWith(custom_filter_data)
   // dispatch(setCustomFilterData(null))
  }, [custom_filter_data])

  useEffect( () => {
    refreshAssetList()
    mapViewRef.current?.getMapData(gridRef?.current?.api?.getFilterModel())
  }, [quick_added_asset])


  /** Get details for an individual asset to set in edit form*/
  async function getAssetDetails(asset_id) {
    let asset = null
    const { data } = (await axios.get(`assets/${asset_id}`)).data

    asset = (() => {
      return {
        id: data.id,
        name: data.asset_name,
        serial_no: data.serial_number,
        asset_tag: data.asset_tag,
        country_code: data.country_code,
        status_value: data.asset_status,
        model_id: data.model_id,
        asset_model: {
          id: data.asset_model.id,
          model_name: data.asset_model.model_name,
          model_no: data.asset_model.model_no,
        },
        shipping_location_details: data.shipping,
        notes: data.notes,
        note_style: data.note_style,
        image_name: data.image_name,
        preview_image_link: data.image_link,
        assigned_user_details: data.assigned_user,
        deployed_date: data.deployed_date,
        recovery_date: data.recovery_date,
        contract_header: data.contract_header,
        order_number: data.order_number,
        mac_address: data.mac_id,
        purchase_cost: data.purchase_cost,
        purchase_date: data.purchase_date,
        install_date: data.install_date,
        warranty_end_date: data.warranty_end_date
      }
    })()

    return asset;
  }

  const handelClone = async (data) => {
    const asset = await getAssetDetails(data.id)
    const copy = { ...asset };
    (copy.serial_no = ""), (copy.asset_tag = "");
    copy.assigned_user_details = null;
    copy.cloneItem = true;
    dispatch(setFromData(copy));
    dispatch(openFormViewerDialog());
  };

  /** Handle In-line editing */
  const onCellValueChanged = (e) => {
    /**
     * In line editable field
     * asset_name,user_name,asset_status,location
    */

    if (e.column.getColDef().cellEditor === 'agSelectCellEditor') {
      const rowIndex = e.node.rowIndex;
      const colId = e.column.getColId();
      e.api.startEditingCell({ rowIndex, colKey: colId, charPress: null });
    }

    let entity_name = ''; let entity_value = null; const asset_id = e.data.id;

    /** Asset name */
    if (e.colDef.field == "name") {
      entity_name = 'asset_name'
      entity_value = e.newValue;

      if (!entity_value.length) {
        toast.error("Asset Name is required");
        refreshAssetList()
        return;
      }
    }

    /** Asset Status */
    if (e.colDef.field == "status") {
      entity_name = 'status_id'
      refreshAssetList()
      // entity_value = statusMenu.find((item) => item.label == e.newValue?.split(",")[0]).id;
    }

    /** Address */
    if (e.colDef.field == "address") {
      entity_name = 'ship_to_locations_id'
      entity_value = locationMenu.find(
        (option) => `${option.address1}, ${option.city}, ${option.state}, ${option.country}` ==
          e.newValue
      ).id;
    }

    /** User Name */
    if (e.colDef.field == "user_name") {
      refreshAssetList()
      // entity_name = 'user_id'
      // entity_value = usersMenu.find(
      //   (item) =>
      //     `${item.first_name} ${item.last_name}(${item.email})` == e.newValue
      // ).id;
    }

    const payload = {
      entity_name,
      entity_value,
      asset_id
    };

    if (!entity_name.length) return

    axios
      .post(`/assets/inline-edit`, payload)
      .then((results) => {
        toast.success("Asset updated successfully.");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handelEdit = async (data) => {
    const asset = await getAssetDetails(data.id)
    dispatch(setFromData(asset));
    dispatch(openFormViewerDialog());
  };

  const handleClickOpen = (asset_id) => {
    setAssetId(asset_id);
    // setOpen(true);
    router.push(`/assets-list/${asset_id}`)
  };

  const handleArchive = (data) => {
    setModalData(data);
  };

  const handleAddAsset = () => {
    dispatch(openFormViewerDialog());
  }

  const handleAddReport = () => {
    dispatch(openReportViewerDialog())
  }

  function formatLogs (data) {
		return data.map((item) => {
			return {
				id:item.id,
				action_id: `act${item.id}`,
				serial_number: item.asset.serial_number,
				action_date: dateReformatTo_mmm_dd_yyyy(item.action_date_time),
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				user_role: item.user_role_name,
				field_name: item.action_field_name
			}
		})
	}

  function getAllAssetActivities () {
		setLogsLoading(true)
    setAllArchiveId([])
		axios.get(`/assets/get-all-activities`).then(({data})=>{
			setLogs(formatLogs(data.data.activities));
		})
		.catch(()=>{
			console.log('cannot fetch logs');
		}).finally(()=>{
			setLogsLoading(false)
		})
	}


  useEffect(()=>{
		(tabValue == 1) && getAllAssetActivities()
	},[tabValue])

  function handleClose() {
    setModalData(null);
  }

  function handleConfirm() {
    axios.delete(`assets/archive-asset/${modalData.id}`, {
      data: {
        assigned_user: modalData.assigned_user_details,
        assigned_contract: modalData.contract_header,
      },
    }).then(() => {
      handleClose();
      refreshAssetList()
      toast.success("Asset archived Successfully.");
    }).catch((err) => {
      console.log("err", err.response);
      if (
        err.response.data.message === "Asset involved with a contract or assigned to an User"
        || err.response.data.message === "Contract is still active. You can not delete the asset."
      ) {
        handleClose();
        toast.error(err.response.data.message);
      } else {
        toast.error(err.response.data.message || 'Something went wrong!')
      }
    });
  }

  function flattenAssets(assets) {
    return assets.map((asset) => {
      const {
        asset_table_id, serial_number, asset_tag, asset_name, user_name,mac_id,
        asset_status_name, asset_status_color, contract_number, contract_tag_name,contract_end_date,
        contract_tag_color, model_name, model_no, category_name, manufacturer_name,
        location_name, address, notes, created_at, updated_at, source, connection_status,
        childcount = undefined
      } = asset;
      return {
        id: asset_table_id,
        serial_no: serial_number,
        mac_id: mac_id,
        asset_tag,
        name: asset_name,
        user_name,
        status: asset_status_name,
        asset_status_color,
        contract_number,
        contract_tag: contract_tag_name,
        contract_status: contract_end_date ? getContractStatus(contract_end_date)?.status : 'N/A',
        contract_tag_color,
        model_name,
        model_number: model_no,
        category: category_name,
        manufacturer: manufacturer_name,
        location_name,
        address,
        notes,
        created_date: dateReformatTo_mmm_dd_yyyy(created_at),
        updated_date: dateReformatTo_mmm_dd_yyyy(updated_at),
        source,
        network_status: connection_status,
        childCount: childcount
      }
    })
  }

  /** This useCallback function, when invoked, refreshes the asset list in the grid.
  *It checks if the grid API is available before attempting to refresh.
  */
  const refreshAssetList = useCallback(() => {
    if (!gridRef.current) return
    gridRef.current.api.refreshServerSideStore({ purge: false })
  }, [gridRef]);

  /** This function handles the fetching of asset data from the server.
  It sends a POST request to the '/assets' endpoint with parameters for server-side data retrieval.
  available property in params object
    {
    "startRow": 0,
    "endRow": 100,
    "rowGroupCols": [],
    "valueCols": [],
    "pivotCols": [],
    "pivotMode": false,
    "groupKeys": [],
    "filterModel": {},
    "sortModel": []
    }
  */
  function onFetchAsset(params){
    let url = '' ;
    const quick_filter = Boolean(asset_quick_filter) ? asset_quick_filter : ""
    if(window.location.search === '' || router.query.report_name) {
      
      url = `/assets?manufacturer_id=&model_id=&category_id=&contract_header_id=&location_id=&quick_filter=&serial_id=`
    } else {
      url = `/assets${window.location.search}&quick_filter=${quick_filter}`
    }
    // console.log(url)
    const {startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode} = params.request;
    axios.post(url,{
      dataSourceParams: {
        startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode
      }
    }).then((res)=>{
      if(!groupKeys.length) dispatch(setAssetCount(res.data.data.lastRow.toLocaleString()));
      if(rowGroupCols.length){
        dispatch(assetGroupByHappened(true));
      }else{
        dispatch(assetGroupByHappened(false));
      } 
      if(res.data.data.rows.length === 0) gridRef.current.api.showNoRowsOverlay();
      if(res.data.data.rows.length && window.location.search != ''){
        switch (Object.keys(router.query)[0]) {
          case 'manufacturer_id':
            // setRedirectedWith({Manufacturer: res.data.data.redirectionMeta.manufacturer_name})
            setTableTitle(`Assets filtered by manufacturer ${res.data.data.redirectionMeta.manufacturer_name}`)
            break;
          case 'model_id':
            // setRedirectedWith({'Model Name': res.data.data.redirectionMeta.model_name})
            setTableTitle(`Assets filtered by model ${res.data.data.redirectionMeta.model_name}`)
            break;
          case 'category_id':
            // setRedirectedWith({Category: res.data.data.redirectionMeta.category_name})
            const network_status = router.query?.network_status
            if(network_status) setTableTitle(`Assets filtered by network status ${network_status}`)
            else setTableTitle(`Assets filtered by category ${res.data.data.redirectionMeta.category_name}`)
            break;
          case 'location_id':
            // setRedirectedWith({Location: res.data.data.redirectionMeta.location_name})
            setTableTitle(`Assets filtered by location ${res.data.data.redirectionMeta.location_name}`)
            break;
          default:
            break;
        }
      }
      params.successCallback(flattenAssets(res.data.data.rows), res.data.data.lastRow);
    }).catch((err) => {
      console.log('error', err)
      params.failCallback();
    })
  }

  /** This object represents the data source for the grid.
  *It defines a `getRows` function that is invoked by the grid to fetch rows from the server.
  */
  const datasource = {
    getRows(params) {
      // Call the function to fetch asset data from the server.
      onFetchAsset(params)
    }
  };

  const onGridReady = (params) => {
    setAggridParam(params)
    params.api.sizeColumnsToFit()
    // setGridApi(params)
    if (router.query.report_name == undefined) {
      params?.api.setServerSideDatasource(datasource);
      if(!custom_filter_data){
        loadInitialState(params)
      }
      dispatch(setfilterlist({}));
      // dispatch(setrowGroupModel([]));
      // loadInitialState(params);
      dispatch(setrowGroupModel([]));
    }
    else {
      setRowGroupPanelShow('always')
      getReport(params);
      // loadInitialState(params)
    }
    mapViewRef.current?.getMapData(gridRef?.current?.api?.getFilterModel())
  };

  const onFirstDataRendered = (params) => {
    // console.log("0979087", gridRef.current.api.getModel().getRowCount())
  }

  const onColumnMoved = (params) => {
    const rows = params.columnApi.getColumnState();
    if (router.query.report_name == undefined) {
      saveView(rows);
      dispatch(setrowGroupModel(rows))
    }

  }

  const onColumnPinned = (params) => {
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
      saveView(rows);
      dispatch(setrowGroupModel(rows))
    }

  };

  function onSortChanged(params) {
    const rows = params.columnApi.getColumnState();
    if (router.query.report_name == undefined) {
      dispatch(setrowGroupModel(rows))
      saveView(rows);
    }

  }

  // function onColumnVisible(params) {
  //   const rows = params.columnApi.getColumnState();
  //   dispatch(setrowGroupModel(rows))
  //   const total_no_of_columns = rows.length;
  //   let total_hidden_columns = 0;
  //   rows.forEach((row) => {
  //     const { hide } = row;
  //     if (hide) {
  //       ++total_hidden_columns;
  //     }
  //   });
  //   if (total_hidden_columns < total_no_of_columns) {
  //     if (router.query.report_name == undefined) {
  //       saveView(rows);
  //     }
  //   }
  // }

  const getMapDataOnFilterChange = useDebounce(value => {
    mapViewRef.current?.getMapData(value)
	}, 500);

  function onFilterChanged(event) {
    if(import_asset_in_other_module) return
    const rows = event.api.getFilterModel();
    // stop rerender to show no overlay text
    dispatch(setfilterlist(rows));

    saveView("N/A", rows, gridRef.current.filterModelArr)
    // saveView(rows);
    // validation for no-overlay
    const total_rows = event.api.getDisplayedRowCount();
    if (total_rows === 0) {
      event.api.showNoRowsOverlay();
    } else {
      event.api.hideOverlay();
    }

    //Call map-view
    getMapDataOnFilterChange(gridRef?.current?.api?.getFilterModel())
  }

  // function onColumnRowGroupChanged(event) {
  //   event.columns.map(item=>{
  //     item.indexOf(item.colId)
  //   })
  //   console.log("event", event.columns)
  //   // const groupedColumns = gridRef.current.api.getRowGroupColumns();

  //   //   if (groupedColumns && groupedColumns.length > 0) {
  //   //     setRowGroupPanelShow('never')
  //   //   }
  //   if (router.query.report_name == undefined) {
  //     const rows = event?.columnApi?.getColumnState();
  //     // const first_row = rows[0];
  //     // if (first_row.colId != "ag-Grid-AutoColumn") {
  //     //   saveView(rows);
  // 		// 	dispatch(setrowGroupModel(rows));
  //     // } else {
  //     //   dispatch(setrowGroupModel(rows));
  //     //   saveView(rows);
  //     // }
  //   }
  // }

  function valueFormatter(params) {
    return dateReformatTo_mmm_dd_yyyy(params.value)
  }

  function getAllSelectedRows(){
    props.handleImport(gridRef.current.api.getSelectedRows())
  }

  function isRowSelectable(params){
    if(!import_asset_in_other_module) return true
    return params.data.contract_number == "No Contract Number" ? true : false
  }

  function isEditable() {
    if(import_asset_in_other_module) return false
    if(
      props.updateAssetPermission.length > 0 ||
      props.updateAssetPermission == "all" ||
      props.updateAssetPermission.map((i) => i == data.location_obj.id ).length > 0
    ){
      return true
    }
    return false
  }

  function getReport(event) {
    const { report_name } = router.query;
    const table_name = "asset";
    axios
      .get(
        `/reports/view-report?table_name=${table_name}&report_name=${report_name}`
      )
      .then((results) => {
        const model = JSON.parse(results.data.data.filter);
        event.api.setFilterModel(model);
        const row_model = JSON.parse(results.data.data.row_model);
        event.columnApi.setColumnState(row_model);
        event?.api.setServerSideDatasource(datasource);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function saveView(rows, filter, filterModelArr=null) {
    if (isArray(rows) && !rows.length) return
    let payload = {}
    if (rows == 'N/A') {
      payload = {
        filter: JSON.stringify(filterModelArr ?? filter),
        table_name
      }
    } else {
      payload = {
        order: JSON.stringify(rows),
        table_name
      }
    }

    axios.post('/user-view/save-view-order', payload)
      .then(() => {
        console.log('view updated')
      })
      .catch(err => {
        console.log(err);
      })
  }

  function loadInitialState(params) {
    axios
      .get(`/user-view/show-view-order?table_name=${table_name}`)
      .then((results) => {
        const { data } = results.data;
        if (data !== null) {
          const order = JSON.parse(data.order);
          const filter = JSON.parse(data?.filter);
          gridRef.current = {...gridRef.current,filterModelArr:filter}
            params?.columnApi.applyColumnState({ state: order });
            params.api.setFilterModel(filter);
            if (Object.keys(filter || {})?.length) {
              setFilterApplied(true)
            }
          }
          // gridRef.current.api.setFilterModel(filterModel);
          params?.api.setServerSideDatasource(datasource);
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        params?.api.setServerSideDatasource(datasource);
      })
  }

  const getChildCount = data => {
    // here child count is stored in the 'childCount' property
    return data.childCount;
  };

  const fetchStatus = () => {
    axios.get(`asset-status?type=hardware`).then((response) => {
      const status = AlphabeticalSorting(response.data.data, "status_name");
      setStatusMenu(
        status.map((item) => {
          return {
            id: item.id,
            label: item.status_name,
            color: item.status_color.code,
          };
        })
      );
    }).catch((err) => {
      console.log("136=>", err);
    });
  };

  const fetchLocationSuggestions = (value = "") => {
    axios.get(`assets/fetch-location-suggestion?value=${value}`).then((response) => {
      const locations = AlphabeticalSorting(
        response.data.data.locations,
        "location_name"
      );
      setLocationMenu(locations);
    }).catch((err) => {
      console.log("136=>", err);
    });
  };

  const fetchUserSuggestions = () => {
    axios.get("/assets/fetch-users-suggestion").then((response) => {
      const users = AlphabeticalSorting(response.data.data.users, "first_name");
      const user_info = [];
      users.forEach((item) => {
        user_info.push(`${item.first_name} ${item.last_name}(${item.email})`);
      });
      setUsersMenu(users);
    }).catch((err) => {
      console.log("164=>", err);
    });
  };

  useEffect(() => {
    if (user_store_query_data.asset_id) {
      setOpen(true);
      setAssetId(user_store_query_data.asset_id);
    }
    fetchStatus();
    fetchLocationSuggestions();
    fetchUserSuggestions();
    dispatch(setServiceTicketsCount(null))
    return () => {
      dispatch(unMountAssetListFunc())
    }
    // gridRef.current.api.columnApi.setColumnVisible('serial_no', false);
  }, []);

  useEffect(() => {
    if (!gridRef.current || asset_quick_filter == null) return
    gridRef.current.api.setServerSideDatasource(datasource);
  }, [asset_quick_filter]);


  useEffect(() => {
    history.listen((location) => {
      // if(location.search.includes("category_id") || location.search.includes("location_id") || location.search.includes("serial_id")) {
        gridRef.current?.api.setServerSideDatasource(datasource);
      // }
    })
  }, [history]);


  useEffect(() => {
    const onBtStopEditing = () => {
      gridRef?.current?.api?.stopEditing();
    };
    window.addEventListener("click", onBtStopEditing);
    return () => {
      window.removeEventListener("click", onBtStopEditing);
      dispatch(setAssetQuickFilter(null))
    };
  }, []);

  useEffect(() => {
    if (filterApplied) {
      toast.success("Filters have been applied")
    }
  }, [filterApplied])

  const frameworkComponents = {
    customDropdownEditor: CustomDropdownEditor,
    customFilter: CustomFilter,
  };

  /** Add refreshAssetList function as an Event Listener
   * to call it from Layout file or from any other places.
   */
  useEffect(() => {
    setTimeout(() => {
      window.addEventListener("refreshAssetList", refreshAssetList);
    }, 0)

    return () => {
      window.removeEventListener("refreshAssetList", refreshAssetList);
    };
  }, [refreshAssetList]);

  useEffect(() => {
    if (!router.params?.serial_no) return
    handleClickOpen(router.params.serial_no)
  }, [])

  useEffect(() => {
    if (gridRef.current) {
      const { columnApi } = gridRef.current;
      // Now you can access columnApi and perform your actions
    }
  }, [gridRef]);

  // for new design filter and group by
  const [groupByArr, setGroupByArr] = useState([])
  const [allArchiveId, setAllArchiveId] = useState([])
  const [archiveAssetName, setArchiveAssetName] = useState()
  const [groupByHappen, setGroupByHappen] = useState(false)

  // function onColumnRowGroupChanged(event) {
  //   if (event.columns.length == 0) {
  //     setRowGroupPanelShow('never')
  //     setRowSelectionCheckboxGroupBy({
  //       "Serial Number": false, "Asset Tag": false, "Asset Name": false,
  //       "User": false, "Status": false, "Contract Number": false
  //     })
  //     setGroupByArr([])
  //     setGroupByHappen(false)
  //   } else {
  //     for (const key of groupByArr) {
  //       setGroupByHappen(true)
  //       if ((event.columns.filter(item => item.colDef.headerName == key)).length) {
  //         setRowSelectionCheckboxGroupBy(prevArray => ({
  //           ...prevArray,
  //           [key]: true
  //         }))

  //       } else {
  //         setRowSelectionCheckboxGroupBy(prevArray => ({
  //           ...prevArray,
  //           [key]: false
  //         }))

  //         let newArr = [...groupByArr]
  //         const indexToRemove = newArr.indexOf(key);
  //         newArr.splice(indexToRemove, 1);
  //         setGroupByArr(newArr)
  //       }
  //     }
  //   }

  //   // event.columns.map(item=>{
  //   //   console.log("9087987", item.colDef.headerName)
  //   //   const index = columnHeaderName.indexOf(item.colId)
  //   //   indexArr[index] = true
  //   //   // setSerialNoCheck(prevArray => {
  //   //   //   const newArray = [...prevArray];
  //   //   //   newArray[index] = true;
  //   //   //   return newArray;
  //   //   // });
  //   // })
  //   // setSerialNoCheck(indexArr)
  //   // const groupedColumns = gridRef.current.api.getRowGroupColumns();

  //   //   if (groupedColumns && groupedColumns.length > 0) {
  //   //     setRowGroupPanelShow('never')
  //   //   }
  //   if (router.query.report_name == undefined) {
  //     const rows = event?.columnApi?.getColumnState();
  //     // const first_row = rows[0];
  //     // if (first_row.colId != "ag-Grid-AutoColumn") {
  //     //   saveView(rows);
  //     // 	dispatch(setrowGroupModel(rows));
  //     // } else {
  //     //   dispatch(setrowGroupModel(rows));
  //     //   saveView(rows);
  //     // }
  //   }
  // }

  function handleHideColumnDropDownDesign(){
    if(!groupByArr.length){
      return { border: '1px solid #000000', borderRadius: '16px', padding: '4px 12px', backgroundColor: 'transparent', lineHeight: '24px', position: 'relative' }
    }else{
      return {}
    }
  }
  function onColumnRowGroupChanged(event) {
		if(event.columns.length == 0) {
			setGroupByArr([])
      setGroupByHappen(false)
		}else{
			setGroupByArr(event.columns)
      setGroupByHappen(true)
		}
    if (router.query.report_name == undefined) {
      const rows = event?.columnApi?.getColumnState();
      const first_row = rows[0];
      if (first_row.colId != "ag-Grid-AutoColumn") {
  			dispatch(setrowGroupModel(rows));
        saveView(rows);
      } else {
        dispatch(setrowGroupModel(rows));
        // saveView(rows);
      }
    }
	}

  function onRowSelected(params) {
    // params.columnApi.setColumnVisible('serial_no', false);
    let selectedRow = gridRef.current.api.getSelectedRows()
    if (selectedRow.length) {
      if(selectedRow.length == 1 ) setArchiveAssetName(selectedRow[0].name)
      const ids = selectedRow.map(item => item.id)
      setAllArchiveId(ids)
      setRowSelected(true)
      setDisabledImportBtn(true)
    }
    else {
      setRowSelected(false)
      setAllArchiveId([])
      setDisabledImportBtn(false)
    }
  }

  const bulkAssetArchive = () => {
    const payload = {
      ids: allArchiveId
    }
    axios.post('/assets/bulk-archive', payload).
      then(response => {
        dispatch(closeBulkArchiveModal())
        if (response.status == 201) {
          toast.error(response.data.message)
        } else {
          toast.success("Asset archived successfully")
          gridRef.current.api.setServerSideDatasource(datasource);
          gridRef.current.api.deselectAll();
        }


      }).catch(err => {
        console.log(err)
      })
  }

  const handleServiceTicketCreation = () => {
    let selectedRow = gridRef.current.api.getSelectedRows()
    if (selectedRow.length > 1) {
      toast.error("Please select one row to create service ticket")
    } else {
      redirectToServiceRequestsPageWithSerialNo(selectedRow[0])
    }

  }

//   function getContextMenuItems(params) {
//     var result = [
//        {
//             name: "Excel Export (.xlsx)",
//             action: () => params.api.exportDataAsExcel(
//               {onlySelected: true}
//             )
//         },
//     ];

//     return result;
// }

// function getContextMenuItems(params) {
//   var result = [
//       // default built-in items
//       'copy',
//       'copyWithHeaders',
//       'paste',
//       'separator',
//       {
//           name: 'Custom Action',
//           action: function() {
//               console.log('Custom Action clicked');
//           }
//       }
//   ];

//   // add a custom item based on the selected row data
//   if (params.node) {
//       result.push({
//           name: 'Custom Action for ' + params.node.data.name,
//           action: function() {
//               console.log('Custom Action for ' + params.node.data.name + ' clicked');
//           }
//       });
//   }

//   return result;
// }

  const handleChildClick = (value) => {
		// setTabValue(value)
  };

  const clearRedirectionFilter = () => {
      dispatch(setCustomFilterData(null))
      setRedirectedWith(null)
      setTableTitle("All Assets")
      router.push('/assets-list')
      gridRef.current?.api.setServerSideDatasource(datasource);
      mapViewRef.current?.getMapData(gridRef?.current?.api?.getFilterModel())
  }

  const handleCancelBulkArchiveModal = () =>{
    dispatch(closeBulkArchiveModal())
  }

  function handleAddTicketFormClose () {
    setAssetDetails()
		dispatch(closeFormViewerDialog())
	}

  return (
    <>
    {!import_asset_in_other_module && <div role="presentation" className="cstm_breadcrumbs_holder">
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit">
          Dashboard
        </Link>
        <Link
          underline="hover"
          color="inherit"
          onClick={()=>router.push("/assets-dashboard")}
          style={{ cursor: 'pointer' }}
        >
          Assets Dashboard
        </Link>
        <span>Assets Listing</span>
      </Breadcrumbs>
    </div>}

    {!import_asset_in_other_module && <h4 className="main_page_heading">Assets Listing</h4>}

    <Box sx={{ width: '100%' }}>
    {!import_asset_in_other_module && <Box sx={{ marginTop: '8px' }} className="map_outer_holder">
        <MapView ref={mapViewRef}/>
      </Box>}
      {(tabValue == 0) && <AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
      groupByArrFromList ={ groupByArr }
      tableName= {'asset'} logs={logs} displayTableName = {tableTitle}
      allArchiveId = { allArchiveId }
      onClick={handleChildClick}
      bulkAssetArchive = { bulkAssetArchive }
      handleServiceTicketCreation = { handleServiceTicketCreation }
      customFilterData={redirectedWith}
      clearRedirectionFilter={import_asset_in_other_module ? ()=>{} : clearRedirectionFilter}
      onClickAddButton={handleAddAsset}
      onClickAddReportButton={handleAddReport}
      addPermission = {(props.createAssetPermission.length > 0 ||
        props.createAssetPermission == "all")}
      addServiceTicketPermission={addServiceTicketPermission}
      hideButton={import_asset_in_other_module}
      handleAssetImport={getAllSelectedRows}
      disableImportBtn={disabledImport}
		/>}
    {(tabValue == 1) && (<ActivityAgGridListing module={'asset'} logs={logs} displayTableName = {'All Assets'} loadingActivityLog={logsLoading}
    addPermission = {(props.createAssetPermission.length > 0 ||
      props.createAssetPermission == "all")}
    />)}

    {(openModal && allArchiveId.length >1 ) &&  (
      <GeneralModal
        open={openModal}
        title={"Archive Asset"}
        text={
          <h4>
            Are you sure to Archive these Assets?
            {/* <b>{modalData.name}</b>? */}
          </h4>
        }
        detailMessage={"Your selected asset will be moved to archive asset if you proceed"}
        handleClose={handleCancelBulkArchiveModal}
        handleConfirm={bulkAssetArchive}
        actionButtonName="Yes, Proceed!"
      />
    )}
    {(openModal && allArchiveId.length == 1 ) &&  (
      <GeneralModal
        open={openModal}
        title={"Archive Asset"}
        text={
          <h4>
          Are you sure to Archive this Asset named as <b>{archiveAssetName}</b>?
            {/* <b>{modalData.name}</b>? */}
          </h4>
        }
        handleClose={handleCancelBulkArchiveModal}
        handleConfirm={bulkAssetArchive}
        actionButtonName="Archive"
      />
    )}
    {/* {(props.viewAssetPermission != 'all' && props.viewAssetPermission?.length == 0) && 
			<div>
				<Card>
					<CardContent>You don't have permission to view any Assets at this time. Please contact your Systems Administrator for access.</CardContent>
				</Card>
			</div>
		} */}

    </Box>
        {/* <AddDashboardAlert open={open} close={close} /> */}
      {(tabValue == 0) && <div
        className="ag-theme-alpine lllllll"
        style={{
          width: "100%",
          height: windowHeight + 125,
          fontSize: "12px",
        }}
      >
        {/* {filterApplied && <p style={{margin: '4px'}}>Filters have been applied</p>} */}
        {/* {Boolean(Object.keys(redirectedWith).length) && <p style={{margin: '4px'}}>
          Filters have been applied with {Object.keys(redirectedWith)[0]}: <b>'{Object.values(redirectedWith)[0]}'</b>
          </p>} */}
        <AgGridReact
          ref={gridRef}
          // columnDefs={columnDefs}
          // rowSelection={rowSelection}
          // Setup SSRM
          rowModelType='serverSide'
          serverSideStoreType="partial"
          defaultColDef={{
            // flex: 1,
            // minWidth: 100,
            sortable: true,
            resizable: true
          }}
          // Set the desired sheet name
          defaultExcelExportParams={{
            sheetName: 'Dalos-Source'
          }}
          suppressRowClickSelection
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          animateRows
          // rowData={props.endUsersAccounts}
          rowHeight={38}
          // no rows overlay
          overlayNoRowsTemplate="<span>No Result Found</span>"
          // Row Grouping conditions
          showOpenedGroup
          suppressDragLeaveHidesColumns
          suppressMakeColumnVisibleAfterUnGroup
          rowGroupPanelShow={rowGroupPanelShow}
          onColumnMoved={onColumnMoved}
          onColumnPinned={onColumnPinned}
          // onColumnVisible={onColumnVisible}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          groupHeaderHeight={1}
          headerHeight={44}
          floatingFiltersHeight={40}
          autoGroupColumnDef={{ minWidth: 100 }}
          // on column group changed
          onColumnRowGroupChanged={onColumnRowGroupChanged}
          // getContextMenuItems = { getContextMenuItems }
          cacheQuickFilter
          // groupIncludeFooter= {false}
          // Pagination Condition
          pagination
          suppressHorizontalScroll= {false}
          getChildCount={getChildCount}
          //Row Selection
          rowSelection={'multiple'}
          isRowSelectable={isRowSelectable}
          onRowSelected={onRowSelected}
          frameworkComponents={frameworkComponents}

          /** 
           * Configuring the Side Bar
           * Donn't remove this section
          */
          // sideBar={'columns'}
          // gridOptions={{
          //   sideBar: {
          //     toolPanels: [
          //       {
          //         id: 'columns',
          //         labelDefault: 'Columns',
          //         labelKey: 'columns',
          //         iconKey: 'columns',
          //         toolPanel: 'agColumnsToolPanel',
          //       },
          //     ],
          //     defaultToolPanel: null,
          //     // visible: false,
          //   }
          // }}
          sortable={true}
        >
          {tableHeaders.map((header) => {
            // if (skipHeader.includes(header)) return
            if (header === "serial_no") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  minWidth={222}
                  suppressSizeToFit
                  suppressRowClickSelection
                  headerName={generateHeaderLabel(header)}
                  filter="text"
                  sortable
                  
                  checkboxSelection={!groupByHappen}
                  pinned={import_asset_in_other_module ? "left" : ""}
                  lockPinned={import_asset_in_other_module}
                  suppressColumnsToolPanel={import_asset_in_other_module}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  headerCheckboxSelectionFilteredOnly
                  headerCheckboxSelection
                ></AgGridColumn>
              );
            }
            if (header == "name") {
              return (
                <AgGridColumn
                  field={header}
                  minWidth={200}
                  key={header}
                  suppressSizeToFit
                  onCellValueChanged={onCellValueChanged}
                  headerName={generateHeaderLabel(header)}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  sortable
                  // floatingFilter
                  searchbar
                  filter="text"
                  // enableRowGroup={true}
                  editable={isEditable}                  
                ></AgGridColumn>
              );
            }
            if (header == "user_name") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  minWidth={250}
                  cellEditorPopup
                  suppressSizeToFit
                  // cellEditor="agRichSelectCellEditor"
                  onCellValueChanged={onCellValueChanged}
                  cellEditor='customDropdownEditor'
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  sortable
                  
                  headerName={generateHeaderLabel(header)}
                  filter="text"
                  editable={isEditable}
                  cellEditorParams={{
                    values: usersMenu.map(
                      (item) =>
                        `${item.first_name} ${item.last_name}(${item.email}),${item.id}`
                    ),
                  }}
                  cellRendererFramework={(event) => {
                    if (event.colDef.field === "user_name") {
                      return (
                        <span
                          // onClick={dropdownClickOne} 
                          style={{ backgroundColor: 'transparent' }}>
                          {/* <span
                          style={{
                            height: "10px",
                            width: "10px",
                            marginTop: "2px",
                            borderRadius: "50%",
                            display: "inline-block",
                            // backgroundColor: event.data?.asset_status_color?.code
                            backgroundColor:
                              event.value?.split(",")[1] ??
                              event.data?.asset_status_color?.code,
                          }}
                         /> */}
                          {/* <span style={{height: '10px',width: '10px',backgroundColor: '#bbb',borderRadius: '50%',display:'inline-block'}}></span> */}
                          {/* <span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span> */}
                          <span style={{ marginLeft: "5px", marginRight: '14px', fontSize: '12px', fontWeight: '500', color: 'black', fontFamily: '"Inter", sans-serif', }}>
                            {event.value?.split(",")[0]}
                          </span>
                          {/* <span style={{position:'absolute', right:'3%', top:'4%'}}>
                          <Icon>
                            expand_more
                          </Icon>
                        </span> */}
                        </span>
                      );
                    } if (
                      event.colDef.headerName == "Group" &&
                      event.node.field == "user_name"
                    ) {
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );
                    }
                    return (
                      <>
                        <span>{event.value}</span>
                      </>
                    );

                  }}                
                ></AgGridColumn>
              );
            }
            if (header == "status") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  minWidth={230}
                  cellEditorPopup
                  suppressSizeToFit
                  // cellEditor="agRichSelectCellEditor"
                  onCellValueChanged={onCellValueChanged}
                  cellEditor='customDropdownEditor'
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  sortable
                  
                  // floatingFilter
                  headerName={generateHeaderLabel(header)}
                  filter="text"
                  editable={isEditable}
                  cellEditorParams={{
                    values: statusMenu.map(
                      (s) => `${s.label},${statusColors[s.color]}, ${s.id}`
                    ),
                    suppressCloseOnSelect: true
                  }}
                  cellRendererFramework={(event) => {
                    if (event.colDef.field === "status") {
                      return (
                        <span
                          // onClick={dropdownClickOne} 
                          style={handleHideColumnDropDownDesign()}>
                          <span
                            style={{
                              height: "10px",
                              width: "10px",
                              marginTop: "2px",
                              borderRadius: "50%",
                              display: "inline-block",
                              // backgroundColor: event.data?.asset_status_color?.code
                              backgroundColor:
                                event.value?.split(",")[1] ??
                                event.data?.asset_status_color?.code,
                            }}
                          />
                          {/* <span style={{height: '10px',width: '10px',backgroundColor: '#bbb',borderRadius: '50%',display:'inline-block'}}></span> */}
                          {/* <span style={{marginLeft: '5px'}}>{event.data?.status_value?.status_name}</span> */}
                          <span style={{ marginLeft: "5px", marginRight: '14px', fontSize: '12px', fontWeight: '500', color: 'black', fontFamily: '"Inter", sans-serif', }}>
                            {event.value?.split(",")[0]}
                          </span>
                          {!groupByArr.length && <span style={{ position: 'absolute', right: '3%', top: '4%' }}>
                            <Icon>
                              expand_more
                            </Icon>
                          </span>}
                        </span>
                      );
                    } if (
                      event.colDef.headerName == "Group" &&
                      event.node.field == "status"
                    ) {
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );
                    }
                    return (
                      <>
                        <span>{event.value}</span>
                      </>
                    );

                  }}
                />
              );
            }
            if (header == "address") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  minWidth={200}
                  onCellValueChanged={onCellValueChanged}
                  cellEditorPopup
                  cellEditor="agRichSelectCellEditor"
                  headerName={generateHeaderLabel(header)}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  filter="text"
                  sortable
                  
                  suppressSizeToFit
                  // floatingFilter
                  cellEditorParams={{
                    values: locationMenu.map(
                      (option) =>
                        `${option.address1}, ${option.city}, ${option.state}, ${option.country}`
                    ),
                  }}                 
                 />
              );
            }                                  
            if (header == "contract_tag") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  suppressSizeToFit
                  filter="text"
                  sortable
                  
                  minWidth={200}
                  // floatingFilter
                  headerName={generateHeaderLabel(header)}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  cellRendererFramework={(event) => {
                    if (event.colDef.field === "contract_tag") {
                      return (
                        // <span style={{ border:'1px solid #000000',borderRadius:'16px', padding:'4px 10px', backgroundColor:'transparent', lineHeight:'24px'}}>
                        <span style={handleHideColumnDropDownDesign()}>
                          <span
                            style={{
                              height: "10px",
                              width: "10px",
                              marginTop: "2px",
                              borderRadius: "50%",
                              display: "inline-block",
                              backgroundColor:
                                event.data?.contract_tag_color?.code
                            }}
                           />
                          <span style={{ marginLeft: "5px", fontSize:'12px', fontWeight:'500', color:'black', fontFamily:'"Inter", sans-serif', }}>
                            {event.value}
                          </span>
                        </span>
                      );
                    } if (
                      event.colDef.headerName == "Group" &&
                      event.node.field == "contract_tag"
                    ) {
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );
                    } 
                      return (
                        <>
                          <span>{event.value}</span>
                        </>
                      );

                  }}                  
                 />
              );
            }        
            if (header === "notes") {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  minWidth={200}
                  suppressSizeToFit
                  headerName={generateHeaderLabel(header)}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  filter="text"
                  sortable
                  
                  // floatingFilter
                  cellRendererFramework={(params)=>{
                    return <abbr style={{textDecoration: 'none'}} title={params.value}>{params.value}</abbr>
                  }}
                 />
              );
            }
            if (header === 'created_date' || header === 'updated_date') {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  headerName={generateHeaderLabel(header)}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  // valueFormatter={valueFormatter}
                  filter="text"
                  minWidth={200}
                  sortable
                  
                  // floatingFilter
                 />
              );
            } 
            if(header === 'source') {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  headerName={generateHeaderLabel(header)}
                  filter="text"
                  sortable
                  minWidth={180}
                  
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                  // floatingFilter
                  cellRendererFramework={(event) => {
                    if(event.value === "manual") {
                      return <span>Manual</span>
                    } if(event.value === "csv") {
                      return <span>CSV</span>
                    } 
                      return <span>{event.value}</span>

                  }}
                 />
              );
            } 
            if (header === 'contract_number') {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  headerName={generateHeaderLabel(header)}
                  filter="text"
                  sortable
                  
                  minWidth={200}
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                // floatingFilter
                // cellRendererFramework={(event) => {
                //   if(event.value === "manual") {
                //     return <span>Manual</span>
                //   } if(event.value === "csv") {
                //     return <span>CSV</span>
                //   } 
                //     return <span>{event.value}</span>

                // }}
                />
              );
            }
            if (header === 'asset_tag') {
              return (
                <AgGridColumn
                  field={header}
                  key={header}
                  minWidth={200}
                  headerName={generateHeaderLabel(header)}
                  filter="text"
                  sortable
                  
                  headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                // floatingFilter
                // cellRendererFramework={(event) => {
                //   if(event.value === "manual") {
                //     return <span>Manual</span>
                //   } if(event.value === "csv") {
                //     return <span>CSV</span>
                //   } 
                //     return <span>{event.value}</span>

                // }}
                />
              );
            }
            return (
              <AgGridColumn
                field={header}
                key={header}
                headerName={generateHeaderLabel(header)}
                filter="text"
                sortable
                minWidth={190}
                
                headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                // floatingFilter
               />
            );

          })}

          {!import_asset_in_other_module && <AgGridColumn
            lockPosition
            rowSelection="single"
            suppressSizeToFit
            headerName="Actions"
            width={120}
            minWidth={120}
            maxWidth={120}
            pinned="right"
            lockPinned
            className="action_field"
            suppressColumnsToolPanel
            cellRendererFramework={(event) => (
              <ActionsColumn
                data={event.data}
                handleClickOpen={handleClickOpen}
                handelEdit={handelEdit}
                handelClone={handelClone}
                handleArchive={handleArchive}
                gotoServiceRequestPage={redirectToServiceRequestsPageWithSerialNo}
                viewAssetPermission={props.viewAssetPermission}
                createAssetPermission={props.createAssetPermission}
                updateAssetPermission={props.updateAssetPermission}
                deleteAssetPermission={props.deleteAssetPermission}
                addServiceTicketPermission = {addServiceTicketPermission}
              />
            )}
          />}
        </AgGridReact>
      </div>}
      {modalData && (
        <GeneralModal
          open={modalData}
          title={"Archive Asset"}
          text={
            <h4>
              Are you sure to Archive this Asset named as{" "}
              <b>{modalData.name}</b>?
            </h4>
          }
          detailMessage={"Your asset will be moved to archive asset if you proceed"}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          actionButtonName="Yes, Proceed!"
        />
      )}

      {openState.open && assetDetails && <AddServiceRequestFormDialog open={openState.open} getServiceRequest = {() => { setAssetDetails() }} asset={assetDetails} close={handleAddTicketFormClose}/>}

    </>
  );
}

export default ListView;

function ActionsColumn({
  data,
  handleClickOpen,
  handelEdit,
  handelClone,
  handleArchive,
  gotoServiceRequestPage,
  createAssetPermission,
  updateAssetPermission,
  deleteAssetPermission,
  viewAssetPermission,
  addServiceTicketPermission
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [idPopper, setIdPopper] = useState(null)

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setIdPopper(index)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIdPopper(null);
  };


  const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;
  if (data.id) {
    return (
      <div style={{height: '35px', display: 'flex', alignItems: 'center'}}>
        {(viewAssetPermission.length > 0 ||
          viewAssetPermission == "all" ||
          viewAssetPermission.map(
            (i) => i == data.location_obj.id
          ).length > 0) && (
            <Tooltip id="view" title="View" placement="top">
              <i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-eye" onClick={() => handleClickOpen(data.id)} />
            </Tooltip>
          )}
        
        {(updateAssetPermission.length > 0 ||
          updateAssetPermission == "all" ||
          updateAssetPermission.map(
            (i) => i == data.location_obj.id
          ).length > 0) && (
            <Tooltip id="edit" title="Edit" placement="top">
              <i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-pencil" onClick={() => handelEdit(data)} />
            </Tooltip>
          )}

        { (createAssetPermission.length > 0 ||
                  createAssetPermission == "all" || deleteAssetPermission.length > 0 || deleteAssetPermission.length > 0 ||
                  deleteAssetPermission == "all" || addServiceTicketPermission) &&(
            <>
              <i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-dots-vertical" 
                aria-describedby={idPopper} variant="contained" onClick={(e) => handleClick(e, data.id)} />
              <Popover
                id={idPopper}
                open={idPopper === data.id}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div className="cstm_popover_holder">
                  {(createAssetPermission.length > 0 ||
                  createAssetPermission == "all" ||
                  createAssetPermission.map(
                    (i) => i == data.location_obj.id
                  ).length > 0) && <div onClick={() => {
                    handleClose()
                    handelClone(data)
                  }} className="action_list">
                    <Icon
                      onClick={() => handelEdit(data)}
                      style={{
                        color: "#1D2939",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    >
                      content_copy
                    </Icon>

                    <span className="action_listText">Clone</span>
                  </div>}
                  {(deleteAssetPermission.length > 0 ||
                    deleteAssetPermission == "all" ||
                    deleteAssetPermission.map(
                      (i) => i == data.location_obj.id
                    ).length > 0) && <div onClick={() => {
                      handleClose()
                      handleArchive(data)
                    }} className="action_list">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.8333 3.33333H4.16667C3.24619 3.33333 2.5 4.07952 2.5 4.99999C2.5 5.92047 3.24619 6.66666 4.16667 6.66666H15.8333C16.7538 6.66666 17.5 5.92047 17.5 4.99999C17.5 4.07952 16.7538 3.33333 15.8333 3.33333Z" stroke="#1D2939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.16675 6.66667V15C4.16675 15.442 4.34234 15.866 4.6549 16.1785C4.96746 16.4911 5.39139 16.6667 5.83341 16.6667H14.1667C14.6088 16.6667 15.0327 16.4911 15.3453 16.1785C15.6578 15.866 15.8334 15.442 15.8334 15V6.66667" stroke="#1D2939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8.33325 10H11.6666" stroke="#1D2939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <defs>
                        <clipPath id="clip0_193_8258">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="action_listText">Archive</span>
                  </div>}
                  {addServiceTicketPermission && <div onClick={() => {
                    handleClose()
                    gotoServiceRequestPage(data)
                  }} className="action_list">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.5 4.16667V5.83334" stroke="#1D2939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12.5 9.16667V10.8333" stroke="#1D2939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12.5 14.1667V15.8333" stroke="#1D2939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.16667 4.16667H15.8333C16.2754 4.16667 16.6993 4.34227 17.0118 4.65483C17.3244 4.96739 17.5 5.39131 17.5 5.83334V8.33334C17.058 8.33334 16.634 8.50893 16.3215 8.82149C16.0089 9.13405 15.8333 9.55798 15.8333 10C15.8333 10.442 16.0089 10.866 16.3215 11.1785C16.634 11.4911 17.058 11.6667 17.5 11.6667V14.1667C17.5 14.6087 17.3244 15.0326 17.0118 15.3452C16.6993 15.6577 16.2754 15.8333 15.8333 15.8333H4.16667C3.72464 15.8333 3.30072 15.6577 2.98816 15.3452C2.67559 15.0326 2.5 14.6087 2.5 14.1667V11.6667C2.94203 11.6667 3.36595 11.4911 3.67851 11.1785C3.99107 10.866 4.16667 10.442 4.16667 10C4.16667 9.55798 3.99107 9.13405 3.67851 8.82149C3.36595 8.50893 2.94203 8.33334 2.5 8.33334V5.83334C2.5 5.39131 2.67559 4.96739 2.98816 4.65483C3.30072 4.34227 3.72464 4.16667 4.16667 4.16667" stroke="#1D2939" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <defs>
                        <clipPath id="clip0_193_8264">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="action_listText">Service Ticket</span>
                  </div>}
                </div>
              </Popover>
            </>
          )}
      </div>
    );
  }
  return <></>;

}


const CustomDropdownEditor = ({ value, column, onCellValueChanged, values, api, params, context, node }) => {
  const [filterElOne, setFilterElOne] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [userList, setUserList] = useState(['Unassigned',...values]);
  const dropdownClickOne = (event) => {
    setFilterElOne(filterElOne ? null : event.currentTarget)
  }
  const handleFilterOneClose = () => {
    setFilterElOne(false)
  }
  const mainTheme = useSelector(selectMainTheme);
  const classes = useStyles();
  const openFilterOne = true;
  const inputRef = useRef(null);
  const idOne = 'simple-popperOne'
  const dispatch = useDispatch();
  const toast = useToast(dispatch);
  const onCellValueChange = (status_id) => {
    const payload = {
      asset_id: node.data.id,
      entity_name: "status_id",
      entity_value: Number(status_id.trim())
    };


    status_id && axios
      .post(`/assets/inline-edit`, payload)
      .then((results) => {
        toast.success("Asset updated successfully.");
      })
      .catch((err) => {
        console.log(err);
      });

    const gridApi = api
    const transaction = {
      update: [{ rowIndex: 4, data: { 'status': "hgfhgf" } }],
    };

    // Apply the transaction to the grid
    // gridApi.updateRowData(transaction);// Reference to the ag-Grid API, obtained through the onGridReady event or elsewhere;

    // Specify the rows to refresh
    const params = {
      force: true, // Set to true to refresh all rows
    };
    gridApi.redrawRows();
  }

  const handleUserSelect = (user_id) => {
    const payload = {
      asset_id: node.data.id,
      entity_name: "user_id",
      entity_value: !user_id? null : Number(user_id.trim())
    };


    axios
      .post(`/assets/inline-edit`, payload)
      .then((results) => {
        toast.success("Asset updated successfully.");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleFilter = (value) => {
    setInputValue(value)
    let list = values.filter(item => item.toLowerCase().includes(value.toLowerCase()));
    setUserList(list)
  }

  useEffect(() => {
    // Focus on the input element when the component mounts
    inputRef?.current?.focus();
  }, []);

  if (column.colDef.field === 'user_name') {
    return (
      <>
        <div className='userlisting_dropdown'>
          <div className="search_holder">
            <input className="search_listingFiled w-full" value={inputValue} onChange={(e) => { handleFilter(e.target.value) }} ref={inputRef} placeholder="Search.." ></input>
            {/* <ThemeProvider theme={mainTheme}>
					<Paper
						// component={motion.div}
						initial={{ y: -20, opacity: 0 }}
						animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
						className={`flex items-center search_listingFiled ${classes.buttonSquare}`}
						style={{minWidth:"100%"}}
					>
						<TextField
							placeholder="Search.."
							className="flex flex-1 p-6 search_text"
							fullWidth
              autoFocus
              ref={inputRef}
              onClick={api.startEditingCell({ rowIndex:2, colKey: 'user_name', charPress: null })}
							InputProps={{
								disableUnderline: true,
								
							}}
							// value={query_data ?? '' }
							// onChange={(e)=>{
							// 	dispatch(setQuickFilter(e.target.value))
							// }}
						/>
						</Paper>
					</ThemeProvider> */}
          </div>
          <div className="list_outer_holder">
            <List>
              {userList.map(item => (
                <ListItem className="userlist_items" disablePadding>
                  <ListItemButton>
                    <ListItemText className="userlist_itemsText" onClick={() => { handleUserSelect((item.split(','))[1]) }} primary={(item.split(','))[0]} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </>
    )
  }
  return (
    <div className='listing_dropdown'>
      <div>
        <div>
          {values.map((item => (<><div className="assigned_list">
            <span className="dot_circle" style={{ backgroundColor: `${(item.split(','))[1]}` }} />
            <p onClick={() => { onCellValueChange((item.split(','))[2]); }} className="assigned_text">
              {(item.split(','))[0]}
            </p>
          </div><br /></>)))}
        </div>
      </div>
    </div>
  )

};

// const CustomFilter = (props) => {
//   const [columnHeaderAndValueMap, setColumnHeaderAndValueMap] = useState({
//     "serial_no": "Serial Number", "asset_tag": "Asset Tag", "name": "Asset Name", "user_name": "User", "status": "Status", "contract_number": "Contract Number"
//   })
//   const [visibility, setVisibility] = useState(false)
//   const dispatch = useDispatch();
  
//   const handleChange = (e) => {
//     props.api.setFilterModel({
//       [props.column.colDef.field]: {
//         filterType: "text",
//         type: "contains",
//         filter: e.target.value
//       }
//     });
//   }
//   return (
//     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//       {!visibility && <p style={{ fontSize: '12px', fontWeight: '600', fontFamily: '"Inter", sans-serif', color: '#1D2939' }}>
//         <span style={{ color: '#98A2B3', position: 'absolute', top: '30%', fontWeight: '200', fontSize: '1.8rem', left: '1%' }}>
//           <Icon onClick={() => { props.api.setSortModel([{ colId: props.column.colDef.field, sort: 'desc' }]) }} style={{ fontSize: '1.8rem' }}>
//             arrow_downward
//           </Icon>
//           <Icon onClick={() => { props.api.setSortModel([{ colId: props.column.colDef.field, sort: 'asc' }]) }} style={{ fontSize: '1.8rem' }}>
//             arrow_upward
//           </Icon>
//         </span>
//         <span style={{ position: 'absolute', top: '31%', left: '21%' }}> {columnHeaderAndValueMap[props.column.colDef.field]} </span>
//       </p>}
//       {visibility && <input
//         type="text"
//         style={{ padding: '6px', backgroundColor: 'transparent' }}
//         // value={filterValue}
//         onChange={handleChange}
//         placeholder="Search..."
//       />}
//       {!visibility && <p style={{ position: 'absolute', right: '7%', top: '30%', color: '#98A2B3', fontWeight: '200' }} onClick={() => { setVisibility(true) }}>
//         {/* open */}
//         <Icon style={{ fontSize: '1.8rem' }}>
//           search
//         </Icon>
//       </p>}
//       {visibility && <p style={{ position: 'absolute', right: '3%', top: '30%', color: '#98A2B3', fontWeight: '200' }} onClick={() => { setVisibility(false) }}>
//         <Icon onClick={() => { props.api.setFilterModel([{}]) }} style={{ fontSize: '1.8rem' }}>
//           close
//         </Icon>
//       </p>}
//     </div>
//   );
// };