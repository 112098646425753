export function customSort(date1, date2) {
  const parsedDate1 = new Date(date1);
  const parsedDate2 = new Date(date2);

  if (parsedDate1 < parsedDate2) {
      return -1;
  } else if (parsedDate1 > parsedDate2) {
      return 1;
  } else {
      return 0;
  }
}

export function customAlphanumericSort(str1, str2) {
    // Extract the letter part (e.g., "I", "A") and number part (e.g., "992", "100")
    const [letterA, numberA] = str1?.split('-') ?? '';
    const [letterB, numberB] = str2?.split('-') ?? '';
 
    // Compare the letter parts first
    if (letterA < letterB) return -1;
    if (letterA > letterB) return 1;
 
    // If the letter parts are the same, compare the numeric parts
    return parseInt(numberA) - parseInt(numberB);
 }