import React, {useState, useRef, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from "@fuse/utils/axios";
import useToast from "@fuse/hooks/useToast";
import excel from "../images/ms_excel.png";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Tabs, Tab, Breadcrumbs, Button, Select, MenuItem, FormControlLabel, Checkbox, Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import StatusComponent from './StatusComponent'
import { exportToCSV } from "@fuse/utils/generateCsv";
import { red } from "@material-ui/core/colors";
import { setFormData } from '../../reports/store/reportViewDialogReducer';
import { BorderBottom } from "@material-ui/icons";


export default function PreviewData({tabValue, successCsvData, errorCsvData, handleNext, setViewDetails, setOverWrite}) {
  const formData_ = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.formData
  );
  const dispatch = useDispatch();
  const toast = useToast(dispatch);
  const gridRef = useRef();

  const [recordTabValue, setRecordTabValue] = useState(0);
  const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);

  function a11yProps(index) {
		return {
		  id: `simple-tab-${index}`,
		  'aria-controls': `simple-tabpanel-${index}`,
		};
	}

  const handleChangeRecordTabValue = (event, newValue) => {
		setRecordTabValue(newValue);
	};

  const onGridReady = params => {
		// Following line to make the currently visible columns fit the screen  
		params.api.sizeColumnsToFit();
	 
		// Following line dymanic set height to row on content
		params.api.resetRowHeights();
	};

  function downloadSuccessLog(data){
    let header = Object.keys(data[0])
		let csvArr = [header]
    data.map(item=>{
      csvArr.push((Object.values(item)))
    })
		exportToCSV(csvArr, `success_log_csv`) 
  }

  function downloadErrorLog(data){  
    let header = Object.keys(data[0])
		let csvArr = [header]
    data.map(item=>{
      csvArr.push((Object.values(item)))
    })
		exportToCSV(csvArr, `error_log_csv`) 
  }

	const frameworkComponents = {
    customFilter: CustomFilter,
  };

	useEffect(()=>{
		setRecordTabValue(tabValue)
	},[tabValue])

	return (
		<>

        <div>
					<div className="flex flex-wrap justify-between mb-36">
						<h4 className="main_page_heading mt-12">Preview</h4>
					</div>

					<div className="badge_info_holder mb-36">
						<img src={excel} alt="icon" />
						<span className="text">{formData_.name}</span>
						<Button className="grey_btn" onClick={() => {
							dispatch(setFormData(null))
							setOverWrite(false)
							setViewDetails(false)
							handleNext('import')}}>
							<i className="ti ti-reload" />
						</Button>
					</div>
					
					<div>
						<Box className='cstm_tabs_holder'>
							<Tabs value={recordTabValue} onChange={handleChangeRecordTabValue} aria-label="basic tabs example" className='tabs_main_holder'>
								<Tab className='each_tab_holder' label="Successful Records" {...a11yProps(0)} />
								<Tab className='each_tab_holder' label="Rejected Records" {...a11yProps(1)} />
							</Tabs>
						</Box>
					</div>

					{ recordTabValue === 0 &&
						<>
							<div style={{ marginTop: '-65px' }} className="text-right mb-40">
								{successCsvData.length > 0 &&
									<Button className="primary_maroon_outlined_btn" onClick={()=>{downloadSuccessLog(successCsvData)}}>
										<i className="ti ti-download" />
										<span>Download</span>
									</Button>
								}
							</div>
							<div 
								className="ag-theme-alpine" 
								style={{
								width: "100%",
								height: windowHeight+105,
								fontSize:'12px'
								}}
							>
								<AgGridReact
									ref={gridRef}
									rowData={successCsvData}
									animateRows
									onGridReady = { onGridReady }
									paginationPageSize={10}
									pagination
									frameworkComponents={frameworkComponents}
								>
									{successCsvData.length &&(Object.keys(successCsvData[0])).map(header => {
                    if( header === "Status Name"){
											return(
												<AgGridColumn 
													key = {header} 
													field = {header}
													headerName = {header}  
													width = { 200 }
                          minWidth = { 200 }
													// maxWidth = { header.maxWidth }
													// sortable = {false}
													// flex = {1}
													sortable = {true}
													filter = "text"
													cellRendererFramework={(event) => (<StatusComponent status_name={event.value}/>)}
													wrapText
													autoHeight
													headerComponentFramework = {CustomFilter}								
												/>
											)
										}
                    return(
                      <AgGridColumn 
                        key = {header} 
                        field = {header}
                        headerName = {header}  
                        width = { 200 }
                        minWidth = { 200 }
                        // maxWidth = { header }
                        // flex = {1}
                        filter = "text"
												sortable = {true}
                        wrapText
                        autoHeight
                        headerComponentFramework = {CustomFilter}									
                      />
                    )
									})}
								</AgGridReact>
							</div>
						</>
					}
					{ recordTabValue === 1 &&
						<>
							<div style={{ marginTop: '-65px' }} className="text-right mb-40">
							{errorCsvData.length > 0 &&
								<Button className="primary_maroon_outlined_btn" onClick={()=>{downloadErrorLog(errorCsvData)}}>
									<i className="ti ti-download" />
									<span>Download</span>
								</Button>
							}
							</div>
							<div 
								className="ag-theme-alpine" 
								style={{
								width: "100%",
								height: windowHeight+105,
								fontSize:'12px'
								}}
							>
								<AgGridReact
									ref={gridRef}
									rowData={errorCsvData}
									animateRows
									onGridReady = { onGridReady }
									paginationPageSize={10}
									pagination
									frameworkComponents={frameworkComponents}
								>
									{errorCsvData.length && (Object.keys(errorCsvData[0])).map(header => {
                    if( header === "Status Name"){
											return(
												<AgGridColumn 
													key = {header} 
													field = {header}
													headerName = {header}  
													width = { 200 }
                          minWidth = { 200 }
													sortable = {true}
													// maxWidth = { header.maxWidth }
													// sortable = {false}
													// flex = {1}
													filter = "text"
													cellRendererFramework={(event) => (<StatusComponent status_name={event.value}/>)}
													wrapText
													autoHeight
													headerComponentFramework = {CustomFilter}								
												/>
											)
										}
										if( header === "Reason for Rejection"){
											return(
												<AgGridColumn 
													key = {header} 
													field = {header}
													headerName = {header}  
													width = { 300 }
                          							minWidth = { 300 }
													className='dnjdnjdnj'
													pinned= 'left' 
													lockPinned={true}
													sortable = {true}
													// maxWidth = { header.maxWidth }
													// sortable = {false}
													// flex = {1}
													filter = "text"
													wrapText
													autoHeight
													headerComponentFramework = {CustomFilter}
													cellStyle= { params => {
														return {color: '#B42318', backgroundColor: '#FFFBFA'};
													}}
													cellRendererFramework={(event) => 
														<span style={{ color: '#B42318', wordBreak: 'break-word' }}>															
															{event.value?.split(",")[0]}															
														</span>
													}								
												/>
											)
										}
                    return(
                      <AgGridColumn 
                        key = {header} 
                        field = {header}
                        headerName = {header}  
                        width = { 200 }
                        minWidth = { 200 }
												sortable = {true}
												flex = {1}
												filter = "text"
										
												headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}								
                      />
                    )
									})}
								</AgGridReact>
							</div>
						</>
					}
				</div>

		</>
	)
}