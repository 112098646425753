import React ,{ useState,useEffect } from 'react';
import { useParams,useLocation } from 'react-router-dom';
import axios from "@fuse/utils/axios";

import ResetPassword from './ResetPassword';
import LinkExpired from './LinkExpired';


function ResetMain(){

	let { id } = useParams();

	const query = new URLSearchParams(useLocation().search);
	const userMail = query.get('email');


	let [expired,setExpired] = useState(false);

	useEffect(()=>{
		axios.get(`/end-user/reset/${id}`)
		.then(result=>{
			console.log("message is: ",result.data.message);
			if(result.data.message === 'link expired'){
				// setExpired(true);
			}
		})
	},[]);


	if(expired){
		return <LinkExpired />
	}else{
		return < ResetPassword id={id} userMail={userMail} />
	}

}

export default ResetMain;