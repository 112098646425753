import MomentUtils from "@date-io/moment";


function hslToHex(hsl) {
  let {h, s, l} = hsl
  h /= 360;
  s /= 100;
  l /= 100;
  let r; let g; let b;
  if (s === 0) {
  r = g = b = l; // achromatic
  } else {
  const hue2rgb = (p, q, t) => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };
  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;
  r = hue2rgb(p, q, h + 1 / 3);
  g = hue2rgb(p, q, h);
  b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = x => {
  const hex = Math.round(x * 255).toString(16);
  return hex.length === 1 ? `0${  hex}` : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
 

}

const customColor = (seriesData, diff =16) => {
  const colors = []
  for (let i=0; i< seriesData.length; i++){
  const hsl = {h:i*diff, s: 100, l:80}
  const result = seriesData[i] === 0 ? 'transparent' : hslToHex(hsl)
  colors.push(result)
  }
  return colors
}

export default customColor;

export const ucFirst = (str) => {
  if (typeof (str) !== 'string') {
    return ''
  }

  if (!str?.trim()) {
    return ''
  }

  return str.split(' ').map((item) => `${item.charAt(0).toUpperCase()}${item.slice(1).toLowerCase()}`).join(' ')
}

export const ucFirstLetter = (str) => {
  if (typeof (str) !== 'string') {
    return ''
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const stripHtml = (html) =>{
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export const acceptFileType = ['.doc', '.docx', '.pdf', '.txt', '.xls', '.xlsx', '.csv', '.ppt', '.pptx', '.jpg', '.jpeg', '.png', ".plain", ".msword", ".vnd.openxmlformats-officedocument.wordprocessingml.document",
".vnd.oasis.opendocument.text"]

export const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())


export const formatDateForContract =(date) =>{
    const moment = (new MomentUtils()).date(date).format('YYYY-MM-DD');
	return moment
}

export const formatDate =(date) =>{
  const moment = (new MomentUtils()).date(date).format('MM-DD-YYYY');
return moment
}

export const getLastDayOfMonth = (year, month) =>{
  let date = new Date(year, month + 1, 0);

  return date.getDate();
}

export const getMonth = (month) =>{
  let monthFormatted = (month + 1).toString().padStart(2, '0');

  return monthFormatted;
}

export const checkForIdInFilter = (filterData) => {
  let updatedData = {};  // Create a new object to store the updated data

  for (let key in filterData) {
    let filterValue = filterData[key].filter;
    
    // Check if the filter contains "id:"
    if (filterValue?.includes("id:")) {
      const [leftPart, ...rightParts] = filterValue.split('_');
      // Store the part before the first underscore in a separate variable
      const removedLeft = leftPart;
      // The rest of the string after the first underscore
      const remainingString = rightParts.join('_')
      
      // Store the updated object in the new variable
      updatedData[key] = {
        ...filterData[key],  // Copy the rest of the data
        filter: remainingString,  // Updated filter value
        id: removedLeft.replace("id:", "")
      };
    } else {
      // If "id:78_" is not present, just copy the original filter data
      updatedData[key] = filterData[key];
    }
  }

return updatedData;
}
