import EndUsersAccountsTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
// import { useDispatch } from 'react-redux';
import CsvUpload from './CsvUpload';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import useToast from '@fuse/hooks/useToast';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import { dateReformatTo_mmm_dd_yyyy,getFormattedDateyyyy_mm_dd } from '@fuse/utils/dateOperations'
// import SaveReportDialog from './saveReportDialog';
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'
import AddContractFormDialog from './addContractDialog';
import { formatString } from '@fuse/utils/stringOperations';
import { usePermission } from '@fuse/hooks';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { Tab, Tabs } from '@material-ui/core';
import SaveReportDialog from '../../reports/components/saveReportDialog';
import  QuickAddContract from 'app/fuse-layouts/shared-components/QuickAddContract';
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomFilterData } from '../store/filterParamsReducer';
import { getContractStatus } from 'helper/status';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentWrapper : {
		marginBottom:'15px',
		borderRadius:'10px'
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
});

const views = ['List View','Grid View'];
function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [endUsersAccounts, setEndUsersAccounts] = useState([]);
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [searchFilters, setSearchFilters] = useState([]);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [currentSearchParameters, setCurrentSearchParameters] = useState({});
	const [manualContract,setManualContract] = useState([])
	const { open: openAddContractForm } = useSelector((state) => state.contractsApp.formViewDialog)
    // const dialogState = useSelector(({reportApp}) => reportApp.reportViewDialogReducer);
	const { hasPermission } = usePermission()
	const viewContractPermission = hasPermission('view-contract')
	const createContractPermission = hasPermission('add-contract')
	const updateContractPermission = hasPermission('update-contract')
	const deleteContractPermission = hasPermission('delete-contract')
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();

	function clearFilters() {
		const {query, field} = currentSearchParameters;
		handleSearch(query, field, {});
		setSelectedFilters({});
	}

	function gotoDetailsPage(id){
		router.push(`contracts/details/${id}`)
	}

	const [platformModule, setPlatformModule] = useState({});
	const [platformModuleAttributes, setPlatformModuleAttributes] = useState([]);
	const contract_header_id = localStorage.getItem('contract_header_id') ?? null
	const custom_filter_data = useSelector(({contractsApp})=>contractsApp.filterParamsReducer.custom_filter_data)
	const [ redirectedWith, setRedirectedWith ] = useState(null);

	async function getContracts(){
		try {
			const res = await axios.get(`/end-user-contracts/list-all?contract_header_id=${contract_header_id ? contract_header_id : ''}`)

			if(custom_filter_data){
				setRedirectedWith(custom_filter_data)
			}
			const { data } = res.data;
			setEndUsersAccounts(formatContracts(data));
			localStorage.removeItem('contract_header_id')
		} catch (err) {
			console.log(err);
		}
	}
	
	function handleSearchReset(){
		getContracts()
	}

	function getSearchFilters(){
		setSearchFilters([
			{field: "entity_external_platform_id", label: "Contract ID"},
			{field: "contract_status", label: "Contract Status"},
			{field: "location", label: "Contact Location"},
		])
	}

	function debounce(func, timeout = 300){
		let timer;
		return (...args) => {
		  clearTimeout(timer);
		  timer = setTimeout(() => { func.apply(this, args); }, timeout);
		};
	}

	function getSearchSuggetions(query, field){
		axios.get('/end-user-contracts/search-suggestions',{
			params: {
				query,
				field
			}
		})
		.then(res => {
			const { data } = res.data;
			setSearchSuggestions(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handelSearchSuggestions = debounce((query, field) => {
		getSearchSuggetions(query, field)
	}, 500)

	function handleSearch(query, field, filters) {
		axios.get('/end-user-contracts/search',{
			params: {
				query,
				field,
				filters
			}
		})
		.then(res => {
			const { data } = res.data;			
			setEndUsersAccounts(formatContracts(data))
		})
		.catch(err => {
			console.log(err);
		})
	}

	function formatContracts(data){
		// console.log("formatContracts", data)
		return data.map((contract) => {
			const dynamicAttributes = Object.entries(contract.dynamic_attributes).reduce((acc, [key, item]) => {
				acc[key] = item.attribute_value
				return acc;
			},{});
			let related_asset = [];
			let idx = 0;
			contract.assets.map((asset) => { 
				related_asset[idx] = asset.serial_number
				idx++
			})
			return {
				id: contract.id,
				contract_number: contract.entity_external_platform_id || '',
				total_contract_value: contract.total_contract_value !== null ? contract.total_contract_value : '',
				total_contract_value_: contract.total_contract_value !== null ? formatPriceFieldOnBlur(contract.total_contract_value) : '',
				contract_name: contract.contract_name,
				contract: contract.contract_name,
				company_name: contract.user_company?.company_name || '',
				contract_description: contract.contract_description,
				start_date: contract.contract_start_date !== null ? dateReformatTo_mmm_dd_yyyy(contract.contract_start_date) : "",
        		end_date: contract.contract_end_date !== null ? dateReformatTo_mmm_dd_yyyy(contract.contract_end_date) : "",
				type: contract?.contract_headers_type?.display_name || '',
				status: contract?.contract_header_status?.contract_status_name || '',
				contract_tag: contract?.contract_header_status?.contract_status_name || '',
				contract_status: getContractStatus(contract?.contract_end_date)?.status || '',
				number_of_assets: contract?.assets.length,
				related_asset: related_asset,
				SLA: contract?.service_level_agreement?.sla_name || '',
				source: formatString(contract?.source) || "",
				service_provider: contract?.contract_service_provider?.service_provider_name || '',
				master: contract?.master === true ? "Yes" : "No",
				parent_contract: contract?.parent_contract?.contract_name || '',
				['new/renew']: contract?.renewal || '',
				location: contract.shipping?`${contract.shipping.location_name}-${contract.shipping.address1}, ${contract.shipping.city}, ${contract.shipping.state}, ${contract.shipping.country}`: "",
				location_id: contract.shipping?.entity_external_platform_id || '',	
				payment_amount: contract?.contract_payment?.payment_amount || '',
				currency: contract?.contract_payment?.country || '',
				payment_term: contract?.contract_payment?.payment_term?.display_name || '',
				payment_order_number: contract?.contract_payment?.po_number || '',
				payment_order_date: contract?.contract_payment?.po_date ? dateReformatTo_mmm_dd_yyyy(contract?.contract_payment?.po_date) : null || '',
				// reminder: formatString(contract?.reminder) || '',
				text_contact_number: contract?.support_contact_number || '',
				contract_status_object: contract?.contract_header_status,
				parent_contract_object: contract?.parent_contract,
				service_level_agreement_object: contract?.service_level_agreement,
				service_provider_object: contract?.contract_service_provider,
				contract_type_object: contract?.contract_headers_type,
				location_object: contract?.shipping,
				contract_payment_object: contract?.contract_payment,
				master_object: { value: contract?.master, label: contract?.master ? 'Yes' : 'No' },
				amount: contract.subscription_billing !== null ? contract.subscription_billing?.amount || '' : '',
				billing_cycle: contract.subscription_billing !== null ? `${contract?.subscription_billing?.billing_cycle} ${contract?.subscription_billing?.month || ''}` || '' : '',
				billing_cycle_: contract.subscription_billing !== null ? contract.subscription_billing?.billing_cycle : '',
				billing_cycle_date: contract.subscription_billing !== null ? contract?.subscription_billing?.billing_cycle_date?dateReformatTo_mmm_dd_yyyy(contract.subscription_billing?.billing_cycle_date):'No Billing Cycle Date' : 'No Billing Cycle Date',
				
				po_date_exact: contract.subscription_payment !== null ? contract?.subscription_payment?.po_date || '' : '',
				po_date: contract.subscription_payment !== null ? contract.subscription_payment.po_date?dateReformatTo_mmm_dd_yyyy(contract.subscription_payment.po_date): 'No PO Date' : 'No PO Date',
				po_number: contract.subscription_payment !== null ? contract.subscription_payment.po_number || 'No PO Number' : 'No PO Number',
				every: contract.subscription_billing !== null ? contract?.subscription_billing?.billing_cycle  || '1' : '1',
				currency: contract.subscription_billing !== null ? contract?.subscription_billing?.currency || '' : '',
				month: contract.subscription_billing !== null ? contract?.subscription_billing?.month || '' : '',
				payment_term_id: contract.subscription_billing !== null ? contract?.subscription_billing?.payment_term_id || '' : '',
				payment_term: contract.subscription_billing !== null ? contract?.subscription_billing?.payment_term || null : null,
				subscription_billing_id: contract.subscription_billing !== null ? contract?.subscription_billing?.id || '' : '',
				subscription_payment_id: contract.subscription_payment !== null ? contract?.subscription_payment?.id || '' : '',
				value: contract.subscription_payment !== null ? contract.subscription_payment.value : 'purchaseOrder',
				card_number: contract.subscription_payment !== null ? contract.subscription_payment.card_number || 'No Card Number' : 'No Card Number',
				card_holder_name:  contract.subscription_payment !== null ? contract?.subscription_payment.card_holder_name || 'No Card Holder Name' : 'No Card Holder Name',
				billing_cycle_date_exact: contract.subscription_payment !== null ? contract?.subscription_billing?.billing_cycle_date : '',
				manually_added: contract?.manually_added,

				subscription_billing: contract.subscription_billing !== null ? contract.subscription_billing : null,
				subscription_payment: contract.subscription_payment !== null ? contract.subscription_payment : null,
				original_info:{
					contract_name: contract.contract_name,
					contract_description: contract.contract_description,
					contract_start_date: contract.contract_start_date || "",
					contract_end_date: contract.contract_end_date || '',
					contract_status: contract?.contract_header_status?.contract_status_name || '',
					payment_amount: contract?.contract_payment?.payment_amount || '',
					payment_term: contract?.contract_payment?.payment_term?.display_name || '',
					service_provider_object: contract?.contract_service_provider,					
					parent_contract: contract?.parent_contract?.contract_name || '-',
					master: contract?.master,
					contract_type: contract?.contract_headers_type?.display_name || '',
					contract_status_object: contract?.contract_header_status,
					renewal: contract?.renewal || '',
					// text_contact_number: contract?.support_contact_number || '',
					payment_order_date: contract?.contract_payment?.po_date || '',
					// reminder: contract?.reminder || '',
					service_level_agreement_object: contract?.service_level_agreement,
					payment_term_obj: contract?.contract_payment?.payment_term,
					location_id: contract.shipping?.entity_external_platform_id || '',
					payment_order_number: contract?.contract_payment?.po_number || '',
					location_object: contract?.shipping,
					parent_contract_object: contract?.parent_contract,
					// remind_me_before: contract?.remind_me_before,
					// notification_type_name: contract?.notification_type_name
				},
				...dynamicAttributes,
				source_: contract.source == 'manual'? 'Manual' :  contract.source == 'CSV Upload'? 'CSV': ''
			}
		})
	}

	async function getDynamicAttributes(){
		try {
			const res = await axios.get("/dynamic-attributes/fetch-all");
			const { data } = res.data;
			const platformModuleItem = data.find(({ name }) => name == "contract_header");
			setPlatformModule(platformModuleItem);
			try {
				const res_1 = await axios.get(`/dynamic-attributes/fetch/${platformModuleItem.id}`);
				const { data: data_1 } = res_1.data;
				setPlatformModuleAttributes(data_1);
				return data_1;
			} catch (err) {
				toast.error("Unable to fetch dynamic attributes");
				console.log(err);
			}
		} catch (err_1) {
			toast.error("Unable to fetch dynamic attributes");
			setPlatformModule({});
			console.log(err_1);
		}
	}

	function refreshList(){
		getDynamicAttributes().then(() => {
			return getContracts();
		})
	}


	function handleAddContractFormClose (data) {
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
	}

	const handleTabChange = (event, value) => {
		if(value == 1){
			getAllContractActivities()
		}
		setSelectedTab(value);
	};

	useEffect(()=>{
		setLoading(true)
		getSearchFilters();
		getDynamicAttributes().then(() => {
			return getContracts();
		}).finally(() => {
			setLoading(false)
		});
	},[]);

	useEffect(()=>{
		if(broadcastChannel){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	}else{
		return (<>
				<CsvUpload refresh={refreshList}/>
				 <SaveReportDialog tableName={"contract"}/>
				<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentWrapper:classes.contentWrapper
				}}
				// header={
				// 	<DynamicFieldsHeader 
				// 		handleSearch={handleSearch} 
				// 		searchSuggestions={searchSuggestions} 
				// 		clearSearch={clearFilters}
				// 		searchFilters={searchFilters}
				// 		handleSearchSuggestions={handelSearchSuggestions}
				// 		handleSearchReset={handleSearchReset}
				// 		onSearchChange={(searchValue, fieldValue) => {
				// 			setCurrentSearchParameters({query: searchValue, field: fieldValue})
				// 		}}
				// 	/>
				// }
				// contentToolbar={
				// 	<>
				// 		<Tabs
				// 			value={selectedTab}
				// 			onChange={handleTabChange}
				// 			indicatorColor="primary"
				// 			textColor="primary"
				// 			variant="scrollable"
				// 			scrollButtons="off"
				// 			className="w-full h-64"
				// 		>
				// 			<Tab key="end_user_tab_1" className="h-64" label="All Contracts" />
				// 			<Tab key="end_user_tab_2" className="h-64" label="Activities" />
							
				// 		</Tabs>
				// 	</>
				// }
				content={
					<>
					<div className={selectedTab !== 0 ? 'hidden' : 'p-10'}>
						<EndUsersAccountsTable 
							endUsersAccounts={endUsersAccounts}
							selectedView={selectedView}
							gotoDetailsPage={gotoDetailsPage}
							refresh={refreshList}
							customFilterData={redirectedWith}
							viewContractPermission = {viewContractPermission}
							createContractPermission = {createContractPermission}
							updateContractPermission = {updateContractPermission}
							deleteContractPermission = {deleteContractPermission}
						/>
					</div>
					{/* <AddContractFormDialog fetchAllManuallyAddedContract={getContracts}/> */}
					{
						openAddContractForm && <QuickAddContract open={ openAddContractForm } close={handleAddContractFormClose} />
					}
					{/* <div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
						<EndUsersAccountsTable 
							endUsersAccounts={manualContract}
							selectedView={selectedView}
							gotoDetailsPage={gotoDetailsPageMaluallyAddedContract}
						/>
					</div> */}
					</>
				}
				innerScroll
			/>
		</>)
	}
}

export default withReducer('contractsApp', reducer)(CardedFullWidth2TabbedSample);

