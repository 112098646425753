import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import {
    Box,
    Button,
    Typography,
    Tabs,
    Tab,
    Skeleton,
    Tooltip
} from '@mui/material';
import axios from "@fuse/utils/axios";
import { useDispatch, useSelector } from 'react-redux';
import { setServiceTicketsCount } from 'app/main/assets/store/formViewDialogReducer';
import { generateProperLocationString } from '@fuse/utils/general';
import { useCurrentUser, useRouter } from '@fuse/hooks';
import ContractDetailsTab from './Tabs/Contract';
import Incidents from './Tabs/Incidents';
import ServiceRequests from './Tabs/ServiceRequests';
import Files from './Tabs/Files';
import Activities from './Tabs/Activities';
import Information from './Tabs/information';
import Application from './Tabs/Applications';
import AssetSpecs from './Tabs/AssetSpecs';
import QuickAddAsset from 'app/fuse-layouts/shared-components/QuickAddAsset';
import AddNotes from '@fuse/components/general/AddNotes';
import { usePermission } from '@fuse/hooks';

import {
    openFormViewerDialog,
    setFromData,
    closeFormViewerDialog
} from "../store/formViewDialogReducer";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

const AssetDetailsDialogue = ({ }) => {
    const dispatch = useDispatch();
    const router = useRouter()
    const { data: currentLoggedInUser } = useCurrentUser();
    const assetId = router.params.id
    const [assetDetails, setAssetDetails] = useState(null)
    const [loading, setLoading] = useState(true)
    const [ assetFiles, setAssetFiles ] = useState([])
    const [serviceReqCount, setServiceReqCount] = useState(null)
    const [ isShareLinkCopied, setIsShareLinkCopied ] = useState(false)
	const [openAddNote, setOpenAddNote] = useState(false)
    const { hasPermission } = usePermission()
    const { open: openAddAssetForm } = useSelector((state) => state.assetsApp.formViewDialog);
    const serviceTicketsCount = useSelector((state) => state.assetsApp.formViewDialog);
    let updateAssetPermission = hasPermission('update-assets')
    useEffect(()=>{
        setServiceReqCount(serviceTicketsCount.count)
    }, [serviceTicketsCount])

    useEffect(()=>{
        dispatch(setServiceTicketsCount(assetDetails?.asset_service_requests?.length ?? null))
    }, [assetDetails])

    const connection_status_color = {
        'unknown': '#808080',
        'connected': '#90EE90',
        'disconnected': '#ff0000'
    }

    function fetchFiles(){
        if(!assetDetails) return
        axios.get(`/assets/get-asset-files/${assetDetails.id}`).then((res)=>{
            const { files } = res.data.data;
            setAssetFiles(files)
        }).catch((err)=>{   
            console.log(err);
        })
    }

    /** Get details for an individual asset to set in edit form*/
    async function getAssetDetails() {
        let asset = null
        const { data } = (await axios.get(`assets/${assetId}`)).data

        asset = (() => {
        return {
            id: data.id,
            name: data.asset_name,
            serial_no: data.serial_number,
            asset_tag: data.asset_tag,
            country_code: data.country_code,
            status_value: data.asset_status,
            model_id: data.model_id,
            asset_model: {
            id: data.asset_model.id,
            model_name: data.asset_model.model_name,
            model_no: data.asset_model.model_no,
            },
            shipping_location_details: data.shipping,
            notes: data.notes,
            note_style: data.note_style,
            image_name: data.image_name,
            preview_image_link: data.image_link,
            assigned_user_details: data.assigned_user,
            deployed_date: data.deployed_date,
            recovery_date: data.recovery_date,
            contract_header: data.contract_header,
            order_number: data.order_number,
            purchase_cost: data.purchase_cost,
            purchase_date: data.purchase_date,
            install_date: data.install_date,
            warranty_end_date: data.warranty_end_date
        }
        })()

        return asset;
    }

    const handelEdit = async (data) => {
        const asset = await getAssetDetails()
        dispatch(setFromData(asset));
        dispatch(openFormViewerDialog());
    };

    function handleAddAssetFormClose(){
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
        getAssetInfo()
	}

    function getAssetInfo(){
        if (!assetId) return
        setLoading(true)
        axios.get(`/assets/${assetId}`).then((res) => {
            if(res.data.message === "Could not fetch asset") {
                router.push('/not-found')
            }
            setAssetDetails(res.data.data)
            setLoading(false)
            // fetchConnectionStatus(res.data.data)
        }).catch((err) => {
            if(err?.response?.data?.message === "Invalid request params input"){
                router.push('/not-found')
            }
            console.log('err', err);
            setLoading(true)
        })
    }

    useEffect(() => {
        getAssetInfo()
        window.scrollTo(0, 0)
    }, [assetId])

    useEffect(()=>{
        fetchFiles()
    },[assetDetails])
    
    function showDefaultAssetImage() {
        if(typeof assetDetails?.image_link === "string" && assetDetails?.image_link.includes("AWSAccessKeyId")){
            return true
        }
        return false
    }

    function shareAsset(asset_id) {
        const link = `${window.location.origin}/assets-list/${asset_id}`
        navigator.clipboard.writeText(link);
        if (!isShareLinkCopied) {
            setIsShareLinkCopied(true); // show tooltip
            setTimeout(() => {
              setIsShareLinkCopied(false); // remove/hide tooltip
            }, 2000);
          }
    }

    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleOpenAddNote = () => {
		setOpenAddNote(true)
	}

    const handleCloseAddNote = (updatedDescription="") => {
		setOpenAddNote(false)
        if(typeof updatedDescription === "object"){
            setAssetDetails({
                ...assetDetails,
                notes: updatedDescription.notes,
                note_style: updatedDescription.note_style,
            })
        }
	}

    function IsUserViewOnly(){
        if(currentLoggedInUser.all_roles.includes('super_user')){
          return false
        }else if(currentLoggedInUser.all_roles.includes('view_only')){
          return true
        }else{
          return false
        }
    }

    const theme = createTheme({
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: '#D1FADF',
                color: '#027A48',
                fontSize: '12px',
                fontWeight: 500
              },
            },
          },
        },
      });
      
    return (
        <div style={{background: '#fff', marginBottom:'15px', padding: '28px'}}>
            <Link id="back_btn_icon" className="back_btn_icon" onClick={()=>router.back()}>
                <i className='ti ti-arrow-left' />
            </Link>
            <>
                <div className='inner_details_section'>
                    <div className='flexbox_holder'>
                        <div className='left_holder img_included_holder'>
                            {
                                loading ? <> <Skeleton animation="wave" className="placeholder-animation" variant="rect" width={350} height={300} /></> : <>
                                    <div className='assetDetails_imgbox'>
                                        {
                                            showDefaultAssetImage()?
                                            <img src={assetDetails?.image_link}/> :
                                            <p className='no_img_info'>No Image Found</p>
                                        }
                                    </div>
                                </>
                            }
                                {/* <img src={Boolean(assetDetails?.image_link) ? assetDetails?.image_link : showDefaultAssestImage()} alt="" /> */}

                            <div className='content_holder px-40'>
                                {
                                    loading ? <>
                                        <Skeleton width={400} height={50} />
                                        <Skeleton width={200} height={20} animation='wave' />
                                        <Skeleton width={200} height={20} animation="wave" />
                                            <Skeleton width={200} height={20} animation='wave' />
                                        <Skeleton width={200} height={20} animation="wave" />
                                            <Skeleton width={200} height={20} style={{marginTop:"50px"}} animation="wave" />
                                    </> : <>
                                        <div>
                                            <div className='status_badge_holder'>
                                                <span style={{
                                                    backgroundColor: connection_status_color[assetDetails?.connection_status]
                                                }} className='dot_holder' />

                                                <span style={{ display: "inline-block" }}>Connection Status: </span>
                                                
                                                <span style={{ display: "inline-block", paddingLeft: '4px', textTransform: 'capitalize' }}>
                                                    {/* {assetDetails?.subscription_status} */}
                                                    {assetDetails.connection_status}
                                                </span>
                                            </div>

                                            <div className='status_badge_holder'>
                                                <span style={{
                                                    backgroundColor: assetDetails.asset_status.status_color.code
                                                }} className='dot_holder' />

                                                <span style={{ display: "inline-block" }}>Asset Status: </span>
                                                
                                                <span style={{ display: "inline-block", paddingLeft: '4px' }}>
                                                    {assetDetails.asset_status.status_name}
                                                </span>
                                            </div>

                                            <div className='name_loc_info_holder'>
                                                {/* <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom style={{ color: "#8A8E97", fontWeight: "600" }}>
                                                    ASSET NAME
                                                </Typography> */}
                                                <div style={{ width: 'calc(100% - 100px)'}} className='flex flex-wrap items-center'>
                                                    <Typography variant="h6" className='main_page_heading' component="div">
                                                        {assetDetails?.asset_name}
                                                    </Typography>
                                                    <span className='orange_badge_holder'>{assetDetails?.serial_number ?? 'N/A'}</span>
                                                    <p className='asset_address_text'>
                                                        <i className='ti ti-map-pin' />
                                                        {generateProperLocationString({
                                                            location_name: assetDetails?.shipping?.location_name ?? '',
                                                            address1: assetDetails?.shipping?.address1 ?? '',
                                                            address2: assetDetails?.shipping?.address2 ?? '',
                                                            city: assetDetails?.shipping?.city ?? '',
                                                            state: assetDetails?.shipping?.state ?? '',
                                                            country: assetDetails?.shipping?.country ?? '',
                                                            zip:  assetDetails?.shipping?.zip ?? ''
                                                        })}
                                                    </p>
                                                </div>
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        className='share_btn'
                                                        style={{ background: isShareLinkCopied ? '#90EE90' : '#F2F4F7'}}
                                                        onClick={()=>shareAsset(assetId)}
                                                    >
                                                        {isShareLinkCopied &&
                                                            <ThemeProvider theme={theme}>
                                                            <Tooltip title={'Copied'} placement="bottom">
                                                                <i className='ti ti-copy' />
                                                            </Tooltip>
                                                            </ThemeProvider>
                                                        }
                                                        {!isShareLinkCopied && <i className='ti ti-copy' />}
                                                        {/* <Icon className="text-white mr-5" fontSize="default"><span className="material-symbols-outlined">{isShareLinkCopied ? 'file_copy' : 'share'}</span></Icon> */}
                                                        {/* {isShareLinkCopied ? 'Copied' : 'Share'} */}
                                                    </Button>
                                                    {updateAssetPermission &&
                                                        <Button
                                                            variant="contained"
                                                            className='share_btn'
                                                            style={{ background: isShareLinkCopied ? '#90EE90' : '#F2F4F7'}}
                                                            onClick={handelEdit}
                                                        >
                                                            <i className='ti ti-pencil' />
                                                        </Button>
                                                    }
                                                </div>
                                            </div>

                                            <div className='infoStat_card_holder'>
                                                <div className='each_card'>
                                                    <p className='label_holder'>Manufacturer</p>
                                                    <p className='name_holder'>{assetDetails?.asset_model?.manufacturer?.manufacturer_name ?? 'N/A'}</p>
                                                </div>
                                                <div className='each_card'>
                                                    <p className='label_holder'>Category</p>
                                                    <p className='name_holder'>{assetDetails?.asset_model?.category?.category_name ?? 'N/A'}</p>
                                                </div>
                                                <div className='each_card'>
                                                    <p className='label_holder'>Model Name and Number</p>
                                                    <p className='name_holder'>{assetDetails?.asset_model?.model_name ?? 'N/A'} ({assetDetails?.asset_model?.model_no ?? 'N/A'})</p>
                                                </div>
                                                <div className='each_card'>
                                                    <p className='label_holder'>Asset Tag</p>
                                                    <p className='name_holder'>{(assetDetails?.asset_tag == null ||  assetDetails?.asset_tag == '') ? 'N/A' : assetDetails?.asset_tag }</p>
                                                </div>
                                                <div className='each_card'>
                                                    <p className='label_holder'>User</p>
                                                    <p className='name_holder'>{assetDetails.assigned_user ? `${assetDetails.assigned_user.first_name} ${assetDetails.assigned_user.last_name}`: 'Unassigned'}</p>
                                                </div>
                                                <div className='each_card'>
                                                    <p className='label_holder'>Subscription Status</p>
                                                    <p className='name_holder'>{assetDetails.subscription_status}</p>
                                                </div>
                                                <div className='each_card'>
                                                    <p className='label_holder'>Company Name</p>
                                                    <p className='name_holder'>{assetDetails.user_company.company_name}</p>
                                                </div>
                                                <div className='each_card'>
                                                    <p className='label_holder'>Mac Address</p>
                                                    <p className='name_holder'>{assetDetails.mac_id ?? 'N/A'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                        <div className='map_location_holder'>
                            { loading ? 
                                <>
                                    <Skeleton width={318} height={260} style={{ transform: 'none' }} />
                                </> : 
                                <>
                                    {/* <div className="mt-5" style={{ display: "flex", alignItems: "center" }}>
                                        <Icon className="text-red-400 mt-2 mr-5" fontSize="default">location_on_outlined</Icon>
                                        <Typography variant='h6'>Location</Typography>
                                    </div> */}
                                        {/* <div className="m-5" style={{ alignItems: "center", flex: 1 }}>
                                            <p className='asset-address-text' styl>{generateProperLocationString({
                                                location_name: assetDetails?.shipping?.location_name ?? '',
                                                address1: assetDetails?.shipping?.address1 ?? '',
                                                address2: assetDetails?.shipping?.address2 ?? '',
                                                city: assetDetails?.shipping?.city ?? '',
                                                state: assetDetails?.shipping?.state ?? '',
                                                country: assetDetails?.shipping?.country ?? ''
                                            })}</p>
                                        </div> */}
                                        <div>
                                            <iframe width="100%" height="260" src={`https://maps.google.com/maps?q=${encodeURIComponent([assetDetails?.shipping?.address1, assetDetails?.shipping?.address2, assetDetails?.shipping?.city, assetDetails?.shipping?.state, assetDetails?.shipping?.zip, assetDetails?.shipping?.country].filter(item => item).join(","))}&t=&z=13&ie=UTF8&iwloc=&output=embed`} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />
                                        </div>
                                </>
                            }
                        </div>
                    </div>
                </div>

                <div style={{paddingTop: '56px'}}>
                    <Box sx={{ display: 'inline-block', backgroundColor: '#F2F4F7', padding: '8px', borderRadius: '8px', marginRight: '24px' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="category_tabs">
                            <Tab className='tabs_exampleText' label="Information" />
                            <Tab className='tabs_exampleText' label="Notes" />
                            <Tab className='tabs_exampleText' label="Contract Details" />
                            <Tab className='tabs_exampleText' label="Insights" />
                            <Tab className='tabs_exampleText' label={`Service Tickets (${serviceTicketsCount.count ?? 0})`} />
                            <Tab className='tabs_exampleText' label="Asset Specs" />
                            <Tab className='tabs_exampleText' label={`Files (${assetFiles.length})`} />
                            <Tab className='tabs_exampleText' label="Activities" />
                        </Tabs>
                    </Box>

                    <div className='tab_scrollContent'>
                        {value === 0 && <CustomTabPanel className='custom_tab' id='tab-0'>
                            <Information assetDetails={assetDetails} />
                        </CustomTabPanel>}
                        {value === 1 && <CustomTabPanel className='custom_tab' id='tab-1'>
                            <div className='notes_holder'>
			                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {!IsUserViewOnly() && <Button className='button primary_maroon_outlined_btn mb-10' onClick={() => handleOpenAddNote()}>
                                        <i className="icon ti ti-pencil" />
                                        Edit
                                    </Button>}
                                </div>
                                {
                                    Boolean(assetDetails.notes) ? <div dangerouslySetInnerHTML={{__html: assetDetails?.note_style && assetDetails?.note_style !==  ''  ? assetDetails?.note_style : `<p>${assetDetails.notes}</p>`}}/>:
                                    <div>No Data Found</div>
                                }
                                
                            </div>
                        </CustomTabPanel>}
                        {value === 2 && <CustomTabPanel className='custom_tab' id='tab-2'>
                            <ContractDetailsTab assetDetails={assetDetails} />
                        </CustomTabPanel>}
                        {value === 3 && <CustomTabPanel className='custom_tab' id='tab-3'>
                            <Incidents assetDetails={assetDetails}/>
                        </CustomTabPanel>}
                        {value === 4 && <CustomTabPanel className='custom_tab' id='tab-4'>
                            <ServiceRequests assetDetails={assetDetails}/>
                        </CustomTabPanel>}
                        {value === 5 && <CustomTabPanel className='custom_tab' id='tab-5'>
                            <AssetSpecs assetDetails={assetDetails}/>
                        </CustomTabPanel>}
                        {value === 6 && <CustomTabPanel className='custom_tab' id='tab-6'>
                            <Files assetDetails={assetDetails} assetFiles={assetFiles} fetchFiles={fetchFiles}/>
                        </CustomTabPanel>}
                        {value === 7 && <CustomTabPanel className='custom_tab' id='tab-7'>
                            <Activities assetDetails={assetDetails} />
                        </CustomTabPanel>}
                    </div>
                </div>
            </>
            {
                openAddNote && <AddNotes
                    open={openAddNote}
                    close={handleCloseAddNote}
                    content={assetDetails?.note_style && assetDetails?.note_style !==  ''  ? assetDetails?.note_style : `<p>${assetDetails.notes}</p>`}
                    module="asset"
                />
            }
            {openAddAssetForm && <QuickAddAsset open={ openAddAssetForm } close={handleAddAssetFormClose}/>}
        </div>
    )
}

export default AssetDetailsDialogue
