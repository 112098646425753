

import useToast from '@fuse/hooks/useToast'
import {
     makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { formatPhoneNumber } from '@fuse/utils/formatPhoneNumber';
import OTPInput from 'react-otp-input';
import {
	Checkbox, 
} from '@material-ui/core'
// import Checkbox from '@mui/material/Checkbox';
// import { Typography, Button, Box, FormControl, Select, MenuItem, InputLabel, Icon, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import { 
    Card, 
    Box, 
    Stepper, 
    Step, 
    DialogContent,
    StepButton, 
    Button,
    Dialog, 
    TextareaAutosize, 
    DialogTitle, 
    DialogActions,
    Divider, 
    Icon, 
    IconButton, 
    TextField, 
    Typography, 
    InputAdornment, 
    Drawer, 
    CardContent, 
    Paper, 
    Grid, 
    Item, 
    CircularProgress, 
    FormControl, 
    InputLabel, 
    MenuItem, 
    Select, 
    Tab, 
    Tabs, 
    FormLabel, 
    RadioGroup 
} from '@mui/material'
import axios from '@fuse/utils/axios'
import { Autocomplete } from '@material-ui/lab'
import Editor from '@fuse/components/Editor';
import PropTypes from 'prop-types';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import { formatPriceField, generateProperLocationString } from '@fuse/utils/general'
import { usePermission } from '@fuse/hooks';
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'
import { countries } from '@fuse/utils/countryName'
import Radio from '@mui/material/Radio';
import AddContractStatusFromQuickAction from './modal/AddContractStatusFromQuickAction';
import AddLocationFromQuickAction from './modal/AddLocationFromQuickAction';
import AddContractTypeFromQuickAction from './modal/AddContractTypeFromQuickAction'
import AddServiceLevelAgreementFromQuickAction from './modal/AddServiceLevelAgreementFromQuickAction';
import AddPaymentTermFromQuickAction from './modal/AddPaymentTermFromQuickAction'
import AddServiceProviderFromQuickAction from './modal/AddServiceProviderFromQuickAction'
import { useDispatch, useSelector } from 'react-redux';
import { editFormViewerDialog } from 'app/main/roles_and_permission/store/formViewDialogReducer'
import { closeFormViewerDialog, setFromData } from '../../main/contracts/store/formViewDialogReducer'
import { quickAddedContract } from '../../main/contracts/store/reportViewDialogReducer'
import AlertModal from 'helper/alertModal'
import PhoneNumberVerificationModal from '@fuse/components/Modal/PhoneNumberVerificationModal'



const useStyles = makeStyles(theme => (
    {
        uploadImageButton: {
            padding: '6px 16px',
            fontSize: '1.3rem',
            minWidth: '64px',
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: '#6B2C57',
            color: '#ffffff',
            cursor: 'pointer'
        },
        loader: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        subSection: {
            marginLeft: '20px',
            marginRight: '20px',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#F9FAFB',
            borderRadius: '4px'
        },
        endButtons: {
            borderRadius: '4px'
        },
        drawerWidth: {
            width: 520,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            overflowX: 'hidden',
            overflowY: 'hidden'
        },
        helperText: {
            color: 'red',
            paddingLeft: '1px'
        },
        datePickerInput: {
            '& .MuiInputBase-input': {
                fontSize: '16px'
            }
        }
    }
))
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function QuickAddContract(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
	const toast = useToast(dispatch)



    const [ isPhoneVerified, setIsPhoneVerified ] = useState(false)
    const [ openPhoneVerificationModal, setOpenPhoneVerificationModal ] = useState(false);
   

    const [contractStartDate,setContractStartDate] = useState({
		date: null,
		error: false
	})

	const [contractEndDate,setContractEndDate] = useState({
		date: null,
		error: false
	})


    const [billingDate,setBillingDate] = useState({
		date: null,
		error: false
	})


    const [currentCountryCurrency, setCurrentCountryCurrency] = useState({
		"name": "United States",
		"code": "US",
		"currency": "USD"
	})

    const [paymentDate,setPaymentDate] = useState({
		date: null,
		error: false
	})

    


    const [purchaseOrder,setPurchaseOrder] = useState('purchaseOrder')
	const [cardNumber, setCardNumber] = useState('');


    const [age, setAge] = useState('');

    const [shippingLocationMenu, setShippingLocationMenu] = useState([])
	const [contractStatusMenu, setContractStatusMenu] = useState([])
	const [serviceProviderMenu, setServiceProviderMenu] = useState([])
	const [paymentTermMenu, setPaymentTermMenu] = useState([])
    const [slaMenu, setSlaMenu] = useState([])
    const [contractsMenu, setContractsMenu] = useState([])
    const [parentContractMenu, setParentContractMenu] = useState([])
    const [ masterValue, setMasterValue ] = useState('Yes');
    const { hasPermission } = usePermission()
    const [ formName, setFormName ] = useState('Add Contract')
    const [ editContractId, setEditContractId ] = useState(0);

    const [ openAddContractStatusModal, setOpenAddContractStatusModel ] = useState(false)
    const [ openAddLocationModal, setOpenAddLocationModal ] = useState(false)
    const [ openAddContractTypeModal, setOpenAddContractTypeModal ] = useState(false)
    const [ openAddServiceLevelAgreementModal, setOpenAddServiceLevelAgreementModal ] = useState(false)
    const [ openAddPaymentTermModal, setOpenAddPaymentTermModal ] = useState(false)
    const [ openAddServiceProviderModal, setOpenAddServiceProviderModal ] = useState(false)
    const [openAlerModal, setOpenAlertModal] = useState(false);

    
    const { data: editFromState } = useSelector((state) => state.contractsApp.formViewDialog);


    const handleCurrentCountry = (countryCode) => {
		if(countryCode !== null) {
			const current = (countries.filter(country => country.code === countryCode))[0]
			setCurrentCountryCurrency(current)
			setValue('billingCycleCurrency', current)
		} else {
			setCurrentCountryCurrency({ "name": "United States", "code": "US", "currency": "USD" })
			setValue('billingCycleCurrency', { "name": "United States", "code": "US", "currency": "USD" })
		}
		
	}

    const [ contractDescriptionText,setContractDescriptionText ] = useState('')
    




    const handleChangeExport = (event) => {
        setAge(event.target.value);
    };
    const [assetNote, setAssetNote] = useState('')
    const [assetNoteText, setAssetNoteText] = useState('')
    
    function onEditorContentChange (content) {
        setContractDescriptionText(content)
    }
    // const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        console.log('new value', newValue)
        // setValue(newValue);
    };
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const [recoveryDate, setRecoveryDate] = useState({
        date: null,
        error: false
    })


    const steps = ['Required Details', 'Contract Type', 'Service Level Agreement', 'Billing Cycle', 'Payment Method', 'Service Provider'];

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };    

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        // handleNext();
    };

    useEffect(() => {
		fetchAllSuggestion()
		fetchContractType()
		fetchParentContract()
	}, [])


    const fetchAllSuggestion = () => {
		axios.get(`contracts/get-all-suggestion`).then(response => {
			const { paymentTerm, shipping, contractStatus, sla, serviceProvider } = response.data.data
			setContractStatusMenu(contractStatus)
			setPaymentTermMenu(paymentTerm)
			setServiceProviderMenu(serviceProvider)
			setSlaMenu(sla)
			setShippingLocationMenu(shipping)
		}).catch(err => {
			console.log('err', err);
			//   toast.error('Something went wrong!')
		})
	}


    function fetchParentContract() {
		axios.get(`contracts/get-parent-contracts`).then(response => {
			// console.log(response.data.data)
			setParentContractMenu(response.data.data)
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}

    function fetchContractType() {
		axios.get(`/contracts/get-contract-types`).then(response => {
			// console.log(response.data.data)
			const check = response.data.data.map((item) => item.display_name)
			setContractsMenu(check)
		}).catch(err => {
			console.log('err', err);
			// toast.error('Something went wrong!')
		})
	}


    const defaultValues = {
        contractNumber: "",
        contract: "",
        contractDescription: "",
        totalContractValue: '',
        startDate: null,
        endDate: null,
        contractStatus: null,
        currentShippingLocation: null,
        contractType: null,
        isContractNew: "",
        isMaster: "",
        parentContract: null,
        sla: null,
        provider: null,
        billingCycleCurrency: { "name": "United States", "code": "US", "currency": "USD" },
		billingCycleAmount: '',
        every: 1,
        paymentTerm: null,
        paymentMethod: "CreditCard",
        cardHolderName: "",
        cardNumber: "",
        poNumber: ""
    };

    

    const validationSchema = [
        // 0 Required Details
        yup.object({
            contractNumber: yup.string().trim().required('Contract Number is required.'),
            contract: yup.string().trim().required(),
            contractDescription: yup.string().nullable(),
            contractStatus: yup.object().shape({
                id: yup.number().required('Tag is required.'),
                // entity_external_platform_id: yup.string().required('Status is required.'),
                contract_status_name: yup.string('Tag is required.').required(),
                // status_color: yup.object()
            }).required('Tag is required.'),

            currentShippingLocation: yup.object().shape({
                id: yup.number().required('Location is required.'),
                entity_external_platform_id: yup.string().required('Status is required.'),
                location_name: yup.string().required('Location is required.'),
                address1: yup.string().required('Location is required.'),
                // address2: yup.string().required('Location is required.'),
                city: yup.string().required('Location is required.'),
                country: yup.string().required('Location is required.'),
                state: yup.string().required('Location is required.'),
                zip: yup.string().required('Location is required.'),
            }).required('Location is required.'),
            // startDate: yup.date().required().max(
            //     yup.ref('endDate'), "start date can't be after end date"
            // ),
            // endDate: yup.date().required().min(
            //     yup.ref('startDate'), "end date can't be before start date"
            // ),
            totalContractValue: yup.string().typeError('Invalid value').required('The Total Contract Value is required!').test('totalContractValue', 'Invalid Value', (value) => {
                // if(/^\d+(\.\d{1,2})?$/.test(value.toString()) || /^[0-9]{1,3}(?:\,[0-9]{3})*(?:\.[0-9]{1,2})?$/.test(value.toString())) {
                // 	return true;
                // }
                // return false;
                if(value.trim() == '') return false
                return /^\d+([\,]\d+)*([\.]\d+)?$/.test(value.toString())
            }),
            location: yup.string().required()
        }),
        // 1 Contract Type
        yup.object({
            contractType: yup?.string().required('Contract type is required.').nullable(),
            isContractNew: yup.string().required('Please select the contract type').oneOf(['New', 'Renew'], "The field value must be one of the following values: New, Renew"),
            isMaster: yup.string().required('please select the value from option menu').oneOf(['Yes', 'No'], "The field value must be one of the following values: Yes, No"),
            parentContract: yup.object().nullable().when("isMaster", {
                is: "No",
                then: yup.object().shape({
                    id: yup.string().required('Parent Contract is required.'),
                    entity_external_platform_id: yup.string().required('Parent Contract is required.'),
                    contract_name: yup.string().required('Parent Contract is required.')
                })
            })
        }),
        // 2 Service Level Agreement
        yup.object({
            sla: yup.object().shape({
                id: yup.string().required('Service Level Agreement is required.'),
                // entity_external_platform_id: yup.string().required('Service Level Agreement is required.'),
                sla_name: yup.string().required('Service Level Agreement is required.')
            }).required('Service Level Agreement is required.')
        }),
        // 3 Billing Cycle
        yup.object({
            billingCycleAmount: yup.string().typeError('Invalid value').required('The Billing Cycle Amount is required!').test('billingCycleAmount', 'Invalid Value', (value) => {
                // if(/^\d+(\.\d{1,2})?$/.test(amountValue.toString()) || /^[0-9]{1,3}(?:\,[0-9]{3})*(?:\.[0-9]{1,2})?$/.test(amountValue.toString())) {
                    return /^\d+([\,]\d+)*([\.]\d+)?$/.test(value.toString())
                // }
                // return false;
                // return /^\d+([\,]\d+)*([\.]\d+)?$/.test(amountValue.toString())
            }),
            // billingCycleAmount: yup.string().required('The Billing Cycle Amount is required!'),
            billingCycleCurrency: yup.object().shape({
                name:  yup.string().required('Currency is required.'),
                code: yup.string().required('Currency is required.'),
                currency: yup.string().required('Currency is required.')
		    }),
            every: yup.number().test('billingCycleAmount', 'Invalid Value', (value) => {
                if(value <= 0) {
                    return false
                }
                return true
            }).required('Every Field Value is required.'),
            paymentTerm: yup.object().shape({
                id: yup.string().required('Payment Term is required.'),
                // entity_external_platform_id: yup.string().required('Payment Term is required.'),
                payment_term_name: yup.string().required('Payment Term is required.'),
                display_name: yup.string().required('Payment Term is required.')
            }).required('Payment Term is required.'),
        }),
        // 4 Payment Method
        yup.object({
            paymentMethod: yup.string().required('payment Method is required!'),
            cardHolderName:  yup.string().when('paymentMethod', {
                is: 'CreditCard',
                then: yup.string().required('Card Holder Name is required'),
                otherwise: yup.string().nullable(),
            }),
		    cardNumber: yup.string().when('paymentMethod', {
                is: 'CreditCard',
                then: yup.string().required('Card number is required').test('cardNumber', 'Invalid Card Number Value', (cardNumberValue) => {
                    if(cardNumberValue.length === 4 ) {
                        return /^[0-9]*$/.test(cardNumberValue)
                    }
                    return false
                }),
                otherwise: yup.string().nullable(),
            }),
            poNumber: yup.string().when('paymentMethod', {
                is: 'purchaseOrder',
                then: yup.string().required('PO number is required'),
                otherwise: yup.string().nullable(),
            })
        }),
        //5 Service Provider
        yup.object({
            provider: yup.object().shape({
                id: yup.string().required('Service Provider is required.'),
                // entity_external_platform_id: yup.string().required('Service Provider is required.'),
                service_provider_name: yup.string().required('Service Provider is required.')
            }).required('Service Provider is required.')
        }),
        yup.object({
            
        }),

    ];

    const currentValidationSchema = validationSchema[activeStep];


    const methods  = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(currentValidationSchema),
        mode: "onChange"
    });

    const { control, formState, handleSubmit, reset, setValue, clearErrors, getValues, trigger, register, setError } = methods

    const { errors } = formState
    
    const handleNext = async () => {
        await trigger();
        switch(activeStep) {
            // Required Details
            case 0:
                // console.log(errors)
                // console.log(getValues())
                if(Object.keys(errors).length !== 0) {
                    return;
                }
                if(contractStartDate.date === null && contractEndDate.date === null) {
                    toast.error('Contract start date and Contract End date are required.')
                    return;
                }
                if(contractStartDate.error ) {
                    toast.error('There is an error on Contract Start Date')
                    return;
                }
                if(contractEndDate.error ) {
                    toast.error('There is an error on Contract End Date')
                    return;
                }
                if(contractStartDate.date === null) {
                    toast.error('Contract start date is required.')
                    return;
                }
                if(contractEndDate.date === null) {
                    toast.error('Contract end date is required.')
                    return;
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            // Contract Type
            case 1:
                console.log(errors)
                if(Object.keys(errors).length !== 0) {
                    return;
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            // Service Level Agreement
            case 2:
                console.log(errors)
                if(Object.keys(errors).length !== 0) {
                    return;
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            // Billing Cycle
            case 3:
                console.log(errors)
                if(Object.keys(errors).length !== 0) {
                    return;
                }
                if(billingDate.date === null) {
                    toast.error('Billing date is required.')
                    return;
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            // Payment Method
            case 4:
                console.log(errors)
                if(Object.keys(errors).length !== 0) {
                    return;
                }
                if(methods.watch("paymentMethod") === "purchaseOrder") {
                    if(paymentDate.date === null) {
                        toast.error('Payment date is required.')
                        return;
                    }
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            // Service Provider
            case 5:
                console.log(errors)
                if(Object.keys(errors).length !== 0) {
                    return;
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            default:
                break; 
        }
    };


    const capturePaymentMethodChangeEvent = (paymentMethodValue) => {
        if(paymentMethodValue === "CreditCard") {
            setValue('poNumber', '');
            setPaymentDate({
                date: null,
                error: false
            })
        } else {
            setValue('cardHolderName', '');
            setValue('cardNumber', '');
        }

    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        reset();
    };


    const handleCloseForm = () => {
        setActiveStep(0);
        reset(defaultValues);
        clearErrors()
        setCurrentCountryCurrency({ "name": "United States", "code": "US", "currency": "USD" })
        setPurchaseOrder('purchaseOrder')
        setPaymentDate({
            date: null,
            error: false
        })
        setBillingDate({
            date: null,
		    error: false
        })
        setContractEndDate({
            date: null,
		    error: false
        })
        setContractStartDate({
            date: null,
		    error: false
        }) 

        props.close('contract')
    }

    const captureIsMasterOnchangeEvent = (event) => {
        setMasterValue(event.target.value)
    }


    const handleRadioChange = (event) => {
        // console.log(event.target.value)
		setPurchaseOrder(event.target.value);
	};

    


    const onSubmit = async (data) => {
        let payload = {
            contract_id: data.contractNumber.trim(),
            contract_name: data.contract.trim(),
            contract_description: contractDescriptionText.trim(),
            contract_start_date: contractStartDate.date ?? '',
            contract_end_date: contractEndDate.date ?? '',
            contract_status: data.contractStatus,
            shipping_id: data.currentShippingLocation.id,
            total_contract_value: data.totalContractValue?.replaceAll(',',''),
            // billing_id: formData.currentBillingLocation.id,
            contract_type: data.contractType,
            master: data.isMaster === "Yes" ? true : false,
            parent_contract_id: data.isMaster == "Yes" ? 0 : data.parentContract.id,
            // // notes: formData.notes,
            // // department: formData.department,
            provider: data.provider,
            sla: data.sla,
            renewal: data.isContractNew,
            billing: {
                amount: data.billingCycleAmount?.replaceAll(',',''),
                location: data.billingCycleCurrency.name,
                cycle: String(data.every) || '',
                month: data.paymentTerm.name || '',
                date: billingDate.date || '',
                currency: data.billingCycleCurrency.currency,
                payment_term_id: data.paymentTerm.id
            },
            payment: {
                value: data.paymentMethod,
                po_number:  data.paymentMethod === 'purchaseOrder' ? data.poNumber !== '' ? data.poNumber : null : null,
                po_date: data.paymentMethod === 'purchaseOrder' ? paymentDate.date !== '' ?  paymentDate.date : null: null,
                // card_number:formData.cardNumber !== '' ? formData.cardNumber : null,
                card_number: data.paymentMethod === 'CreditCard' ? data.cardNumber  === '' ? null : data.cardNumber : null,
                card_holder_name: data.paymentMethod === 'CreditCard' ? data.cardHolderName !== '' ? data.cardHolderName: null : null,
            },
        }

        

        if(parseInt(editContractId) === 0) {
            axios.post('/contracts/add-contract-manually', payload).then(response => {
            if (response.status == 201) {
                toast.error(response.data.message)
            } else {
                // onSuccess()
                // closeDialog()
                handleCloseForm()
                toast.success('Contract Added Successfully.')
                dispatch(quickAddedContract())
                props.close('contract')
                // props.fetchAllManuallyAddedContract()
                // fetchParentContract()
            }

        }).catch(err => {
            toast.error(err.message)
            console.log('212=>', err);
        })
        } else {
            payload.id = editFromState.id
            payload.billing.subscription_billing_id = editFromState.subscription_billing_id
            payload.payment.subscription_payment_id = editFromState.subscription_payment_id
            axios.put('/contracts/update-contract-manually', payload).then(response => {
				if(response.status == 201){
					toast.error(response.data.message)
				} else {
                    reset(defaultValues)
                    toast.success('Contract Updated Successfully.')
                    dispatch(quickAddedContract())
                    props.close('contract')

				}
			}).catch(err => {
				console.log('212=>',err);
				toast.error('Something went wrong!')
			})
        }

        
    };


    const onCancel = () => {
		closeDialog()
	}

	const closeDialog = () => {
        clearErrors()
		reset(defaultValues)
	}





    const handleOpenCloseAddLocation = () => {
        setOpenAddLocationModal(!openAddLocationModal)
    }


    const handleOpenCloseAddContractStatus = () => {
        setOpenAddContractStatusModel(!openAddContractStatusModal)
    }


    const handleOpenCloseAddContractType = () => {
        setOpenAddContractTypeModal(!openAddContractTypeModal)
    }


    const handleOpenCloseAddServiceLevelAgreement = () => {
        setOpenAddServiceLevelAgreementModal(!openAddServiceLevelAgreementModal)
    }


    const handleOpenCloseAddPaymentTerm = () => {
        setOpenAddPaymentTermModal(!openAddPaymentTermModal)
    }


    const handleOpenCloseAddServiceProvide = () => {
        setOpenAddServiceProviderModal(!openAddServiceProviderModal)
    }



    const newContractStatusAddedEvent = (createdData) => {
        setOpenAddContractStatusModel(!openAddContractStatusModal)
        setValue('contractStatus', {
            id: createdData.id,
            contract_status_name: createdData.contract_status_name
        })
        clearErrors("contractStatus")
        fetchAllSuggestion()
        // console.log(createdData)
    }


    const newLocationAddedEvent = (createdData) => {
        setOpenAddLocationModal(!openAddLocationModal)
        setValue('currentShippingLocation',{
            entity_external_platform_id: createdData.entity_external_platform_id,
            id: createdData.id,
            location_name: createdData.location_name,
            address1: createdData.address1 || '',
            address2: createdData.address2 || '',
            city: createdData.city || '',
            state: createdData.state || '',
            country: createdData.country || '',
            zip : createdData.zip || ''
        })
        clearErrors("currentShippingLocation")
        fetchAllSuggestion()
    }


    const newContractTypeAddedEvent = (createdData) => {
        setOpenAddContractTypeModal(!openAddContractTypeModal)
        setValue('contractType', createdData || '')
        fetchContractType()
    }


    const newServiceLevelAgreementAddedEvent = (createdData) => {
        setOpenAddServiceLevelAgreementModal(!openAddServiceLevelAgreementModal)
        setValue('sla', {
            id: createdData.id,
            sla_name: createdData.sla_name
        })
        clearErrors("sla")
        fetchAllSuggestion()
    }


    const newPaymentTermAddedEvent = (createdData) => {
        setOpenAddPaymentTermModal(!openAddPaymentTermModal)
        setValue("paymentTerm", {
            id: createdData.id,
            payment_term_name: createdData.display_name,
            display_name: createdData.display_name,
        })
        clearErrors("paymentTerm")
        fetchAllSuggestion()
    }


    const newServiceProviderAddedEvent = (createdData) => {
        setOpenAddServiceProviderModal(!openAddServiceProviderModal)
        setValue('provider', {
            id: createdData.id,
            service_provider_name: createdData.service_provider_name
        })
        clearErrors("provider")
        fetchAllSuggestion()
    }


    const handleTotalContractValueBlurEvent = () => {
        if(getValues('totalContractValue') === "") {
            setError('totalContractValue',{
                message: "Total Contract Value is required!",
            })
            setValue('totalContractValue','')
            // console.log("messi")
            // console.log(errors)
        } else {
            if(!isNaN(getValues('totalContractValue'))) {
                clearErrors('totalContractValue', '')
                setValue('totalContractValue',formatPriceFieldOnBlur(getValues('totalContractValue')))
            } else {
                if(/\d/.test(getValues('totalContractValue'))) {
                    clearErrors('totalContractValue', '')
                    setValue('totalContractValue',formatPriceFieldOnBlur(getValues('totalContractValue')))
                } else {
                    // setError('totalContractValue', 'Total Contract Value is required!')
                    setError('totalContractValue',{
                        message: "Total Contract Value is required!",
                    })
                    setValue('totalContractValue','')
                }   
            }
        }
    }


    const handleBillingAmountValueBlurEvent = () => {
        if(!isNaN(getValues('billingCycleAmount'))) {
            clearErrors('billingCycleAmount', '')
            setValue('billingCycleAmount',formatPriceFieldOnBlur(getValues('billingCycleAmount')))
        } else {
            if(/\d/.test(getValues('billingCycleAmount'))) {
                clearErrors('billingCycleAmount', '')
                setValue('billingCycleAmount',formatPriceFieldOnBlur(getValues('billingCycleAmount')))
            } else {
                setError('billingCycleAmount', 'Billing Amount is required!')
                setValue('billingCycleAmount','')
            }   
        }	
    }




    useEffect(()=>{
		if(!editFromState) return
        // console.log(typeof editFromState.amount)
        setEditContractId(editFromState.id)
        const getCountryDetails = (countries.filter(country => country.currency === editFromState.currency))[0]
        setValue('billingCycleCurrency', getCountryDetails)
        setFormName('Edit Contract')
        setValue('contract', editFromState.contract_name)
        setValue('contractNumber', editFromState.contract_number)
        setContractDescriptionText(editFromState.contract_description)
        setContractStartDate((prev) => {
			return {
				...prev,
				date: editFromState.original_info.contract_start_date
			}
		})
		setContractEndDate((prev) => {
			return {
				...prev,
				date: editFromState.original_info.contract_end_date
			}
		})
        setValue('contractStatus', {
            id: editFromState.contract_status_object.id,
            contract_status_name: editFromState.contract_status_object.contract_status_name
        })

        setValue('currentShippingLocation', {
            id: editFromState.location_object.id,
            entity_external_platform_id: editFromState.location_object.entity_external_platform_id,
            location_name: editFromState.location_object.location_name,
            address1: editFromState.location_object.address1,
            city: editFromState.location_object.city,
            country: editFromState.location_object.country,
            state: editFromState.location_object.state,
            zip: editFromState.location_object.zip,
        })
        setValue('totalContractValue', editFromState.total_contract_value_)
        setValue('isContractNew', editFromState["new/renew"])
        setValue('isMaster', editFromState.master)
        setValue('parentContract', editFromState.parent_contract_object)
        setValue('contractType', editFromState.contract_type_object.display_name)
        setValue('sla', editFromState.service_level_agreement_object)
        setValue('billingCycleAmount', formatPriceFieldOnBlur(editFromState.amount.toString()))
        // setValue('billingCycleAmount', editFromState.amount)
        setValue('every', editFromState.every)
        setValue('paymentTerm', editFromState.payment_term === null ?  null : {
            id: editFromState.payment_term.id,
            payment_term_name: editFromState.payment_term.display_name,
            display_name: editFromState.payment_term.display_name
        })
        setBillingDate( prev => {
            return {
                ...prev,
                date: editFromState.billing_cycle_date_exact
            }
        })
        setValue('paymentMethod', editFromState.value)
        setValue('provider', editFromState.service_provider_object)
        setValue('cardHolderName', editFromState.subscription_payment === null ?  "" : editFromState.subscription_payment.card_holder_name)
        setValue('cardNumber', editFromState.subscription_payment === null ?  ""  : editFromState.subscription_payment.card_number)
        setPaymentDate( prev => {
            return {
                ...prev,
                date: editFromState.po_date_exact
            }
        })
        setValue('poNumber', editFromState.po_number === "No PO Number" ? null :  editFromState.po_number)
        
	},[editFromState])

    const handleCloseOpenPhoneVerificationModal = () => {
        setOpenPhoneVerificationModal(false)
    }

    const handleAlertModal=(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpenAlertModal(!openAlerModal)
    }

    const handleCloseAlertModal=() =>{
        setOpenAlertModal(false)
        handleCloseForm()
    }

    return (


        <>
            <div>
                <Drawer anchor='right' open={props.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
                    <div className='heading_holder'>
                        <h4 className='heading'>{ formName }</h4>
                        <IconButton onClick={handleAlertModal} style={{padding: '0px'}}>
                            <Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
                        </IconButton>
                    </div>

                    <div className='progressbar_holder'>
                        <div style={{width: activeStep === 0 ? '14.28%' : activeStep === 1 ? '28.56%' : activeStep === 2 ? '42.84%' : activeStep === 3 ? '57.12%' : activeStep === 4 ? '71.4%' : activeStep === 5 ? '85.68%' : activeStep === 6 ? '100%' : '0%'}} className='progress'>
                            &nbsp;
                        </div>
                    </div>

                    <div className='cstm_wizard_holder'>
                        <Box sx={{ width: '100%' }} className='box_holder'>
                            <Stepper activeStep={activeStep} className='stepper_holder'>
                                {steps.map((label, index) => (
                                <Step className='each_step_holder' key={label} completed={completed[index]}>
                                    <StepButton color="inherit" onClick={handleStep(index)}>
                                    {label}
                                    </StepButton>
                                </Step>
                                ))}
                            </Stepper>
                            <div className='main_content_holder'>
                                {/* {allStepsCompleted() ? (
                                <>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </>
                                ) : ( */}
                                <>
                                    <h4 className='main_heading'>
                                        {activeStep === 0 && <span>Required Details</span>}
                                        {activeStep === 1 && <span>Contract Type</span>}
                                        {activeStep === 2 && <span>Service Level Agreement</span>}
                                        {activeStep === 3 && <span>Billing Cycle</span>}
                                        {activeStep === 4 && <span>Payment Method</span>}
                                        {activeStep === 5 && <span>Service Provider</span>}
                                    </h4>
                                    <FormProvider {...methods}>
                                        <form>
                                            <div className='content_holder'>
                                                {/* Required Details */}
                                                {activeStep === 0 && 
                                                    <>
                                                        <Box className='light_blue_holder'>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                
                                                                    <Typography className='status_text'>
                                                                        Contract Number
                                                                        <span className='required_span'>*</span>
                                                                    </Typography>
                                                                    <Controller 
                                                                        name="contractNumber"
                                                                        className='serial_numberField'
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                style={{ background: 'white' }}
                                                                                variant='outlined'
                                                                                fullWidth
                                                                                required
                                                                                defaultValue={defaultValues.contractNumber}
                                                                                error={!!errors.contractNumber}
                                                                                helperText={errors?.contractNumber?.message}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        margin: 0,
                                                                                        backgroundColor: '#f4f4f4',
                                                                                        width: '100%',
                                                                                        paddingTop: '2px',
                                                                                        border: '1px solid #F2F4F7',
                                                                                        fontSize: '10px',
                                                                                    }
                                                                                }} 
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>                                                
                                                                <Grid item xs={6}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Contract
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <Controller 
                                                                            name="contract"
                                                                            className='serial_numberField'
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    style={{ background: 'white' }}
                                                                                    variant='outlined'
                                                                                    fullWidth
                                                                                    required
                                                                                    defaultValue={defaultValues.contract}
                                                                                    error={!!errors.contract}
                                                                                    helperText={errors?.contract?.message}
                                                                                    FormHelperTextProps={{
                                                                                        style: {
                                                                                            margin: 0,
                                                                                            backgroundColor: '#f4f4f4',
                                                                                            width: '100%',
                                                                                            paddingTop: '2px',
                                                                                            border: '1px solid #F2F4F7',
                                                                                            fontSize: '10px',
                                                                                        }
                                                                                    }} 
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography className='status_text'>Contract Description</Typography>
                                                                    <Editor
                                                                        editorContent={contractDescriptionText}
                                                                        onEditorContentChange={onEditorContentChange}
                                                                        placeholderText={'Contract Description'}
                                                                    />   
                                                                    {/* <Controller 
                                                                        name="contractDescription"
                                                                        className='serial_numberField'
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                style={{ background: 'white' }}
                                                                                variant='outlined'
                                                                                defaultValue={defaultValues.contractDescription}
                                                                                error={!!errors.contractDescription}
                                                                                helperText={errors?.contractDescription?.message}
                                                                                fullWidth
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        margin: 0,
                                                                                        backgroundColor: '#f4f4f4',
                                                                                        width: '100%',
                                                                                        paddingTop: '2px',
                                                                                        border: '1px solid #F2F4F7',
                                                                                        fontSize: '10px',
                                                                                    }
                                                                                }} 
                                                                            />
                                                                        )}
                                                                    /> */}
                                                                    
                                                                </Grid>
                                                                <Grid item xs={6}>                                                            
                                                                    <Typography className='status_text'>
                                                                        Contract Start Date
                                                                        <span className='required_span'>*</span>
                                                                    </Typography>
                                                                    <CustomDatePickerV2
                                                                        state={contractStartDate}
                                                                        setState={setContractStartDate}
                                                                        required={true}
                                                                        fromCustomFilter={false}
                                                                        // label='Contract Start Date*'
                                                                        maxDate={contractEndDate.date}
                                                                        condition='Contract End Date'									
                                                                    />
                                                                </Grid>                                                
                                                                <Grid item xs={6}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Contract End Date
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <CustomDatePickerV2
                                                                            state={contractEndDate}
                                                                            setState={setContractEndDate}
                                                                            required={true}
                                                                            fromCustomFilter={false}
                                                                            // label='Contract End Date*'
                                                                            minDate={contractStartDate.date}
                                                                            condition='Contract Start Date'									
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Tag
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <FormControl fullWidth className='assets_listSelect'>
                                                                            <Controller
                                                                                name="contractStatus"
                                                                                className="mb-16"
                                                                                control={control}
                                                                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                                    <>
                                                                                        <Autocomplete
                                                                                            {...field}
                                                                                            style={{ width: '100%' }}
                                                                                            className='main_autocomplete'
                                                                                            options={contractStatusMenu}
                                                                                            // disablePortal
                                                                                            onChange={(event, value) => { field.onChange(value) }}
                                                                                            PaperComponent={({ children }) => (
                                                                                                <Paper className='autocomplete_holder'>{children}
                                                                                                    <div className='p-16'>
                                                                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddContractStatus()}>
                                                                                                            <i className='ti ti-plus' />
                                                                                                            <span>Add Contract Tag</span>
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Paper>
                                                                                            )}
                                                                                            getOptionLabel={option => option.contract_status_name ?? ''}
                                                                                            renderInput={params => {
                                                                                                return (
                                                                                                    <div ref={params.InputProps.ref}>
                                                                                                        <TextField
                                                                                                            required
                                                                                                            {...params}
                                                                                                            name="contractStatus"
                                                                                                            // label='Contract Status'
                                                                                                            variant='outlined'
                                                                                                            fullWidth
                                                                                                            error={!!errors?.contractStatus}
                                                                                                            helperText={errors?.contractStatus?.message && 'Tag is required!'}
                                                                                                            defaultValue={defaultValues.contractStatus}
                                                                                                            inputRef={ref}
                                                                                                            FormHelperTextProps={{
                                                                                                                style: {
                                                                                                                    margin: 0,
                                                                                                                    backgroundColor: '#f4f4f4',
                                                                                                                    width: '100%',
                                                                                                                    paddingTop: '2px',
                                                                                                                    fontSize: '10px',
                                                                                                                }
                                                                                                            }}
                                                                                                            style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Location
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <FormControl fullWidth className='assets_listSelect'>
                                                                            <Controller
                                                                                name="currentShippingLocation"
                                                                                className="mb-16"
                                                                                control={control}
                                                                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                                    <>
                                                                                        <Autocomplete
                                                                                            {...field}
                                                                                            style={{ width: '100%' }}
                                                                                            className='main_autocomplete'
                                                                                            options={shippingLocationMenu}
                                                                                            // disablePortal
                                                                                            onChange={(event, value) => { field.onChange(value) }}
                                                                                            PaperComponent={({ children }) => (
                                                                                                <Paper className='autocomplete_holder'>{children}
                                                                                                    <div className='p-16'>
                                                                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddLocation()}>
                                                                                                            <i className='ti ti-plus' />
                                                                                                            <span>Add Location</span>
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Paper>
                                                                                            )}
                                                                                            getOptionLabel={option => {
                                                                                                return generateProperLocationString({
                                                                                                    location_name: option?.location_name ?? '',
                                                                                                    address1: option?.address1 ?? '',
                                                                                                    address2: option?.address2 ?? '',
                                                                                                    city: option?.city ?? '',
                                                                                                    state: option?.state ?? '',
                                                                                                    country: option?.country ?? ''
                                                                                                })
                                                                                                // `${option.location_name ?? ''} - ${option.address1}, ${option.city}, ${option.state}, ${option.country}`
                                                                                            }}
                                                                                            renderInput={params => {
                                                                                                return (
                                                                                                    <div ref={params.InputProps.ref}>
                                                                                                        <TextField
                                                                                                            required
                                                                                                            {...params}
                                                                                                            name="currentShippingLocation"
                                                                                                            // label='Location'
                                                                                                            variant='outlined'
                                                                                                            fullWidth
                                                                                                            defaultValue={defaultValues.currentShippingLocation}
                                                                                                            error={!!errors?.currentShippingLocation}
                                                                                                            helperText={errors?.currentShippingLocation?.message && 'Location is required!'}
                                                                                                            inputRef={ref}
                                                                                                            FormHelperTextProps={{
                                                                                                                style: {
                                                                                                                    margin: 0,
                                                                                                                    backgroundColor: '#f4f4f4',
                                                                                                                    width: '100%',
                                                                                                                    paddingTop: '2px',
                                                                                                                    fontSize: '10px',
                                                                                                                }
                                                                                                            }}
                                                                                                            style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography className='status_text'>Total Contract Value  <span className='required_span'>*</span></Typography>
                                                                    <Controller 
                                                                        name="totalContractValue"
                                                                        className='serial_numberField'
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <TextField
                                                                                {...field}
                                                                                style={{ background: 'white' }}
                                                                                onChange={(event) => {
                                                                                    // if(/\d/.test(event.target.value)) {
                                                                                    //     console.log("demo")
                                                                                    //     clearErrors('totalContractValue', '')
                                                                                    // }
                                                                                    field.onChange(event.target.value)
                                                                                }}
                                                                                variant='outlined'
                                                                                defaultValue={defaultValues.totalContractValue}
                                                                                error={!!errors.totalContractValue}
                                                                                helperText={errors?.totalContractValue?.message}
                                                                                fullWidth
                                                                                onKeyPress={(event) => {
                                                                                    if (!/[0-9,.]+/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                onBlur={()=>handleTotalContractValueBlurEvent()}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        margin: 0,
                                                                                        backgroundColor: '#f4f4f4',
                                                                                        width: '100%',
                                                                                        paddingTop: '2px',
                                                                                        border: '1px solid #F2F4F7',
                                                                                        fontSize: '10px',
                                                                                    }
                                                                                }} 
                                                                                InputProps={{
                                                                                    startAdornment:
                                                                                        <InputAdornment>
                                                                                            <Icon style={{ opacity: '0.4' }} >attach_money_outlined</Icon>
                                                                                        </InputAdornment>
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                    
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </>
                                                }
                                                {/* Contract Type */}
                                                {activeStep === 1 && 
                                                    <>
                                                        <Box className='light_blue_holder'>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Contract Type
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <FormControl fullWidth className='assets_listSelect'>
                                                                            <Controller
                                                                                name="contractType"
                                                                                className="mb-16"
                                                                                control={control}
                                                                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                                    <>
                                                                                        <Autocomplete
                                                                                            {...field}
                                                                                            style={{ width: '100%' }}
                                                                                            className='main_autocomplete'
                                                                                            options={contractsMenu}
                                                                                            // disablePortal
                                                                                            // getOptionLabel={option => option.display_name }
                                                                                            onChange={(event, value) => field.onChange(value)}
                                                                                            PaperComponent={({ children }) => (
                                                                                                <Paper className='autocomplete_holder'>
                                                                                                    {children}
                                                                                                    <div className='p-16'>
                                                                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddContractType()}>
                                                                                                            <i className='ti ti-plus' />
                                                                                                            <span>Add Contract Type</span>
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                    {/* <Button
                                                                                                        style={{ width: '100%' }}
                                                                                                        variant="outlined"
                                                                                                        color="primary"
                                                                                                        onMouseDown={() => handleOpenCloseAddContractType()}
                                                                                                    >
                                                                                                        Add Contract Type
                                                                                                    </Button> */}
                                                                                                </Paper>
                                                                                            )}
                                                                                            renderInput={params => {
                                                                                                return (
                                                                                                    <div ref={params.InputProps.ref}>
                                                                                                        <TextField
                                                                                                            required
                                                                                                            {...params}
                                                                                                            name="contractType"
                                                                                                            // label='Contract Type'
                                                                                                            variant='outlined'
                                                                                                            fullWidth
                                                                                                            error={!!errors?.contractType}
                                                                                                            helperText={errors?.contractType?.message && 'Contract Type is required!'}
                                                                                                            defaultValue={defaultValues.contractType}
                                                                                                            // inputRef={contractTypeInputRef}
                                                                                                            FormHelperTextProps={{
                                                                                                                style: {
                                                                                                                    margin: 0,
                                                                                                                    backgroundColor: '#f4f4f4',
                                                                                                                    width: '100%',
                                                                                                                    paddingTop: '2px',
                                                                                                                    fontSize: '10px',
                                                                                                                }
                                                                                                            }}
                                                                                                            style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            New/Renew
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <FormControl fullWidth className='assets_listSelect'>
                                                                            <Controller
                                                                                name="isContractNew"
                                                                                className="mb-16"
                                                                                control={control}
                                                                                render={({ field }) => {
                                                                                    return (
                                                                                        <>
                                                                                            <TextField
                                                                                                required
                                                                                                open
                                                                                                {...field}
                                                                                                variant='outlined'
                                                                                                select
                                                                                                // label="New/Renew"
                                                                                                fullWidth
                                                                                                error={!!errors.isContractNew}
                                                                                                helperText={errors?.isContractNew?.message}
                                                                                                defaultValue={defaultValues.isContractNew}
                                                                                                FormHelperTextProps={{
                                                                                                    style: {
                                                                                                        margin: 0,
                                                                                                        backgroundColor: '#f4f4f4',
                                                                                                        width: '100%',
                                                                                                        paddingTop: '2px',
                                                                                                        fontSize: '10px',
                                                                                                    }
                                                                                                }}
                                                                                                style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                            >
                                                                                                {['New', 'Renew'].map((option) => (
                                                                                                    <MenuItem key={option} value={option}>
                                                                                                        {option}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </TextField>
                                                                                        </>
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Master
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <FormControl fullWidth className='assets_listSelect'>
                                                                        <Controller
                                                                                name="isMaster"
                                                                                className="mb-16"
                                                                                control={control}
                                                                                render={({ field: { ref, ...field }, fieldState: { error } }) => {
                                                                                    return (
                                                                                        <>
                                                                                            <TextField
                                                                                                required
                                                                                                {...field}
                                                                                                variant='outlined'
                                                                                                select
                                                                                                // label="New/Renew"
                                                                                                onChange={(event) => {
                                                                                                    captureIsMasterOnchangeEvent(event)
                                                                                                    field.onChange(event)
                                                                                                }}
                                                                                                fullWidth
                                                                                                error={!!errors.isMaster}
                                                                                                helperText={errors?.isMaster?.message}
                                                                                                defaultValue={defaultValues.isMaster}
                                                                                                // inputRef={renewalInputRef}
                                                                                                FormHelperTextProps={{
                                                                                                    style: {
                                                                                                        margin: 0,
                                                                                                        backgroundColor: '#f4f4f4',
                                                                                                        width: '100%',
                                                                                                        paddingTop: '2px',
                                                                                                        fontSize: '10px',
                                                                                                    }
                                                                                                }}
                                                                                                style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                            >
                                                                                                {['Yes', 'No'].map((option) => (
                                                                                                    <MenuItem key={option} value={option}>
                                                                                                        {option}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </TextField>
                                                                                        </>
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                                
                                                                {
                                                                    getValues().isMaster === "No" &&  (
                                                                        <Grid item xs={12}>
                                                                            <Box>
                                                                                <Typography className='status_text'>
                                                                                    Parent Contract
                                                                                {/* <span className='required_span'>*</span> */}
                                                                                </Typography>
                                                                                <FormControl fullWidth className='assets_listSelect'>
                                                                                    <Controller
                                                                                        name="parentContract"
                                                                                        className="mb-16"
                                                                                        control={control}
                                                                                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                                            <>
                                                                                                <Autocomplete
                                                                                                    {...field}
                                                                                                    style={{ width: '100%' }}
                                                                                                    className='main_autocomplete'
                                                                                                    options={parentContractMenu}
                                                                                                    // disablePortal
                                                                                                    getOptionLabel={option => `${option?.entity_external_platform_id ?? ''}-${option?.contract_name}`}
                                                                                                    onChange={(event, value) => field.onChange(value)}
                                                                                                    // PaperComponent={({ children }) => (
                                                                                                    //     <Paper style={{ marginTop: '50px' }}>
                                                                                                    //         {children}
                                                                                                    //         {createCTypePermission && <Button
                                                                                                    //             style={{ width: '100%' }}
                                                                                                    //             variant="outlined"
                                                                                                    //             color="primary"
                                                                                                    //             onMouseDown={() => setContractTypeField(true)}
                                                                                                    //         >
                                                                                                    //             Add Contract Type
                                                                                                    //         </Button>}
                                                                                                    //     </Paper>
                                                                                                    // )}
                                                                                                    renderInput={params => {
                                                                                                        return (
                                                                                                            <div ref={params.InputProps.ref}>
                                                                                                                <TextField
                                                                                                                    required
                                                                                                                    {...params}
                                                                                                                    name="parentContract"
                                                                                                                    // label='Contract Type'
                                                                                                                    variant='outlined'
                                                                                                                    fullWidth
                                                                                                                    error={!!errors?.parentContract}
                                                                                                                    helperText={errors?.parentContract?.message && 'Parent Contract is required.'}
                                                                                                                    defaultValue={defaultValues.parentContract}
                                                                                                                    // inputRef={contractTypeInputRef}
                                                                                                                    FormHelperTextProps={{
                                                                                                                        style: {
                                                                                                                            margin: 0,
                                                                                                                            backgroundColor: '#f4f4f4',
                                                                                                                            width: '100%',
                                                                                                                            paddingTop: '2px',
                                                                                                                            fontSize: '10px',
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        )
                                                                                                    }}
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                    />
                                                                                </FormControl>
                                                                            </Box>
                                                                        </Grid>
                                                                    )
                                                                
                                                                }
                                                            </Grid>
                                                        </Box>
                                                    </>
                                                }
                                                {/* Service Level Agreement */}
                                                {activeStep === 2 && 
                                                    <>
                                                        <Box className='light_blue_holder'>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Service Level Agreement
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <FormControl fullWidth className='assets_listSelect'>
                                                                            <Controller
                                                                                name="sla"
                                                                                className="mb-16"
                                                                                control={control}
                                                                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                                    <>
                                                                                        <Autocomplete
                                                                                            {...field}
                                                                                            style={{ width: '100%' }}
                                                                                            className='main_autocomplete'
                                                                                            options={slaMenu}
                                                                                            // disablePortal
                                                                                            getOptionLabel={option => option.sla_name ?? ''}
                                                                                            onChange={(event, value) => field.onChange(value)}
                                                                                            PaperComponent={({ children }) => (
                                                                                                <Paper className='autocomplete_holder'>
                                                                                                    {children}
                                                                                                    <div className='p-16'>
                                                                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddServiceLevelAgreement()}>
                                                                                                            <i className='ti ti-plus' />
                                                                                                            <span>Add Service Level Agreement</span>
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Paper>
                                                                                            )}
                                                                                            renderInput={params => {
                                                                                                return (
                                                                                                    <div ref={params.InputProps.ref}>
                                                                                                        <TextField
                                                                                                            required
                                                                                                            {...params}
                                                                                                            name="sla"
                                                                                                            // label='Service Level Agreement'
                                                                                                            variant='outlined'
                                                                                                            fullWidth
                                                                                                            error={!!errors.sla}
                                                                                                            helperText={errors?.sla?.message && 'Service Level Agreement Value is required.'}
                                                                                                            // inputRef={slaInputRef}
                                                                                                            defaultValue={defaultValues.sla}
                                                                                                            FormHelperTextProps={{
                                                                                                                style: {
                                                                                                                    margin: 0,
                                                                                                                    backgroundColor: '#f4f4f4',
                                                                                                                    width: '100%',
                                                                                                                    paddingTop: '2px',
                                                                                                                    fontSize: '10px',
                                                                                                                }
                                                                                                            }}
                                                                                                            style={{ width: '100%', backgroundColor: '#fff' }}

                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                                
                                                            </Grid>
                                                        </Box>
                                                    </>
                                                }
                                                {/* Billing Cycle */}
                                                {activeStep === 3 && 
                                                    <>
                                                        <Box className='light_blue_holder'>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Amount
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <FormControl fullWidth className='assets_listSelect'>
                                                                            <Controller
                                                                                name="billingCycleAmount"
                                                                                className="mb-16"
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <TextField
                                                                                            // inputRef={paymentAmountInputRef}
                                                                                            required
                                                                                            {...field}
                                                                                            variant='outlined'
                                                                                            // label='Amount'
                                                                                            fullWidth
                                                                                            defaultValue={defaultValues.billingCycleAmount}
                                                                                            error={!!errors.billingCycleAmount}
                                                                                            helperText={errors?.billingCycleAmount?.message}
                                                                                            FormHelperTextProps={{
                                                                                                style: {
                                                                                                    margin: 0,
                                                                                                    backgroundColor: '#f4f4f4',
                                                                                                    width: '100%',
                                                                                                    paddingTop: '2px',
                                                                                                    fontSize: '10px',
                                                                                                }
                                                                                            }}
                                                                                            onBlur={()=>handleBillingAmountValueBlurEvent()}
                                                                                            onKeyPress={(event) => {
                                                                                                if (!/[0-9,.]+/.test(event.key)) {
                                                                                                    event.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                            onChange={(event) => { field.onChange(event.target.value ?? '') }}
                                                                                            style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                            InputProps={{
                                                                                                startAdornment:
                                                                                                    <InputAdornment>
                                                                                                        <Icon style={{ opacity: '0.4' }} >attach_money_outlined</Icon>
                                                                                                    </InputAdornment>
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Currency
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <FormControl fullWidth className='assets_listSelect'>
                                                                            <Controller
                                                                                name="billingCycleCurrency"
                                                                                className="mb-16"
                                                                                control={control}
                                                                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                                    <>
                                                                                        <Autocomplete
                                                                                            {...field}
                                                                                            style={{ width: '100%' }}
                                                                                            options={countries}
                                                                                            value={getValues('billingCycleCurrency')}

                                                                                            getOptionLabel={option => option.currency ?? ''}
                                                                                            onChange={(event, value) => {
                                                                                                field.onChange(value)
                                                                                            }}
                                                                                            renderOption={(option) => {
                                                                                                return (
                                                                                                    <div className='flex items-center'>
                                                                                                        <img
                                                                                                            loading="lazy"
                                                                                                            // width={"20px"}
                                                                                                            className='mr-5 py-2'
                                                                                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                                                            alt=""
                                                                                                        />
                                                                                                        <span className='pt-2'>{option.currency}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            }}
                                                                                            renderInput={params => {
                                                                                                return (
                                                                                                    <div ref={params.InputProps.ref}>
                                                                                                        <TextField
                                                                                                            {...params}
                                                                                                            variant='outlined'
                                                                                                            fullWidth
                                                                                                            // required
                                                                                                            inputRef={ref}
                                                                                                            FormHelperTextProps={{
                                                                                                                style: { marginLeft: 0 }
                                                                                                            }}
                                                                                                            error={!!errors.billingCycleCurrency}
                                                                                                            helperText={errors?.billingCycleCurrency?.message && "Currency is required."}
                                                                                                            style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                                            InputProps={{
                                                                                                                startAdornment:
                                                                                                                    <InputAdornment style={{ marginRight: '15px' }}>
                                                                                                                        <img
                                                                                                                            loading="lazy"
                                                                                                                            width="20"
                                                                                                                            src={`https://flagcdn.com/w20/${getValues('billingCycleCurrency')?.code.toLowerCase()}.png`}
                                                                                                                            srcSet={`https://flagcdn.com/w40/${getValues('billingCycleCurrency')?.code.toLowerCase()}.png 2x`}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                    </InputAdornment>
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Every 
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <FormControl fullWidth className='assets_listSelect'>
                                                                            <Controller
                                                                                name="every"
                                                                                className="mb-16"
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <TextField
                                                                                            {...field}
                                                                                            variant='outlined'
                                                                                            // label=""
                                                                                            // inputRef={billingEveryRef}
                                                                                            type='number'
                                                                                            fullWidth
                                                                                            min="0"

                                                                                            // value = {everyFielVal}
                                                                                            InputLabelProps={{ shrink: true }}
                                                                                            required
                                                                                            style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                            error={!!errors.every}
                                                                                            helperText={errors?.every?.message && "Please enter the correct value."}
                                                                                            defaultValue={defaultValues.every}
                                                                                            // formhelpertextprops={{
                                                                                            // 	style: { marginLeft: 0 }
                                                                                            // }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            />

                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Payment Term 
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <FormControl fullWidth className='assets_listSelect'>
                                                                            <Controller
                                                                                name="paymentTerm"
                                                                                className="mb-16"
                                                                                control={control}
                                                                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                                    <>
                                                                                        <Autocomplete
                                                                                            {...field}
                                                                                            style={{ width: '100%' }}
                                                                                            className='main_autocomplete'
                                                                                            // options={[{id: 1, name: '1 Month'}, {id: 1, name: '3 Months'}, {id: 1, name: '6 Months'}, {id: 1, name: '12 Months'}]}
                                                                                            options={paymentTermMenu}
                                                                                            // disablePortal
                                                                                            getOptionLabel={option => option.display_name ?? ''}
                                                                                            onChange={(event, value) => field.onChange(value)}
                                                                                            PaperComponent={({ children }) => (
                                                                                                <Paper className='autocomplete_holder'>{children}
                                                                                                    <div className='p-16'>
                                                                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddPaymentTerm()}>
                                                                                                            <i className='ti ti-plus' />
                                                                                                            <span>Add Payment Term</span>
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Paper>
                                                                                            )}
                                                                                            renderInput={params => {
                                                                                                return (
                                                                                                    <div ref={params.InputProps.ref}>
                                                                                                        <TextField
                                                                                                            required
                                                                                                            {...params}
                                                                                                            // inputRef={paymentTermInputRef}
                                                                                                            name="paymentTerm"
                                                                                                            // label='Payment Term'
                                                                                                            variant='outlined'
                                                                                                            fullWidth
                                                                                                            error={!!errors.paymentTerm}
                                                                                                            helperText={errors?.paymentTerm?.message && 'Payment Term is required!'}
                                                                                                            defaultValue={defaultValues.paymentTerm}
                                                                                                            // inputRef={ref}
                                                                                                            FormHelperTextProps={{
                                                                                                                style: {
                                                                                                                    margin: 0,
                                                                                                                    backgroundColor: '#f4f4f4',
                                                                                                                    width: '100%',
                                                                                                                    paddingTop: '2px',
                                                                                                                    fontSize: '10px',
                                                                                                                }
                                                                                                            }}
                                                                                                            style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            />      
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Box className='date_pickr_box'>
                                                                        <Typography className='status_text'>
                                                                            Billing date
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <CustomDatePickerV2
                                                                            state={billingDate}
                                                                            setState={setBillingDate}
                                                                            required={true}
                                                                            fromCustomFilter={false}
                                                                            //label='Contract Start Date*'
                                                                            // maxDate={contractEndDate.date}
                                                                            // condition='Contract End Date'									
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </>
                                                }
                                                {/* Payment Method */}
                                                {activeStep === 4 && 
                                                    <>
                                                        <Box className='light_blue_holder'>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    {/* <FormLabel id="demo-radio-buttons-group-label">Payment Method</FormLabel> */}
                                                                    <Typography className='status_text'>
                                                                        Mode of Payment
                                                                    </Typography>
                                                                    <div className='flex flex-wrap items-center'>
                                                                        <div className='inline-flex items-center pr-10'>
                                                                            <input type="radio" value="CreditCard" {...register("paymentMethod")} onClick={ () => capturePaymentMethodChangeEvent("CreditCard")}/> 
                                                                            <span className='status_text light pl-4 mb-0'>Credit Card</span>
                                                                        </div>
                                                                        <div className='inline-flex items-center'>
                                                                            <input type="radio" value="purchaseOrder" {...register("paymentMethod")} onClick={() => capturePaymentMethodChangeEvent("purchaseOrder")} /> 
                                                                            <span className='status_text light pl-4 mb-0'>Purchase Order</span>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                                {/* <pre>
                                                                    {methods.watch("paymentMethod")}
                                                                </pre> */}
                                                                {
                                                                    methods.watch("paymentMethod") === "purchaseOrder" && 
                                                                        
                                                                    <>
                                                                        <Grid item xs={12}>
                                                                            <Box>
                                                                                <Typography className='status_text'>
                                                                                    PO Number
                                                                                    <span className='required_span'>*</span>
                                                                                </Typography>
                                                                                <FormControl fullWidth className='assets_listSelect'>
                                                                                    <Controller
                                                                                        name="poNumber"
                                                                                        className="mb-16"
                                                                                        control={control}
                                                                                        render={({ field }) => (
                                                                                            <>
                                                                                                <TextField
                                                                                                    {...field}
                                                                                                    variant='outlined'
                                                                                                    // label='PO Number'
                                                                                                    fullWidth
                                                                                                    className='input-height'
                                                                                                    required
                                                                                                    // inputRef={poNumberRef}
                                                                                                    // inputProps={{ readOnly: false }}
                                                                                                    error={!!errors.poNumber}
                                                                                                    helperText={errors?.poNumber?.message}
                                                                                                    defaultValue={defaultValues.poNumber}
                                                                                                    FormHelperTextProps={{
                                                                                                        style: { marginLeft: 0 }
                                                                                                    }}
                                                                                                    style={{ backgroundColor: '#fff' }}
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                    />
                                                                                </FormControl>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Box>
                                                                                <Typography className='status_text'>
                                                                                    Payment Date
                                                                                    <span className='required_span'>*</span>
                                                                                </Typography>
                                                                                <FormControl fullWidth className='assets_listSelect'>
                                                                                <CustomDatePickerV2
                                                                                    state={paymentDate}
                                                                                    setState={setPaymentDate}
                                                                                    required={true}	
                                                                                    fromCustomFilter={false}
                                                                                    // label='PO Date*'										
                                                                                />
                                                                                </FormControl>
                                                                            </Box>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                {
                                                                    methods.watch("paymentMethod") === "CreditCard" && 
                                                                    <>
                                                                        <Grid item xs={12}>
                                                                            <Box>
                                                                                <Typography className='status_text'>
                                                                                    Card Holder's Name
                                                                                    <span className='required_span'>*</span>
                                                                                </Typography>
                                                                                <FormControl fullWidth className='assets_listSelect'>
                                                                                    <Controller
                                                                                        name="cardHolderName"
                                                                                        className="mb-16"
                                                                                        control={control}
                                                                                        render={({ field }) => (
                                                                                            <>
                                                                                                <TextField
                                                                                                    {...field}
                                                                                                    variant='outlined'
                                                                                                    // label="Card Holder's Name"
                                                                                                    fullWidth
                                                                                                    // required={ purchaseOrder === 'CreditCard' ? true : false}	
                                                                                                    // inputRef={cardHolderNameRef}
                                                                                                    inputProps={{ readOnly: false }}
                                                                                                    style={{ backgroundColor: '#fff' }}
                                                                                                    error={!!errors.cardHolderName}
                                                                                                    helperText={errors?.cardHolderName?.message}
                                                                                                    defaultValue={defaultValues.cardHolderName}
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                    />
                                                                                </FormControl>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Box>
                                                                                <Typography className='status_text'>
                                                                                    Card Number
                                                                                    <span className='required_span'>*</span>
                                                                                </Typography>
                                                                                <FormControl fullWidth className='assets_listSelect'>
                                                                                    <Controller
                                                                                        name="cardNumber"
                                                                                        className="mb-16"
                                                                                        control={control}
                                                                                        render={({ field }) => (
                                                                                            <>
                                                                                                <TextField
                                                                                                    {...field}
                                                                                                    required={ purchaseOrder === 'CreditCard' ? true : false}	
                                                                                                    variant='outlined'
                                                                                                    // onKeyPress={(event) => {
                                                                                                    //     if (!/[0-9/-]+/.test(event.key)) {
                                                                                                    //         event.preventDefault();
                                                                                                    //     }
                                                                                                    // }}
                                                                                                    label="Last 4 Digits of Credit Card"
                                                                                                    // onChange={(e)=>{
                                                                                                    //     if(e.target.value.length > 4){
                                                                                                    //         return
                                                                                                    //     }
                                                                                                    //     setCardNumber(e.target.value)
                                                                                                    // }}
                                                                                                    fullWidth
                                                                                                    inputProps={{ readOnly: false }}
                                                                                                    style={{ backgroundColor: '#fff' }}
                                                                                                    error={!!errors.cardNumber}
                                                                                                    helperText={errors?.cardNumber?.message}
                                                                                                    defaultValue={defaultValues.cardNumber}
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                    />
                                                                                </FormControl>
                                                                            </Box>
                                                                        </Grid>
                                                                    </>
                                                                }                                                            
                                                            </Grid>
                                                        </Box>
                                                    </>
                                                }
                                                {/* Service Provider */}
                                                {activeStep === 5 && 
                                                    <>
                                                        <Box className='light_blue_holder'>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Service Provider
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <FormControl fullWidth className='assets_listSelect'>
                                                                            <Controller
                                                                                name="provider"
                                                                                className="mb-16"
                                                                                control={control}
                                                                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                                    <>
                                                                                        <Autocomplete
                                                                                            {...field}
                                                                                            style={{ width: '100%' }}
                                                                                            className='main_autocomplete'
                                                                                            options={serviceProviderMenu}
                                                                                            // disablePortal
                                                                                            getOptionLabel={option => option.service_provider_name ?? ''}
                                                                                            onChange={(event, value) => field.onChange(value)}
                                                                                            PaperComponent={({ children }) => (
                                                                                                <Paper className='autocomplete_holder'>
                                                                                                    {children}
                                                                                                    <div className='p-16'>
                                                                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddServiceProvide()}>
                                                                                                            <i className='ti ti-plus' />
                                                                                                            <span>Add Service Provider</span>
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Paper>
                                                                                            )}
                                                                                            renderInput={params => {
                                                                                                return (
                                                                                                    <div ref={params.InputProps.ref}>
                                                                                                        <TextField
                                                                                                            required
                                                                                                            {...params}
                                                                                                            name="sla"
                                                                                                            // label='Service Level Agreement'
                                                                                                            variant='outlined'
                                                                                                            fullWidth
                                                                                                            error={!!errors.provider}
                                                                                                            helperText={errors?.provider?.message && 'Service Provider is required.'}
                                                                                                            // inputRef={slaInputRef}
                                                                                                            defaultValue={defaultValues.provider}
                                                                                                            FormHelperTextProps={{
                                                                                                                style: {
                                                                                                                    margin: 0,
                                                                                                                    backgroundColor: '#f4f4f4',
                                                                                                                    width: '100%',
                                                                                                                    paddingTop: '2px',
                                                                                                                    fontSize: '10px',
                                                                                                                }
                                                                                                            }}
                                                                                                            style={{ width: '100%', backgroundColor: '#fff' }}

                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                                
                                                            </Grid>
                                                        </Box>
                                                    </>
                                                }
                                            </div>
                                            <div className='btn_holder'>
                                                {activeStep !== 0 && 
                                                    <Button
                                                        color="inherit"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}
                                                        className='back_btn'
                                                    >
                                                        Back
                                                    </Button>
                                                }

                                                {activeStep !== steps.length - 1 ?
                                                    (
                                                        <Button onClick={handleNext} className='next_btn'>
                                                            Next
                                                        </Button>
                                                    ) : (
                                                        <Button onClick={handleSubmit(onSubmit)} className='next_btn'>
                                                            Proceed
                                                        </Button>
                                                    )
                                                }
                                                {/* {activeStep !== steps.length &&
                                                    (completed[activeStep] ? (
                                                        <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                            Step {activeStep + 1} already completed
                                                        </Typography>
                                                    ) : (
                                                        <Button onClick={handleComplete}>
                                                            {completedSteps() === totalSteps() - 1
                                                            ? 'Proceed'
                                                            : 'Complete Step'}
                                                        </Button>
                                                    ))
                                                } */}
                                                {/* {activeStep === steps.length - 1 &&                                            
                                                    <Button onClick={handleComplete} className='next_btn'>                                                
                                                        Proceed
                                                    </Button>                                            
                                                } */}
                                            </div>
                                        </form>
                                    </FormProvider>
                                </>
                                {/* )} */}
                            </div>
                        </Box>

                    </div>
                </Drawer>
            </div >

            {openAlerModal &&
                <AlertModal  openAlerModal module="contract" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }

            {
                openAddContractStatusModal && <AddContractStatusFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddContractStatus}
                    openAddStatusModalStateData={openAddContractStatusModal}
                    newDataAddedEvent={newContractStatusAddedEvent}
                />
            }

            {
                openAddLocationModal && <AddLocationFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddLocation}
                    openAddStatusModalStateData={openAddLocationModal}
                    newDataAddedEvent={newLocationAddedEvent}
                />
            }


            {
                openAddContractTypeModal && <AddContractTypeFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddContractType}
                    openAddStatusModalStateData={openAddContractTypeModal}
                    newDataAddedEvent={newContractTypeAddedEvent}
                />
            }


            {
                openAddServiceLevelAgreementModal && <AddServiceLevelAgreementFromQuickAction
                    handleOpenCloseAddModalEvent={handleOpenCloseAddServiceLevelAgreement}
                    openAddStatusModalStateData={openAddServiceLevelAgreementModal}
                    newDataAddedEvent={newServiceLevelAgreementAddedEvent}
                />
            }


            {
                openAddPaymentTermModal && <AddPaymentTermFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddPaymentTerm}
                    openAddStatusModalStateData={openAddPaymentTermModal}
                    newDataAddedEvent={newPaymentTermAddedEvent}
                />
                
            }


            {
                openAddServiceProviderModal && <AddServiceProviderFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddServiceProvide}
                    openAddStatusModalStateData={openAddServiceProviderModal}
                    newDataAddedEvent={newServiceProviderAddedEvent}
                />
            }

            {openPhoneVerificationModal && (
                <PhoneNumberVerificationModal
                    open={openPhoneVerificationModal}
                    close={handleCloseOpenPhoneVerificationModal}
                />
            )}
        </>
        
    )
}

export default QuickAddContract