import axios from "@fuse/utils/axios";
import { getLocalTimeFromUTC } from "@fuse/utils/dateOperations";

export async function fetchServiceRequests(){
  const res = await axios.get("/service/show-all-service-request")
  return formatServiceRequests(res.data.data.response)
}

export const colDefs = [
    {
      field: "ticket_number",
      key: "ticket_number",
      headerName: "Ticket Number"
    },
    {
      field: "serial_number",
      key: "serial_number",
      headerName: "Serial Number"
    },
    {
      field: "problem_summary",
      key: "problem_summary",
      headerName: "Problem Summary" 
    },
    {
      field: "priority",
      key: "priority",
      headerName: "Priority"
    },
    {
        field: "category_name",
        key: "category_name",
        headerName: "Category"
    },
    {
      field: "model_number",
      key: "model_number",
      headerName: "Model Number"
    },
    {
      field: "contract_Number",
      key: "contract_Number",
      headerName: "Contract Number"
    },
    {
      field: "status",
      key: "status",
      headerName: "Status"
    },
    {
      field: "agent",
      key: "agent",
      headerName: "Agent"
    },
    {
      field: "user",
      key: "user",
      headerName: "User",
      // comparator: durationComparator,
    },
    {
        field: "department",
        key: "department",
        headerName: "Department",
        // comparator: durationComparator,
    },
    {
      field: "location",
      key: "location",
      headerName: "Location"    
    },
    {
      field: "detailed_description_of_the_issue",
      key: "detailed_description_of_the_issue",
      headerName: "Detailed Description Of The Issue"
    },
    {
      field: "created_date",
      key: "created_date",
      headerName: "Created Date"   
    },
    {
      field: "updated_date",
      key: "updated_date",
      headerName: "Updated Date"
    }
]

function formatServiceRequests(data){
    return data.map((item) => {
        return {
            id: item?.id,
            ticket_number: item?.ticket_id,
            serial_number: item?.asset?.serial_number,
            problem_summary: item?.title,
            priority: item?.priority,
            model_number: item?.asset?.asset_model?.model_no,
            // model_name: item?.asset?.asset_model?.model_name,
            contract_Number: item?.asset?.contract_header?.entity_external_platform_id,
            status: item?.service_request_status?.status_name,
            category_name: item?.service_request_categories?.category_name,
            // source_name: item?.service_request_source?.source_name,
            agent: item.agent ? `${item.agent.first_name} ${item.agent.last_name}` : 'Unassigned',
            user: item.asset?.assigned_user ? `${item.asset.assigned_user.first_name} ${item.asset.assigned_user.last_name}` : 'Unassigned',
            department: item.asset?.assigned_user?.department ? item.asset?.assigned_user?.department?.department_name : 'No department',
            location: item.location ? `${item.location.location_name}-${item.location.address1}, ${item.location.city}, ${item.location.state}, ${item.location.country}` : '-',
            detailed_description_of_the_issue: item?.description,
            created_date: item.created_at ? getLocalTimeFromUTC(item.created_at) : '-',
            updated_date: item.updated_at ? getLocalTimeFromUTC(item.updated_at) : '-',
        }
    })
}