import React, { useEffect, useState } from 'react'
import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    makeStyles
} from '@material-ui/core'
import * as yup from 'yup'
// import Autocomplete from '@mui/material/Autocomplete';
import { Autocomplete } from '@material-ui/lab'
// import { Button, Icon, IconButton, makeStyles, Modal, TextField,TextareaAutosize,Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName'
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions, MenuItem } from '@mui/material'
import AddDepartmentFromQuickAction from './AddDepartmentFromQuickAction';
import Checkbox from '@mui/material/Checkbox';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import AddContractStatusFromQuickAction from './AddContractStatusFromQuickAction';
import AddLocationFromQuickAction from './AddLocationFromQuickAction';
import AddContractTypeFromQuickAction from './AddContractTypeFromQuickAction'
import AddServiceLevelAgreementFromQuickAction from './AddServiceLevelAgreementFromQuickAction';
import AddPaymentTermFromQuickAction from './AddPaymentTermFromQuickAction'
import AddServiceProviderFromQuickAction from './AddServiceProviderFromQuickAction'
import { generateProperLocationString } from '@fuse/utils/general'
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'
import { formatPhoneNumber } from '@fuse/utils/formatPhoneNumber';
import OTPInput from 'react-otp-input';
import { quickAddedContract } from '../../../main/contracts/store/reportViewDialogReducer'
import PhoneNumberVerificationModal from '@fuse/components/Modal/PhoneNumberVerificationModal';
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))



const AddContractFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {


    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)


    const [shippingLocationMenu, setShippingLocationMenu] = useState([])
	const [contractStatusMenu, setContractStatusMenu] = useState([])
	const [serviceProviderMenu, setServiceProviderMenu] = useState([])
	const [paymentTermMenu, setPaymentTermMenu] = useState([])
    const [slaMenu, setSlaMenu] = useState([])
    const [contractsMenu, setContractsMenu] = useState([])
    const [parentContractMenu, setParentContractMenu] = useState([])
    const [contractStartDate,setContractStartDate] = useState({
		date: null,
		error: false
	})

	const [contractEndDate,setContractEndDate] = useState({
		date: null,
		error: false
	})
    const [billingDate,setBillingDate] = useState({
		date: null,
		error: false
	})
    const [paymentDate,setPaymentDate] = useState({
		date: null,
		error: false
	})

    
    const [ openAddContractStatusModal, setOpenAddContractStatusModel ] = useState(false)
    const [ openAddLocationModal, setOpenAddLocationModal ] = useState(false)
    const [ openAddContractTypeModal, setOpenAddContractTypeModal ] = useState(false)
    const [ openAddServiceLevelAgreementModal, setOpenAddServiceLevelAgreementModal ] = useState(false)
    const [ openAddPaymentTermModal, setOpenAddPaymentTermModal ] = useState(false)
    const [ openAddServiceProviderModal, setOpenAddServiceProviderModal ] = useState(false)


    const [ openPhoneVerificationModal, setOpenPhoneVerificationModal ] = useState(false);
                                                                                                                    
    const defaultValues = {
        contractNumber: '',
        contractName: '',
        contractDescription: "",
        contractStatus: null,
        currentShippingLocation: null,
        totalContractValue: '',
        contractType: '',
        isContractNew: "",
        isMaster: "",
        parentContract: null,
        sla: null,
        provider: null,
        billingCycleCurrency: { "name": "United States", "code": "US", "currency": "USD" },
        billingCycleAmount: '',
        every: 1,
        paymentTerm: null,
        paymentMethod: "CreditCard",
        cardHolderName: "",
        cardNumber: "",
        poNumber: "",
        provider:null
    }


    const schema = yup.object({
        contractNumber: yup.string().trim().required('Contract Number is required!'),
        contractName: yup.string().trim().required('Contract Name is required!'), 
        contractDescription: yup.string().nullable(),
        contractStatus: yup.object().shape({
            id: yup.number().required('Tag is required.'),
            contract_status_name: yup.string('Tag is required.').required(),
        }).required('Tag is required.'),
        currentShippingLocation: yup.object().shape({
            id: yup.number().required('Location is required.'),
            entity_external_platform_id: yup.string().required('Status is required.'),
            location_name: yup.string().required('Location is required.'),
            address1: yup.string().required('Location is required.'),
            // address2: yup.string().required('Location is required.'),
            city: yup.string().required('Location is required.'),
            country: yup.string().required('Location is required.'),
            state: yup.string().required('Location is required.'),
            zip: yup.string().required('Location is required.'),
        }).required('Location is required.'),
        totalContractValue: yup.string().typeError('Invalid value').required('The Total Contract Value is required!').test('totalContractValue', 'Invalid Value', (value) => {
            return /^\d+([\,]\d+)*([\.]\d+)?$/.test(value.toString())
        }),
        contractType: yup.string().required('Contract type is required.'),
        isContractNew: yup.string().required('Please select the contract type').oneOf(['New', 'Renew'], "The field value must be one of the following values: New, Renew"),
        isMaster: yup.string().required('please select the value from option menu').oneOf(['Yes', 'No'], "The field value must be one of the following values: Yes, No"),
        parentContract: yup.object().nullable().when("isMaster", {
            is: "No",
            then: yup.object().shape({
                id: yup.string().required('Parent Contract is required.'),
                entity_external_platform_id: yup.string().required('Parent Contract is required.'),
                contract_name: yup.string().required('Parent Contract is required.')
            })
        }),
        sla: yup.object().shape({
            id: yup.string().required('Service Level Agreement is required.'),
            // entity_external_platform_id: yup.string().required('Service Level Agreement is required.'),
            sla_name: yup.string().required('Service Level Agreement is required.')
        }).required('Service Level Agreement is required.'),
        billingCycleAmount: yup.string().typeError('Invalid value').required('The Billing Cycle Amount is required!').test('billingCycleAmount', 'Invalid Value', (value) => {
            return /^\d+([\,]\d+)*([\.]\d+)?$/.test(value.toString())
        }),
        billingCycleCurrency: yup.object().shape({
            name:  yup.string().required('Currency is required.'),
            code: yup.string().required('Currency is required.'),
            currency: yup.string().required('Currency is required.')
        }),
        every: yup.number().test('billingCycleAmount', 'Invalid Value', (value) => {
            if(value < 0) {
                return false
            }
            return true
        }).required('Every Field Value is required.'),
        paymentTerm: yup.object().shape({
            id: yup.string().required('Payment Term is required.'),
            // entity_external_platform_id: yup.string().required('Payment Term is required.'),
            payment_term_name: yup.string().required('Payment Term is required.'),
            display_name: yup.string().required('Payment Term is required.')
        }).required('Payment Term is required.'),
        paymentMethod: yup.string().required('payment Method is required!'),
        cardHolderName:  yup.string().when('paymentMethod', {
            is: 'CreditCard',
            then: yup.string().required('Card Holder Name is required'),
            otherwise: yup.string().nullable(),
        }),
        cardNumber: yup.string().when('paymentMethod', {
            is: 'CreditCard',
            then: yup.string().required('Card number is required').test('cardNumber', 'Invalid Card Number Value', (cardNumberValue) => {
                if(cardNumberValue.length === 4 ) {
                    return /^[0-9]*$/.test(cardNumberValue)
                }
                return false
            }),
            otherwise: yup.string().nullable(),
        }),
        poNumber: yup.string().when('paymentMethod', {
            is: 'purchaseOrder',
            then: yup.string().required('PO number is required'),
            otherwise: yup.string().nullable(),
        }),
        provider: yup.object().shape({
            id: yup.string().required('Service Provider is required.'),
            // entity_external_platform_id: yup.string().required('Service Provider is required.'),
            service_provider_name: yup.string().required('Service Provider is required.')
        }).required('Service Provider is required.')
        
    })


    const [currentCountryCurrency, setCurrentCountryCurrency] = useState({
		"name": "United States",
		"code": "US",
		"currency": "USD"
	})    
    
    const handleCurrentCountry = (countryCode) => {
		if(countryCode !== null) {
			const current = (countries.filter(country => country.code === countryCode))[0]
			setCurrentCountryCurrency(current)
			setValue('billingCycleCurrency', current)
		} else {
			setCurrentCountryCurrency({ "name": "United States", "code": "US", "currency": "USD" })
			setValue('billingCycleCurrency', { "name": "United States", "code": "US", "currency": "USD" })
		}
		
	}

    const methods = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });


    const { 
        control, 
        formState:{errors: contractFormErrors}, 
        handleSubmit: contractFormSubmit, 
        trigger,
        reset: contractFormReset,
        clearErrors,
        setError,
        getValues,
        setValue,
        register
    } = methods


    const handleOpenCloseAddLocation = () => {
        setOpenAddLocationModal(!openAddLocationModal)
    }

    useEffect( () => {
        fetchAllSuggestion()
		fetchContractType()
		fetchParentContract()
    }, [])
    
    
    const fetchAllSuggestion = () => {
		axios.get(`contracts/get-all-suggestion`).then(response => {
			const { paymentTerm, shipping, contractStatus, sla, serviceProvider } = response.data.data
			setContractStatusMenu(contractStatus)
			setPaymentTermMenu(paymentTerm)
			setServiceProviderMenu(serviceProvider)
			setSlaMenu(sla)
			setShippingLocationMenu(shipping)
		}).catch(err => {
			console.log('err', err);
			toast.error('Something went wrong!')
		})
	}


    function fetchParentContract() {
		axios.get(`contracts/get-parent-contracts`).then(response => {
			// console.log(response.data.data)
			setParentContractMenu(response.data.data)
		}).catch(err => {
			console.log('err', err);
			toast.error('Something went wrong!')
		})
	}

    function fetchContractType() {
		axios.get(`/contracts/get-contract-types`).then(response => {
			// console.log(response.data.data)
			const check = response.data.data.map((item) => item.display_name)
			setContractsMenu(check)
		}).catch(err => {
			console.log('err', err);
			toast.error('Something went wrong!')
		})
	}


    const handleOpenCloseAddContractStatus = () => {
        setOpenAddContractStatusModel(!openAddContractStatusModal)
    }


    const handleOpenCloseAddContractType = () => {
        setOpenAddContractTypeModal(!openAddContractTypeModal)
    }


    const handleOpenCloseAddServiceLevelAgreement = () => {
        setOpenAddServiceLevelAgreementModal(!openAddServiceLevelAgreementModal)
    }


    const handleOpenCloseAddPaymentTerm = () => {
        setOpenAddPaymentTermModal(!openAddPaymentTermModal)
    }


    const handleOpenCloseAddServiceProvide = () => {
        setOpenAddServiceProviderModal(!openAddServiceProviderModal)
    }
    


    const onSubmit = async (data) => {
        // await trigger();
        // if(Object.keys(contractFormErrors).length !== 0) { 
        //     return;
        // }

        if(contractStartDate.date === null && contractEndDate.date === null) {
            toast.error('Contract start date and Contract End date are required.')
            return;
        }
        if(contractStartDate.date === null) {
            toast.error('Contract start date is required.')
            return;
        }
        if(contractEndDate.date === null) {
            toast.error('Contract end date is required.')
            return;
        }
        if(contractStartDate.error ) {
            toast.error('There is an error on Contract Start Date')
            return;
        }
        if(contractEndDate.error ) {
            toast.error('There is an error on Contract End Date')
            return;
        }
        if(billingDate.date === null) {
            toast.error('Billing date is required.')
            return;
        }
        if(methods.watch("paymentMethod") === "purchaseOrder") {
            if(paymentDate.date === null) {
                toast.error('Payment date is required.')
                return;
            }
        }

        let payload = {
			contract_id: data.contractNumber.trim(),
			contract_name: data.contractName.trim(),
			contract_description: data.contractDescription.trim(),
			contract_start_date: contractStartDate.date ?? '',
			contract_end_date: contractEndDate.date ?? '',
			contract_status: data.contractStatus,
			shipping_id: data.currentShippingLocation.id,
			total_contract_value: data.totalContractValue.replaceAll(',',''),
			// billing_id: formData.currentBillingLocation.id,
			contract_type: data.contractType,
			master: data.isMaster === "Yes" ? true : false,
			parent_contract_id: data.isMaster == "Yes" ? 0 : data.parentContract.id,
			// // notes: formData.notes,
			// // department: formData.department,
			provider: data.provider,
			sla: data.sla,
			renewal: data.isContractNew,
			// support_phone_number: reminderCheckboxes.supportContact ? formattedNumber : null,
			// // poNumber: formData.poNumber,
			// // poDate: poDate.date ?? '',
			// // country: formData.country,
		    // no_reminder: noReminderCheckBox,
			// reminder: { term45: remindMeCheckBox, term30: false },
			// textMessage: textMe,
			// paymentAmount: formData.paymentAmount?.replaceAll(',',''),
			// paymentTerm: formData.paymentTerm,
			// no_of_days: noOfDays,
			// notification_type_name:(emailMe && textMe)?'both':textMe?'sms':'email',
			// paymentAmount: data.paymentAmount.replaceAll(',',''),
			// paymentTerm: data.paymentTerm,
			billing: {
				amount: data.billingCycleAmount.replaceAll(',',''),
				location: data.billingCycleCurrency.name,
				cycle: String(data.every) || '',
				month: data.paymentTerm.name || '',
				date: billingDate.date || '',
				currency: data.billingCycleCurrency.currency,
				payment_term_id: data.paymentTerm.id
			},
			payment: {
				value: data.paymentMethod,
				po_number:  data.paymentMethod === 'purchaseOrder' ? data.poNumber !== '' ? data.poNumber : null : null,
				po_date: data.paymentMethod === 'purchaseOrder' ? paymentDate.date !== '' ?  paymentDate.date : null: null,
				// card_number:formData.cardNumber !== '' ? formData.cardNumber : null,
				card_number: data.paymentMethod === 'CreditCard' ? data.cardNumber  === '' ? null : data.cardNumber : null,
				card_holder_name: data.paymentMethod === 'CreditCard' ? data.cardHolderName !== '' ? data.cardHolderName: null : null,
			},
		}

        axios.post('/contracts/add-contract-manually', payload).then(response => {
            if (response.status == 201) {
                toast.error(response.data.message)
            } else {
                // onSuccess()
                // closeDialog()
                toast.success('Contract Added Successfully.')
                dispatch(quickAddedContract())
                newDataAddedEvent(response.data.data.created_contract)
            }

        }).catch(err => {
            toast.error(err.message)
            console.log('212=>', err);
        })
    }


    const capturePaymentMethodChangeEvent = (paymentMethodValue) => {
        if(paymentMethodValue === "CreditCard") {
            setValue('poNumber', '');
            setPaymentDate({
                date: null,
                error: false
            })
        } else {
            setValue('cardHolderName', '');
            setValue('cardNumber', '');
        }

    }


    const newContractStatusAddedEvent = (createdData) => {
        setOpenAddContractStatusModel(!openAddContractStatusModal)
        setValue('contractStatus', {
            id: createdData.id,
            contract_status_name: createdData.contract_status_name
        })
        clearErrors("contractStatus")
        fetchAllSuggestion()
        // console.log(createdData)
    }


    const newLocationAddedEvent = (createdData) => {
        setOpenAddLocationModal(!openAddLocationModal)
        setValue('currentShippingLocation',{
            entity_external_platform_id: createdData.entity_external_platform_id,
            id: createdData.id,
            location_name: createdData.location_name,
            address1: createdData.address1 || '',
            address2: createdData.address2 || '',
            city: createdData.city || '',
            state: createdData.state || '',
            country: createdData.country || '',
            zip : createdData.zip || ''
        })
        clearErrors("currentShippingLocation")
        fetchAllSuggestion()
    }


    const newContractTypeAddedEvent = (createdData) => {
        setOpenAddContractTypeModal(!openAddContractTypeModal)
        setValue('contractType', createdData)
        clearErrors("contractType")
        fetchContractType()
    }


    const newServiceLevelAgreementAddedEvent = (createdData) => {
        setOpenAddServiceLevelAgreementModal(!openAddServiceLevelAgreementModal)
        setValue('sla', {
            id: createdData.id,
            sla_name: createdData.sla_name
        })
        clearErrors("sla")
        fetchAllSuggestion()
    }


    const newPaymentTermAddedEvent = (createdData) => {
        setOpenAddPaymentTermModal(!openAddPaymentTermModal)
        setValue("paymentTerm", {
            id: createdData.id,
            payment_term_name: createdData.display_name,
            display_name: createdData.display_name,
        })
        clearErrors("paymentTerm")
        fetchAllSuggestion()
    }


    const newServiceProviderAddedEvent = (createdData) => {
        setOpenAddServiceProviderModal(!openAddServiceProviderModal)
        setValue('provider', {
            id: createdData.id,
            service_provider_name: createdData.service_provider_name
        })
        clearErrors("provider")
        fetchAllSuggestion()
    }

    const handleTotalContractValueBlurEvent = () => {
        if(getValues('totalContractValue') === "") {
            setError('totalContractValue',{
                message: "Total Contract Value is required!",
            })
            setValue('totalContractValue','')
            // console.log("messi")
            // console.log(errors)
        } else {
            if(!isNaN(getValues('totalContractValue'))) {
                clearErrors('totalContractValue', '')
                setValue('totalContractValue',formatPriceFieldOnBlur(getValues('totalContractValue')))
            } else {
                if(/\d/.test(getValues('totalContractValue'))) {
                    clearErrors('totalContractValue', '')
                    setValue('totalContractValue',formatPriceFieldOnBlur(getValues('totalContractValue')))
                } else {
                    setError('totalContractValue',{
                        message: "Total Contract Value is required!",
                    })
                    setValue('totalContractValue','')
                }   
            }
        }
    }


    const handleBillingAmountValueBlurEvent = () => {
        if(!isNaN(getValues('billingCycleAmount'))) {
            clearErrors('billingCycleAmount', '')
            setValue('billingCycleAmount',formatPriceFieldOnBlur(getValues('billingCycleAmount')))
        } else {
            if(/\d/.test(getValues('billingCycleAmount'))) {
                clearErrors('billingCycleAmount', '')
                setValue('billingCycleAmount',formatPriceFieldOnBlur(getValues('billingCycleAmount')))
            } else {
                setError('billingCycleAmount', 'Billing Amount is required!')
                setValue('billingCycleAmount','')
            }   
        }	
    }

    return (
        <>
            <Dialog
                className="cstm_dialog_modal medium text-center"
                open={openAddStatusModalStateData}
                // onClose={handleOpenCloseAddModalEvent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogTitle id="alert-dialog-title">
                    <div className='heading_holder flexbox_holder items-center w-full'>
                        <h2 className="main_heading">Add Contract</h2>
                        <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                            <i className="icon ti ti-x" />
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="text-left">
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Contract Number
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name='contractNumber' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        style={{background: 'white'}}
                                        variant='outlined'
                                        // label='First Name' 
                                        autoFocus
                                        fullWidth	
                                        required
                                        error={!!contractFormErrors.contractNumber}
                                        helperText={contractFormErrors?.contractNumber?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </div>



                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Contract Name
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name='contractName' 
                                control={control}
                                render={( { field} ) => (
                                    <TextField 
                                        {...field}
                                        style={{background: 'white'}}
                                        variant='outlined'
                                        // label='First Name' 
                                        autoFocus
                                        fullWidth	
                                        required
                                        error={!!contractFormErrors.contractName}
                                        helperText={contractFormErrors?.contractName?.message}
                                        FormHelperTextProps={{
                                            style: { 
                                                margin : 0, 
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                fontSize: '10px'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </div>


                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Contract Description
                            </Typography>
                            <Controller 
                                name="contractDescription"
                                className='serial_numberField'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        style={{ background: 'white' }}
                                        variant='outlined'
                                        defaultValue={defaultValues.contractDescription}
                                        error={!!contractFormErrors.contractDescription}
                                        helperText={contractFormErrors?.contractDescription?.message}
                                        fullWidth
                                        FormHelperTextProps={{
                                            style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                border: '1px solid #F2F4F7'
                                            }
                                        }} 
                                    />
                                )}
                            />
                        </div>


                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Contract Start Date
                                <span className='required_span'>*</span>
                            </Typography>
                            <CustomDatePickerV2
                                state={contractStartDate}
                                setState={setContractStartDate}
                                required={true}
                                fromCustomFilter={false}
                                //label='Contract Start Date*'
                                maxDate={contractEndDate.date}
                                condition='Contract End Date'									
                            />

                        </div>



                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Contract End Date
                                <span className='required_span'>*</span>
                            </Typography>
                            <CustomDatePickerV2
                                state={contractEndDate}
                                setState={setContractEndDate}
                                required={true}
                                fromCustomFilter={false}
                                //label='Contract End Date*'
                                minDate={contractStartDate.date}
                                condition='Contract Start Date'										
                            />

                        </div>

                        <div className='mb-10 relative'>
                            <Typography className='status_text'>
                                Tag
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="contractStatus"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={contractStatusMenu}
                                            disablePortal
                                            onChange={(event, value) => { field.onChange(value) }}
                                            PaperComponent={({ children }) => (
                                                <Paper className="autocomplete_holder">{children}
                                                    <div className='p-16'>
                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddContractStatus()}>
                                                            <i className='ti ti-plus' />
                                                            <span>Add Contract Tag</span>
                                                        </Button>
                                                    </div>
                                                </Paper>
                                            )}
                                            getOptionLabel={option => option.contract_status_name ?? ''}
                                            renderInput={params => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            required
                                                            {...params}
                                                            name="contractStatus"
                                                            // label='Contract Status'
                                                            variant='outlined'
                                                            fullWidth
                                                            error={!!contractFormErrors?.contractStatus}
                                                            helperText={contractFormErrors?.contractStatus?.message && "Contract Tag is required!"}
                                                            defaultValue={defaultValues.contractStatus}
                                                            inputRef={ref}
                                                            FormHelperTextProps={{
                                                                style: {
                                                                    margin: 0,
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px',
                                                                    fontSize: '10px',
                                                                }
                                                            }}
                                                            style={{ width: '100%', backgroundColor: '#fff' }}
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>



                        <div className='mb-10 relative'>
                            <Typography className='status_text'>
                                Location
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="currentShippingLocation"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={shippingLocationMenu}
                                            disablePortal
                                            onChange={(event, value) => field.onChange(value)}
                                            PaperComponent={({ children }) => (
                                                <Paper className="autocomplete_holder">{children}
                                                    <div className='p-16'>
                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddLocation()}>
                                                            <i className='ti ti-plus' />
                                                            <span>Add Location</span>
                                                        </Button>
                                                    </div>
                                                </Paper>
                                            )}
                                            getOptionLabel={
                                                option => {
                                                    return generateProperLocationString({
                                                        location_name: option?.location_name ?? '',
                                                        address1: option?.address1 ?? '',
                                                        address2: option?.address2 ?? '',
                                                        city: option?.city ?? '',
                                                        state: option?.state ?? '',
                                                        country: option?.country ?? ''
                                                    })
                                                } 
                                            }
                                            renderInput={(params) => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            {...params}
                                                            style={{background: 'white'}}
                                                            // label="Location"
                                                            required
                                                            defaultValue={defaultValues.currentShippingLocation}
                                                            error={!!contractFormErrors?.currentShippingLocation}
                                                            helperText={contractFormErrors?.currentShippingLocation?.message && 'Location is required!'}
                                                            FormHelperTextProps={{
                                                                style: { 
                                                                    margin : 0, 
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px',
                                                                    fontSize: '10px'
                                                                }
                                                            }}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                                    
                            />
                        </div>


                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Total Contract Value
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller 
                                name="totalContractValue"
                                className='serial_numberField'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        style={{ background: 'white' }}
                                        variant='outlined'
                                        defaultValue={defaultValues.totalContractValue}
                                        error={!!contractFormErrors.totalContractValue}
                                        helperText={contractFormErrors?.totalContractValue?.message}
                                        fullWidth
                                        onBlur={()=>handleTotalContractValueBlurEvent()}
                                        onKeyPress={(event) => {
                                            if (!/[0-9,.]+/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            style: {
                                                margin: 0,
                                                backgroundColor: '#f4f4f4',
                                                width: '100%',
                                                paddingTop: '2px',
                                                border: '1px solid #F2F4F7',
                                                fontSize:'10px'
                                            }
                                        }} 
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment>
                                                    <Icon style={{ opacity: '0.4' }} >attach_money_outlined</Icon>
                                                </InputAdornment>
                                        }}
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-10 relative'>
                            <Typography className='status_text'>
                                Contract Type
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="contractType"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                <>
                                    <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={contractsMenu}
                                            disablePortal
                                            // getOptionLabel={option => option.display_name }
                                            onChange={(event, value) => field.onChange(value)}
                                            PaperComponent={({ children }) => (
                                                <Paper className="autocomplete_holder">
                                                    {children}
                                                    <div className='p-16'>
                                                        <Button
                                                            className='secondary_btn w-full'
                                                            variant="outlined"
                                                            color="primary"
                                                            onMouseDown={() => handleOpenCloseAddContractType()}
                                                        >Add Contract Type</Button>
                                                    </div>
                                                </Paper>
                                            )}
                                            renderInput={params => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            required
                                                            {...params}
                                                            name="contractType"
                                                            // label='Contract Type'
                                                            variant='outlined'
                                                            fullWidth
                                                            error={!!contractFormErrors?.contractType}
                                                            helperText={contractFormErrors?.contractType?.message && "Contract Type is required!"}
                                                            defaultValue={defaultValues.contractType}
                                                            // inputRef={contractTypeInputRef}
                                                            FormHelperTextProps={{
                                                                style: {
                                                                    margin: 0,
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px',
                                                                    fontSize: '10px'
                                                                }
                                                            }}
                                                            style={{ width: '100%', backgroundColor: '#fff' }}
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            />

                        </div>
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                New/Renew
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="isContractNew"
                                className="mb-16"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <>
                                            <TextField
                                                required
                                                {...field}
                                                variant='outlined'
                                                select
                                                // label="New/Renew"
                                                fullWidth
                                                error={!!contractFormErrors.isContractNew}
                                                helperText={contractFormErrors?.isContractNew?.message}
                                                defaultValue={defaultValues.isContractNew}
                                                FormHelperTextProps={{
                                                    style: {
                                                        margin: 0,
                                                        backgroundColor: '#f4f4f4',
                                                        width: '100%',
                                                        paddingTop: '2px',
                                                        fontSize: '10px'
                                                    }
                                                }}
                                                style={{ width: '100%', backgroundColor: '#fff' }}
                                            >
                                                {['New', 'Renew'].map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </>
                                    )
                                }}
                            />
                        </div>


                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Master
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="isMaster"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => {
                                    return (
                                        <>
                                            <TextField
                                                required
                                                {...field}
                                                variant='outlined'
                                                select
                                                // label="New/Renew"
                                                onChange={(event) => {
                                                    // captureIsMasterOnchangeEvent(event)
                                                    field.onChange(event)
                                                }}
                                                fullWidth
                                                error={!!contractFormErrors.isMaster}
                                                helperText={contractFormErrors?.isMaster?.message}
                                                defaultValue={defaultValues.isMaster}
                                                // inputRef={renewalInputRef}
                                                FormHelperTextProps={{
                                                    style: {
                                                        margin: 0,
                                                        backgroundColor: '#f4f4f4',
                                                        width: '100%',
                                                        paddingTop: '2px',
                                                        fontSize:'10px'
                                                    }
                                                }}
                                                style={{ width: '100%', backgroundColor: '#fff' }}
                                            >
                                                {['Yes', 'No'].map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </>
                                    )
                                }}
                            />
                        </div>

                        {
                            methods.watch("isMaster") === "No" &&  (

                                <div className='mb-10 relative'>
                                    <Typography className='status_text'>
                                        Parent Contract
                                        <span className='required_span'>*</span>
                                    </Typography>
                                    <Controller
                                        name="parentContract"
                                        className="mb-16"
                                        control={control}
                                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                            <>
                                                <Autocomplete
                                                    {...field}
                                                    style={{ width: '100%' }}
                                                    options={parentContractMenu}
                                                    disablePortal
                                                    getOptionLabel={option => `${option?.entity_external_platform_id ?? ''}-${option?.contract_name}`}
                                                    onChange={(event, value) => field.onChange(value)}
                                                    renderInput={params => {
                                                        return (
                                                            <div ref={params.InputProps.ref}>
                                                                <TextField
                                                                    required
                                                                    {...params}
                                                                    name="parentContract"
                                                                    // label='Contract Type'
                                                                    variant='outlined'
                                                                    fullWidth
                                                                    error={!!contractFormErrors?.parentContract}
                                                                    helperText={contractFormErrors?.parentContract?.message && 'Parent Contract is required.'}
                                                                    defaultValue={defaultValues.parentContract}
                                                                    // inputRef={contractTypeInputRef}
                                                                    FormHelperTextProps={{
                                                                        style: {
                                                                            margin: 0,
                                                                            backgroundColor: '#f4f4f4',
                                                                            width: '100%',
                                                                            paddingTop: '2px',
                                                                            fontSize: '10px'
                                                                        }
                                                                    }}
                                                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                                                />
                                                            </div>
                                                        )
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                            )
                        }
                        


                        <div className='mb-10 relative'>
                            <Typography className='status_text'>
                                Service Level Agreement
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="sla"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={slaMenu}
                                            disablePortal
                                            getOptionLabel={option => option.sla_name ?? ''}
                                            onChange={(event, value) => field.onChange(value)}
                                            PaperComponent={({ children }) => (
                                                <Paper className="autocomplete_holder">
                                                    {children}
                                                    <div className='p-16'>
                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddServiceLevelAgreement()}>
                                                            <i className='ti ti-plus' />
                                                            <span>Add Service Level Agreement</span>
                                                        </Button>
                                                    </div>
                                                </Paper>
                                            )}
                                            renderInput={params => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            required
                                                            {...params}
                                                            name="sla"
                                                            // label='Service Level Agreement'
                                                            variant='outlined'
                                                            fullWidth
                                                            error={!!contractFormErrors.sla}
                                                            helperText={contractFormErrors?.sla?.message && 'Service Level Agreement Value is required.'}
                                                            // inputRef={slaInputRef}
                                                            defaultValue={defaultValues.sla}
                                                            FormHelperTextProps={{
                                                                style: {
                                                                    margin: 0,
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px',
                                                                    fonstSize: '10px'
                                                                }
                                                            }}
                                                            style={{ width: '100%', backgroundColor: '#fff' }}

                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>



                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Amount
                                <span className='required_span'>*</span>
                            </Typography> 
                            <Controller
                                name="billingCycleAmount"
                                className="mb-16"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            // inputRef={paymentAmountInputRef}
                                            required
                                            {...field}
                                            variant='outlined'
                                            // label='Amount'
                                            fullWidth
                                            defaultValue={defaultValues.billingCycleAmount}
                                            error={!!contractFormErrors.billingCycleAmount}
                                            helperText={contractFormErrors?.billingCycleAmount?.message}
                                            FormHelperTextProps={{
                                                style: {
                                                    margin: 0,
                                                    backgroundColor: '#f4f4f4',
                                                    width: '100%',
                                                    paddingTop: '2px',
                                                    fontSize:'10px'
                                                }
                                            }}
                                            onBlur={()=>handleBillingAmountValueBlurEvent()}
                                            onKeyPress={(event) => {
                                                if (!/[0-9,.]+/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => { field.onChange(event.target.value ?? '') }}
                                            style={{ width: '100%', backgroundColor: '#fff' }}
                                            InputProps={{
                                                startAdornment:
                                                    <InputAdornment>
                                                        <Icon style={{ opacity: '0.4' }} >attach_money_outlined</Icon>
                                                    </InputAdornment>
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Currency
                                <span className='required_span'>*</span>
                            </Typography>  
                            <Controller
                                name="billingCycleCurrency"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={countries}
                                            value={getValues('billingCycleCurrency')}
                                            getOptionLabel={option => option.currency ?? ''}
                                            onChange={(event, value) => {
                                                field.onChange(value)
                                            }}
                                            renderOption={(option) => {
                                                return (
                                                    <div className='flex items-center'>
                                                        <img
                                                            loading="lazy"
                                                            // width={"20px"}
                                                            className='mr-5 py-2'
                                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                            alt=""
                                                        />
                                                        <span className='pt-2'>{option.currency}</span>
                                                    </div>
                                                )
                                            }}
                                            renderInput={params => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            {...params}
                                                            variant='outlined'
                                                            fullWidth
                                                            // required
                                                            inputRef={ref}
                                                            FormHelperTextProps={{
                                                                style: { marginLeft: 0 }
                                                            }}
                                                            error={!!contractFormErrors.billingCycleCurrency}
                                                            helperText={contractFormErrors?.billingCycleCurrency?.message && "Currency is required."}
                                                            style={{ width: '100%', backgroundColor: '#fff' }}
                                                            InputProps={{
                                                                startAdornment:
                                                                    <InputAdornment style={{ marginRight: '15px' }}>
                                                                        <img
                                                                            loading="lazy"
                                                                            width="20"
                                                                            src={`https://flagcdn.com/w20/${getValues('billingCycleCurrency')?.code.toLowerCase()}.png`}
                                                                            srcSet={`https://flagcdn.com/w40/${getValues('billingCycleCurrency')?.code.toLowerCase()}.png 2x`}
                                                                            alt=""
                                                                        />
                                                                    </InputAdornment>
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>



                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Every 
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="every"
                                className="mb-16"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            variant='outlined'
                                            // label=""
                                            // inputRef={billingEveryRef}
                                            type='number'
                                            fullWidth
                                            min="0"
                                            // onChange={(e) => {
                                            // 	let val = parseInt(e.target.value, 10);
                                            // 	if (isNaN(val)) {
                                            // 	  setEveryFielVal('');
                                            // 	} else {
                                            // 	  // is A Number
                                            // 	  val = val >= 0 ? val : 0;
                                            // 	  setEveryFielVal(val);
                                            // 	}
                                            //   }}
                                            // value = {everyFielVal}
                                            InputLabelProps={{ shrink: true }}
                                            required
                                            style={{ width: '100%', backgroundColor: '#fff' }}
                                            error={!!contractFormErrors.every}
                                            helperText={contractFormErrors?.every?.message && "Please enter the correct value"}
                                            defaultValue={defaultValues.every}
                                            // formhelpertextprops={{
                                            // 	style: { marginLeft: 0 }
                                            // }}
                                        />
                                    </>
                                )}
                            />
                        </div>



                        <div className='mb-10 relative'>
                            <Typography className='status_text'>
                                Payment Term 
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="paymentTerm"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            // options={[{id: 1, name: '1 Month'}, {id: 1, name: '3 Months'}, {id: 1, name: '6 Months'}, {id: 1, name: '12 Months'}]}
                                            options={paymentTermMenu}
                                            disablePortal
                                            getOptionLabel={option => option.display_name ?? ''}
                                            onChange={(event, value) => field.onChange(value)}
                                            PaperComponent={({ children }) => (
                                                <Paper className="autocomplete_holder">{children}
                                                    <div className='p-16'>
                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddPaymentTerm()}>
                                                            <i className='ti ti-plus' />
                                                            <span>Add Payment Term</span>
                                                        </Button>
                                                    </div>
                                                </Paper>
                                            )}
                                            renderInput={params => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            required
                                                            {...params}
                                                            // inputRef={paymentTermInputRef}
                                                            name="paymentTerm"
                                                            // label='Payment Term'
                                                            variant='outlined'
                                                            fullWidth
                                                            error={!!contractFormErrors.paymentTerm}
                                                            helperText={contractFormErrors?.paymentTerm?.message && 'Payment Term is required!'}
                                                            defaultValue={defaultValues.paymentTerm}
                                                            // inputRef={ref}
                                                            FormHelperTextProps={{
                                                                style: {
                                                                    margin: 0,
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px',
                                                                    fontSize:'10px'
                                                                }
                                                            }}
                                                            style={{ width: '100%', backgroundColor: '#fff' }}
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            />     
                        </div>



                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Billing date
                                <span className='required_span'>*</span>
                            </Typography>
                            <CustomDatePickerV2
                                state={billingDate}
                                setState={setBillingDate}
                                required={true}
                                fromCustomFilter={false}
                                //label='Contract Start Date*'
                                // maxDate={contractEndDate.date}
                                // condition='Contract End Date'									
                            />
                        </div>


                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Mode of Payment
                                <span className='required_span'>*</span>
                            </Typography>
                            <div className='flex flex-wrap items-center'>
                                <div className='inline-flex items-center pr-10'>
                                    <input type="radio" value="CreditCard" {...register("paymentMethod")} onClick={ () => capturePaymentMethodChangeEvent("CreditCard")}/> 
                                    <span className='status_text light pl-4 mb-0'>Credit Card</span>
                                </div>
                                <div className='inline-flex items-center'>
                                    <input type="radio" value="purchaseOrder" {...register("paymentMethod")} onClick={() => capturePaymentMethodChangeEvent("purchaseOrder")} /> 
                                    <span className='status_text light pl-4 mb-0'>Purchase Order</span>
                                </div>
                            </div>
                        </div>


                        {
                            methods.watch("paymentMethod") === "purchaseOrder" && 

                            <>
                                <div className='mb-10'>
                                    <Typography className='status_text'>
                                        PO Number
                                        <span className='required_span'>*</span>
                                    </Typography>
                                    <Controller
                                        name="poNumber"
                                        className="mb-16"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    variant='outlined'
                                                    // label='PO Number'
                                                    fullWidth
                                                    className='input-height'
                                                    required
                                                    // inputRef={poNumberRef}
                                                    // inputProps={{ readOnly: false }}
                                                    error={!!contractFormErrors.poNumber}
                                                    helperText={contractFormErrors?.poNumber?.message}
                                                    defaultValue={defaultValues.poNumber}
                                                    FormHelperTextProps={{
                                                        style: {
                                                            margin: 0,
                                                            backgroundColor: '#f4f4f4',
                                                            width: '100%',
                                                            paddingTop: '2px',
                                                            fontSize:'10px'
                                                        }
                                                    }}
                                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                                />
                                            </>
                                        )}
                                    />
                                </div>

                                <div className='mb-10'>
                                    <Typography className='status_text'>
                                        Payment Date
                                        <span className='required_span'>*</span>
                                    </Typography>
                                    <CustomDatePickerV2
                                        state={paymentDate}
                                        setState={setPaymentDate}
                                        required={true}	
                                        fromCustomFilter={false}
                                        // label='PO Date*'										
                                    />
                                </div>
                            </>
                        }



                        {
                            methods.watch("paymentMethod") === "CreditCard" && 
                            <>
                                <div className='mb-10'>
                                    <Typography className='status_text'>
                                        Card Holder's Name
                                        <span className='required_span'>*</span>
                                    </Typography>
                                    <Controller
                                        name="cardHolderName"
                                        className="mb-16"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    variant='outlined'
                                                    // label="Card Holder's Name"
                                                    fullWidth
                                                    // required={ purchaseOrder === 'CreditCard' ? true : false}	
                                                    // inputRef={cardHolderNameRef}
                                                    inputProps={{ readOnly: false }}
                                                    FormHelperTextProps={{
                                                        style: {
                                                            margin: 0,
                                                            backgroundColor: '#f4f4f4',
                                                            width: '100%',
                                                            paddingTop: '2px',
                                                            fontSize:'10px'
                                                        }
                                                    }}
                                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                                    error={!!contractFormErrors.cardHolderName}
                                                    helperText={contractFormErrors?.cardHolderName?.message}
                                                    defaultValue={defaultValues.cardHolderName}
                                                />
                                            </>
                                        )}
                                    />
                                </div>

                                <div className='mb-10'>
                                    <Typography className='status_text'>
                                        Last 4 Digits of Credit Card
                                        <span className='required_span'>*</span>
                                    </Typography>
                                    <Controller
                                        name="cardNumber"
                                        className="mb-16"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    // required={ purchaseOrder === 'CreditCard' ? true : false}	
                                                    variant='outlined'
                                                    // onKeyPress={(event) => {
                                                    //     if (!/[0-9/-]+/.test(event.key)) {
                                                    //         event.preventDefault();
                                                    //     }
                                                    // }}
                                                    // label="Last 4 Digits of Credit Card"
                                                    // onChange={(e)=>{
                                                    //     if(e.target.value.length > 4){
                                                    //         return
                                                    //     }
                                                    //     setCardNumber(e.target.value)
                                                    // }}
                                                    fullWidth
                                                    inputProps={{ readOnly: false }}
                                                    FormHelperTextProps={{
                                                        style: {
                                                            margin: 0,
                                                            backgroundColor: '#f4f4f4',
                                                            width: '100%',
                                                            paddingTop: '2px',
                                                            fontSize:'10px'
                                                        }
                                                    }}
                                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                                    error={!!contractFormErrors.cardNumber}
                                                    helperText={contractFormErrors?.cardNumber?.message}
                                                    defaultValue={defaultValues.cardNumber}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                            </>
                        }


                        <div className='mb-10 relative'>
                            <Typography className='status_text'>
                                Service Provider
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="provider"
                                className="mb-16"
                                control={control}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={serviceProviderMenu}
                                            disablePortal
                                            getOptionLabel={option => option.service_provider_name ?? ''}
                                            onChange={(event, value) => field.onChange(value)}
                                            PaperComponent={({ children }) => (
                                                <Paper className="autocomplete_holder">
                                                    {children}
                                                    <div className='p-16'>
                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddServiceProvide()}>
                                                            <i className='ti ti-plus' />
                                                            <span>Add Service Provider</span>
                                                        </Button>
                                                    </div>
                                                </Paper>
                                            )}
                                            renderInput={params => {
                                                return (
                                                    <div ref={params.InputProps.ref}>
                                                        <TextField
                                                            required
                                                            {...params}
                                                            name="sla"
                                                            // label='Service Level Agreement'
                                                            variant='outlined'
                                                            fullWidth
                                                            error={!!contractFormErrors.provider}
                                                            helperText={contractFormErrors?.provider?.message && 'Service Provider is required.'}
                                                            // inputRef={slaInputRef}
                                                            defaultValue={defaultValues.provider}
                                                            FormHelperTextProps={{
                                                                style: {
                                                                    margin: 0,
                                                                    backgroundColor: '#f4f4f4',
                                                                    width: '100%',
                                                                    paddingTop: '2px',
                                                                    fontSize:'10px'
                                                                }
                                                            }}
                                                            style={{ width: '100%', backgroundColor: '#fff' }}

                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>


                    </div>
                </DialogContent>
                <DialogActions className="action_footer_holder">
                    <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                    <Button onClick={contractFormSubmit(onSubmit)} className="primary_btn">
                        Save and Select
                    </Button>
                </DialogActions>
            </Dialog>


            {
                openAddContractStatusModal && <AddContractStatusFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddContractStatus}
                    openAddStatusModalStateData={openAddContractStatusModal}
                    newDataAddedEvent={newContractStatusAddedEvent}
                />
            }

            {
                openAddLocationModal && <AddLocationFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddLocation}
                    openAddStatusModalStateData={openAddLocationModal}
                    newDataAddedEvent={newLocationAddedEvent}
                />
            }


            {
                openAddContractTypeModal && <AddContractTypeFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddContractType}
                    openAddStatusModalStateData={openAddContractTypeModal}
                    newDataAddedEvent={newContractTypeAddedEvent}
                />
            }


            {
                openAddServiceLevelAgreementModal && <AddServiceLevelAgreementFromQuickAction
                    handleOpenCloseAddModalEvent={handleOpenCloseAddServiceLevelAgreement}
                    openAddStatusModalStateData={openAddServiceLevelAgreementModal}
                    newDataAddedEvent={newServiceLevelAgreementAddedEvent}
                />
            }


            {
                openAddPaymentTermModal && <AddPaymentTermFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddPaymentTerm}
                    openAddStatusModalStateData={openAddPaymentTermModal}
                    newDataAddedEvent={newPaymentTermAddedEvent}
                />
                
            }


            {
                openAddServiceProviderModal && <AddServiceProviderFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddServiceProvide}
                    openAddStatusModalStateData={openAddServiceProviderModal}
                    newDataAddedEvent={newServiceProviderAddedEvent}
                />
            }

        </>
    )
}


export default AddContractFromQuickAction;