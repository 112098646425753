import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import auth0Service from 'app/services/auth0Service';
import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import axiosgeneral from "@fuse/utils/axios";
import { token } from '@fuse/utils/axios';
import React from 'react'

import { setUserDataFirebase, setUserDataAuth0, setUserData, logoutUser } from './store/userSlice';

class Auth extends Component {
	state = {
		waitAuthCheck: true
	};

	componentDidMount() {	


		window.addEventListener('storage', (event) => {
			if(event.key === 'LOGIN') {
				return Promise.all([
					// Comment the lines which you do not use
					// this.firebaseCheck(),
					// this.auth0Check(),
					this.jwtCheck()
				]).then(() => {
					this.setState({ waitAuthCheck: false });
				});
			}
			if(event.key === 'LOGOUT') {
				this.props.setUserData({
					role: [], // guest
					data: {
						displayName: 'John Doe',
						photoURL: 'assets/images/avatars/Velazquez.jpg',
						email: 'johndoe@withinpixels.com',
						shortcuts: ['calendar', 'mail', 'contacts', 'todo']
					}
				});
				delete axiosgeneral.defaults.headers.common.Authorization;
			}
			// if(event.key === 'CREDENTIALS_FLUSH' && credentials){
			// 	axiosgeneral.defaults.headers.common.Authorization = '';
			// 	this.props.logout();
			// 	this.props.setUserData({});
			// }
			
		  })
		//   window.localStorage.setItem('REQUESTING_SHARED_CREDENTIALS', Date.now().toString())
		//   window.localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS');
		if(this.props.user.data.token){
			axiosgeneral.defaults.headers.common.Authorization = `Bearer ${this.props.user.data.token}`;
			token.token = this.props.user.data.token;
		}
		return Promise.all([
			// Comment the lines which you do not use
			// this.firebaseCheck(),
			// this.auth0Check(),
			this.jwtCheck()
		]).then(() => {
			this.setState({ waitAuthCheck: false });
		});
	}

	jwtCheck = () =>
		new Promise(resolve => {
			jwtService.on('onAutoLogin', () => {
				// this.props.showMessage({ message: 'Logged in successfully!',autoHideDuration: 6000,//ms
				// anchorOrigin: {
				// 	vertical  : 'bottom',//top bottom
				// 	horizontal: 'right'//left center right
				// },
				// variant: 'success' });

				/**
				 * Sign in and retrieve user data from Api
				 */
				jwtService
					.signInWithToken()
					.then(user => {
						this.props.setUserData(user);
						resolve();

						// this.props.showMessage({ message: 'Logged in successfully!' ,
						// autoHideDuration: 6000,//ms
						// anchorOrigin: {
						// 	vertical  : 'bottom',//top bottom
						// 	horizontal: 'right'//left center right
						// },
						// variant: 'success'});
					})
					.catch(error => {
						this.props.showMessage({ message: 'Logged out',
						autoHideDuration: 6000,//ms
						anchorOrigin: {
							vertical  : 'bottom',//top bottom
							horizontal: 'right'//left center right
						},
						variant: 'error' });

						resolve();
					});
			});

			jwtService.on('onAutoLogout', message => {
				if (message) {
					this.props.showMessage({ message: 'Logged out',autoHideDuration: 6000,//ms
					anchorOrigin: {
						vertical  : 'bottom',//top bottom
						horizontal: 'right'//left center right
					},
					variant: 'Error' });
				}

				this.props.logout(true);

				resolve();
			});

			jwtService.on('onNoAccessToken', () => {
				resolve();
			});

			jwtService.init();

			return Promise.resolve();
		});

	auth0Check = () =>
		new Promise(resolve => {
			auth0Service.init(success => {
				if (!success) {
					resolve();
				}
			});

			if (auth0Service.isAuthenticated()) {
				this.props.showMessage({ message: 'Logging in with Auth0' });

				/**
				 * Retrieve user data from Auth0
				 */
				auth0Service.getUserData().then(tokenData => {
					this.props.setUserDataAuth0(tokenData);

					resolve();

					this.props.showMessage({ message: 'Logged in with Auth0' });
				});
			} else {
				resolve();
			}

			return Promise.resolve();
		});

	firebaseCheck = () =>
		new Promise(resolve => {
			firebaseService.init(success => {
				if (!success) {
					resolve();
				}
			});

			firebaseService.onAuthStateChanged(authUser => {
				if (authUser) {
					this.props.showMessage({ message: 'Logging in with Firebase' });

					/**
					 * Retrieve user data from Firebase
					 */
					firebaseService.getUserData(authUser.uid).then(
						user => {
							this.props.setUserDataFirebase(user, authUser);

							resolve();

							this.props.showMessage({ message: 'Logged in with Firebase' });
						},
						error => {
							resolve();
						}
					);
				} else {
					resolve();
				}
			});

			return Promise.resolve();
		});

	render() {
		return ( 
			this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: state.auth.user,
		loggingIn: state.auth.login.loggingIn
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: logoutUser,
			setUserData,
			setUserDataAuth0,
			setUserDataFirebase,
			showMessage,
			hideMessage,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
