import { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box, Icon } from '@mui/material'
import axios from '@fuse/utils/axios'
import { useRouter } from '@fuse/hooks';
// import { FileDelta, CurrentLocation, Ticket } from 'tabler-icons-react';
import { IconFile, IconFilePower, IconFileAlert, IconFileCheck } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux'
import { setCustomFilterData } from 'app/main/contracts/store/filterParamsReducer';

function DashboardCard(props) {
	const router = useRouter();
	const dispatch = useDispatch();
	const [ contractMetrics, setContractMetrics ] = useState({
		total_contract_count: 0,
		total_active_contract_count: 0,
		total_expiring_contract_count: 0,
		total_expired_contract_count: 0  
	});

	function handleOnClickRedirection( pathName ){
		router.push(pathName)
	}

	function getAnalytics(){
		axios.get('/dashboard/dashboard-contract-insights').then((res)=>{
			const {
				total_contract_count,
				total_active_contract_count,
				total_expiring_contract_count,
				total_expired_contract_count
			} = res.data.data;
			setContractMetrics({
				...contractMetrics,
				total_contract_count,
				total_active_contract_count,
				total_expiring_contract_count,
				total_expired_contract_count
			})
		}).catch((err)=>{
			console.log('error',err)
		}).finally(()=>{

		})
	}
	useEffect(()=>{
		getAnalytics()
	},[]);

	const redirectionFilteredList = (data) => {
		dispatch(setCustomFilterData({contract_status:data}))
		router.push(`/contracts`)
	}

	return (
		<>
			<div className='dashboard_card'>
				<Card onClick={()=>{
					dispatch(setCustomFilterData('all'))
					handleOnClickRedirection('/contracts')
				}} className='dashboard_cardshadow'>
					<CardContent style={{height:'100%', padding:'24px'}}>
						<div>
							<span className='dashboardPattern_img'>
								<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB"/>
									<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB"/>
									<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB"/>
									<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB"/>
								</svg>
							</span>
							<Box sx={{ alignContent: 'center', display: 'flex', }}>
								<span className='dashboard_cardIcon icon_holder'>
									{/* <Icon>
										request_quote
									</Icon> */}
									<IconFile size={18} />
								</span>
							</Box>
							<div className='dashboard_cardDetails'>
								<p>Total Contracts</p>
								<h5>{contractMetrics.total_contract_count}</h5>
							</div>
						</div>
					</CardContent>
				</Card>
			</div>
			<div className='dashboard_card'>
				<Card onClick={()=>redirectionFilteredList('Active')} className='dashboard_cardshadow'>
					<CardContent style={{height:'100%', padding:'24px'}}>
						<div>
							<span className='dashboardPattern_img'>
								<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB"/>
									<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB"/>
									<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB"/>
									<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB"/>
								</svg>
							</span>
							<Box sx={{ alignContent: 'center', display: 'flex', }}>
								<span style={{backgroundColor: '#D1FADF', color: '#039855'}} className='location_icon icon_holder'>
									<IconFilePower size={18} />
								</span>
							</Box>
							<div className='dashboard_cardDetails'>
								<p>Active Contracts</p>
								<h5>{contractMetrics.total_active_contract_count}</h5>
							</div>
						</div>
					</CardContent>
				</Card>
			</div>
			<div className='dashboard_card'>
				<Card onClick={()=>redirectionFilteredList('Expiring')} className='dashboard_cardshadow'>
					<CardContent style={{height:'100%', padding:'24px'}}>
						<div>
							<span className='dashboardPattern_img'>
								<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB"/>
									<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB"/>
									<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB"/>
									<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB"/>
								</svg>
							</span>
							<Box sx={{ alignContent: 'center', display: 'flex', }}>
								<span style={{backgroundColor: '#ABEFFE', color: '#0B7B94'}} className='ticket_icon icon_holder'>
									<IconFileAlert size={18} />
								</span>
							</Box>
							<div className='dashboard_cardDetails'>
								<p>Expiring Contracts</p>
								<h5>{contractMetrics.total_expiring_contract_count}</h5>
							</div>
						</div>
					</CardContent>
				</Card>
			</div>
			<div className='dashboard_card'>
				<Card onClick={()=>redirectionFilteredList('Expired')} className='dashboard_cardshadow'>
					<CardContent style={{height:'100%', padding:'24px'}}>
						<div>
							<span className='dashboardPattern_img'>
								<svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="81.5" cy="68.5" r="81" stroke="#F9FAFB"/>
									<circle cx="81.5" cy="68.5" r="68" stroke="#F9FAFB"/>
									<circle cx="81.5" cy="68.5" r="50" stroke="#F9FAFB"/>
									<circle cx="81.5" cy="68.5" r="32" stroke="#F9FAFB"/>
								</svg>
							</span>
							<Box sx={{ alignContent: 'center', display: 'flex', }}>
								<span style={{backgroundColor: '#FDCCCB', color: '#DA2D28'}} className='active_assetsIcon icon_holder'>
									<IconFileCheck size={18} />
								</span>
							</Box>
							<div className='dashboard_cardDetails'>
								<p>Expired Contracts</p>
								<h5>{contractMetrics.total_expired_contract_count}</h5>
							</div>
						</div>
					</CardContent>
				</Card>
			</div>
		</>
	);
}
export default DashboardCard